//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Multiselect from 'vue-multiselect'
import throttle from 'lodash/throttle'
import debounce from 'lodash/debounce'
import filter from 'lodash/filter'
import VIcon from '@/components/ui/v-icon/VIcon'
import VBadge from '@/components/ui/v-badge/VBadge'

export default {
  name: 'VProductSelect',
  components: {
    Multiselect,
    VIcon,
    VBadge,
  },
  props: {
    /**
     * @model
     */
    value: {
      required: false,
      type: [Array, Object],
      default: () => null,
    },
    options: {
      required: true,
      type: Array,
    },
    /**
     * Function for data loading
     * Can be async function
     * @example `(searchString, page, context) => { const arrayOfNewOptions = []; return arrayOfNewOptions; }`
     * @param {string} searchString
     * @param {number} page
     */
    asyncSearch: {
      required: true,
      type: Function,
    },
    /**
     * Additional context for `asyncSearch` prop
     */
    asyncSearchContext: {
      required: false,
      type: Object,
      default: () => {},
    },
    /**
     * Will run rerunAsyncSearch on multiselect's `open` event
     */
    rerunOnOpen: {
      required: false,
      type: Boolean,
      default: false,
    },
    /**
     * Remove options before re-running asyncSearch.
     * Has no effect with rerunOnOpen === false
     * NOTE: selected options will NOT be removed
     */
    clearOptionsBeforeRerun: {
      required: false,
      type: Boolean,
      default: false,
    },
    /**
     * Search in loaded from first request options without calling `asyncSearch`
     */
    searchInLoadedOptions: {
      required: false,
      type: Boolean,
      default: false,
    },
    optionsLimit: {
      required: false,
      type: Number,
      default: 3000,
    },
    trackBy: {
      required: false,
      type: String,
      default: 'id',
    },
    label: {
      required: false,
      type: String,
      default: 'title',
    },
    placeholder: {
      required: false,
      type: String,
      default: 'Search by name, tags, SKU or ASIN',
    },
    allowEmpty: {
      required: false,
      type: Boolean,
      default: true,
    },
    canClear: {
      required: false,
      type: Boolean,
      default: true,
    },
    icon: {
      required: false,
      type: String,
      default: 'package',
    },
    showCountry: {
      type: Boolean,
      default: true,
    },
    showShopType: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      loading: false,
      searchQuery: '',
      currentPage: 1,
      allDataLoaded: false,

      // see searchInLoadedOptions
      firstPageOptionsLoaded: false,
      firstPageOptions: [],
    }
  },
  mounted() {
    this.applyScrollHandler()

    if (!this.options || !this.options.length) {
      this.runAsyncSearch()
    }
  },
  methods: {
    /**
     * Return safe title of product even if product suspended (without title)
     * @param {Product} option
     * @return {string}
     */
    getSafeProductTitleByOption(option) {
      if (
        option.product_detail !== null &&
        option.product_detail !== undefined &&
        option.product_detail[this.label]
      ) {
        return option.product_detail[this.label]
      } else if (option[this.label]) {
        return option[this.label]
      }
      return `Suspended product ${option.asin}`
    },

    getSafeProductCountry(option, key, defVal = null) {
      if (
        option.country !== null &&
        option.country !== undefined &&
        option.country[key]
      ) {
        return option.country[key]
      }
      return defVal
    },

    /**
     * Return safe value of product details
     * @param {Product} product
     * @param {string} key
     * @param {any|null} fallback
     * @return {string}
     */
    getSafeProductDetailItem(product, key, fallback = null) {
      if (typeof product[key] !== 'undefined') {
        return product[key]
      }
      return product.product_detail ? product.product_detail[key] : fallback
    },

    selectAll() {
      this.$emit('input', this.options)
    },

    runAsyncSearch(replaceMode = false) {
      if (!this.loading) {
        this.loading = true

        if (
          !this.searchInLoadedOptions ||
          (this.searchInLoadedOptions && !this.firstPageOptionsLoaded)
        ) {
          const prevSearch = this.searchQuery
          Promise.resolve(
            this.asyncSearch(
              this.searchQuery,
              this.currentPage,
              this.asyncSearchContext
            )
          )
            .then((newOptions) => {
              if (Array.isArray(newOptions)) {
                if (newOptions.length > 0) {
                  // keepingOptions - options that must left after all options list changed
                  let keepingOptions = []

                  if (replaceMode) {
                    // selected options must be in all options list
                    keepingOptions = this.value
                  } else {
                    // append mode
                    keepingOptions = this.options
                  }

                  // remove all duplicates
                  const newOptionsFiltered = newOptions.filter(
                    (value, _index, _array) => {
                      // see https://lodash.com/docs/4.17.15#filter for details
                      const matchedOptions = filter(keepingOptions, value)
                      return !matchedOptions || matchedOptions.length === 0
                    }
                  )

                  // value for 'update:options' event
                  const updatedOptionsList = [
                    ...keepingOptions,
                    ...newOptionsFiltered,
                  ]

                  // on first request save all options to firstPageOptions
                  if (
                    this.searchInLoadedOptions &&
                    !this.firstPageOptionsLoaded
                  ) {
                    this.firstPageOptions = updatedOptionsList
                    this.firstPageOptionsLoaded = true
                    this.allDataLoaded = false
                  }

                  this.$emit('update:options', updatedOptionsList)
                } else {
                  this.firstPageOptionsLoaded && (this.allDataLoaded = true)
                }
              }
            })
            .finally(() => {
              this.loading = false
              if (this.searchQuery !== prevSearch)
                this.onSearch(this.searchQuery)
            })
        } else {
          const filterOptionsCallback = () => {
            return this.firstPageOptions.filter(
              (value) =>
                value[this.label]
                  .toLowerCase()
                  .indexOf(this.searchQuery.toLowerCase()) !== -1 // eslint-disable-line unicorn/prefer-includes
            )
          }
          Promise.resolve(filterOptionsCallback())
            .then((newOptions) => {
              // remove all duplicates
              const newOptionsFiltered = newOptions.filter(
                (value, _index, _array) => {
                  // see https://lodash.com/docs/4.17.15#filter for details
                  const matchedOptions = filter(this.value, value)
                  return !matchedOptions || matchedOptions.length === 0
                }
              )

              // value for 'update:options' event
              const updatedOptionsList = [...this.value, ...newOptionsFiltered]

              this.$emit('update:options', updatedOptionsList)
            })
            .finally(() => {
              this.loading = false
            })
        }
      }
    },
    onSearch(query) {
      this.currentPage = 1
      if (!this.searchInLoadedOptions) {
        this.allDataLoaded = false
      }
      this.searchQuery = query
      this.runAsyncSearch(true)
    },
    onSearchDebounced: debounce(
      function (query) {
        this.onSearch(query)
      },
      100,
      {
        trailing: true,
      }
    ),
    onOpen(_id) {
      if (this.rerunOnOpen === true) {
        this.rerunAsyncSearch()
      }
    },
    infiniteScrollCallback() {
      if (!this.allDataLoaded && !this.loading) {
        this.currentPage++
        this.runAsyncSearch(false)
      }
    },

    /**
     * Refresh data via reset values and rerun search
     */
    rerunAsyncSearch() {
      this.searchQuery = ''
      this.currentPage = 1
      if (this.clearOptionsBeforeRerun) {
        this.$emit('update:options', [...this.value])
      }
      this.runAsyncSearch(true)
    },

    /**
     * Check if selected option is active.
     * Should be used for checking initial value's (js-)objects with loaded external (js-)objects
     * @param option
     * @return {boolean}
     */
    getOptionActiveState(option) {
      const searchingOption = this.value.find((value) => {
        return value[this.trackBy] === option[this.trackBy]
      })

      return searchingOption !== undefined
    },

    onInput(value) {
      /**
       * v-model's event
       * @property {Array} value Selected options
       */
      const newValue = value === null ? [] : [value]
      this.$emit('input', newValue)
    },

    // currently `search` argument is not used and was renames to `_search`.
    clearAll(_search) {
      this.$emit('input', [])
    },

    limitText(count) {
      let word = 'products'
      if (count === 1) {
        word = 'product'
      }
      return `Selected: ${count} ${word}`
    },

    applyScrollHandler() {
      const multiselectContentWrapper = this.$refs.multiselect.$refs.list
      const scrollListener = (event) => {
        const minDistance = 10 // minimal distance from bottom of element for triggering data loading
        const currentPosition =
          event.target.offsetHeight + event.target.scrollTop
        const maxPosition = event.target.scrollHeight
        const shouldRunCallback = currentPosition >= maxPosition - minDistance
        if (shouldRunCallback) {
          this.infiniteScrollCallback()
        }
      }
      const scrollListenerThrottled = throttle(scrollListener, 200, {
        trailing: true,
      })
      multiselectContentWrapper.addEventListener(
        'scroll',
        scrollListenerThrottled
      )

      this.$on('hook:beforeDestroy', () => {
        multiselectContentWrapper.removeEventListener(
          'scroll',
          scrollListenerThrottled
        )
      })
    },
  },
}
