export default () => ({
  previousViewMode: null,
  modalAddCategoryVisible: false,
  modalAddComplaintVisible: false,
  modalEditComplaint: {
    visible: false,
    complaint: null,
    category: null,
  },
})
