/**
 * Return all scoped slots which starting with given substring
 * @param {Vue} vm
 * @param {string} startWith
 */
function getScopedSlotsStartingWith(vm, startWith) {
  const scopedSlots = {}
  for (const key in vm.$scopedSlots) {
    if (key.startsWith(startWith)) {
      scopedSlots[key] = vm.$scopedSlots[key]
    }
  }
  return scopedSlots
}

/**
 * Return all scoped slots which matches given regexp
 * @param {Vue} vm
 * @param {RegExp} regexp
 */
function getScopedSlotsByRegexp(vm, regexp) {
  const scopedSlots = {}
  for (const key in vm.$scopedSlots) {
    if (regexp.test(key)) {
      scopedSlots[key] = vm.$scopedSlots[key]
    }
  }
  return scopedSlots
}

export { getScopedSlotsStartingWith, getScopedSlotsByRegexp }
