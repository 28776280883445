import { AxiosStatic } from 'axios'
import Auth from '@nuxtjs/auth-next/dist/core/auth'
import BaseService from '~/modules/api-resources/BaseService'

// Nuxt Axios type is not exported. Use AxiosStatic typing instead.
type NuxtContextAxios = {
  $axios: AxiosStatic
  $auth: Auth
}

export type ServiceConstructor<T extends BaseService> = new (
  axios: AxiosStatic
) => T

/**
 * Higher order function that return function for creating api services with Nuxt Axios instance
 * Creating api services with application's axios instance allow use all
 * axios setting from app and modules like Nuxt Auth module
 * @param $axios
 * @param $auth
 */
export const createServiceResolver = ({ $axios, $auth }: NuxtContextAxios) => <
  T extends BaseService
>(
  ServiceConstructor: ServiceConstructor<T>
): T => {
  const $service = new ServiceConstructor($axios)
  $service.setUser($auth.user)
  return $service
}
