//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VInput from '@/components/ui/v-input/VInput'
import VBtn from '@/components/ui/v-btn/VBtn'
import VIcon from '@/components/ui/v-icon/VIcon'

export default {
  name: 'VStepInput',
  components: { VIcon, VBtn },
  mixins: [VInput],
  props: {
    min: {
      required: false,
      type: Number,
      default: null,
    },
    max: {
      required: false,
      type: Number,
      default: null,
    },
    step: {
      required: true,
      type: Number,
    },
    prependIcon: {
      required: false,
      type: String,
      default: null,
    },
  },
  data() {
    return {
      generatedId: 'v-step-input-' + Math.random().toString(36).substring(7),
    }
  },
  computed: {
    formControlClasses() {
      return {
        // ...VInput.$options.computed.formControlClasses.call(this),
        'v-input__form-control--error': this.isError,
        'v-step-input__form-control--with-prepend-icon': true,
      }
    },
  },
  methods: {
    subtract() {
      let value = +parseFloat(this.value - this.step).toFixed(2)
      if (this.min !== null && this.min !== undefined) {
        value = Math.max(this.min, value)
      }
      this.$emit('input', value)
    },
    add() {
      let value = +parseFloat(this.value + this.step).toFixed(2)
      if (this.max !== null && this.max !== undefined) {
        value = Math.min(this.max, value)
      }
      this.$emit('input', value)
    },
  },
}
