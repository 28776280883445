//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VCheckbox from '~/components/ui/v-checkbox/VCheckbox'
export default {
  name: 'VStarRating',
  mixins: [VCheckbox],
}
