//
//
//
//
//
//

import VIcon from '~/components/ui/v-icon/VIcon'

export default {
  name: 'VPopoverCloseBtn',
  components: {
    VIcon,
  },
}
