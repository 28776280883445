













import Vue, { PropOptions } from 'vue'
// @ts-ignore
import VAlert from '@/components/ui/v-alert/VAlert'

export default Vue.extend({
  name: 'VErrorsBag',
  components: { VAlert },
  props: {
    /**
     * AxiosResponse or AxiosError or AxiosResponse.data containing errors
     */
    errorsMessages: {
      required: false,
      type: Array,
      default: () => null,
    } as PropOptions<string[]>,
  },
})
