//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

export default {
  name: 'VSelect',
  components: { vSelect },
  props: {
    getOptionLabel: {
      required: false,
      type: Function,
      default: null,
    },
    appendToBody: {
      type: Boolean,
      default: true,
    },
    hideDetails: {
      required: false,
      type: Boolean,
      default: false,
    },
    error: {
      required: false,
      type: Boolean,
      default: false,
    },
    errorMessage: {
      required: false,
      type: [String, Boolean],
      default: '',
    },
    hint: {
      required: false,
      type: String,
      default: '',
    },
    label: {
      required: false,
      type: String,
      default: null,
    },
    placeholder: {
      required: false,
      type: String,
      default: null,
    },
    searchable: {
      required: false,
      type: Boolean,
      default: false,
    },
    multiple: {
      required: false,
      type: Boolean,
      default: false,
    },
    clearable: {
      required: false,
      type: Boolean,
      default: false,
    },
    disabled: {
      required: false,
      type: Boolean,
      default: false,
    },
    readonly: {
      required: false,
      type: Boolean,
      default: false,
    },
    trackBy: {
      required: false,
      type: String,
      default: null,
    },
    value: {
      required: false,
      type: [String, Number, Object, Boolean, Array],
      default: '',
    },
    options: {
      required: false,
      type: Array,
      default: () => [],
    },
    formStyle: {
      required: false,
      type: String,
      default: 'light',
    },
    title: {
      required: false,
      type: String,
      default: null,
    },
  },
  data() {
    return {
      hidePlaceholder: false,
    }
  },
  computed: {
    isError() {
      return this.error || !!this.errorMessage
    },
    formControlClasses() {
      return {
        vselect: true,
        default: true,
        'hide-placeholder': this.hidePlaceholder,
        dark: this.formStyle === 'dark',
        middle: this.formStyle === 'middle',
        white: this.formStyle === 'white',
        disabled: this.disabled,
        error: this.isError,
      }
    },
    calcWidth() {
      return {
        '--width': this.width,
      }
    },
    hintClasses() {
      return {
        'v-input__hint--active': this.hintActive,
        'v-input__hint--auto-show': this.autoShowDetails,
      }
    },
    errorClasses() {
      return {
        'v-input__error-feedback--active': this.isError,
        'v-input__error-feedback--auto-show': this.autoShowDetails,
      }
    },
  },
  methods: {
    onInput(newValue) {
      this.$emit('input', newValue)
    },
    calculateTopPosition(dropdownList, component, { width, top, left }) {
      const wHeight = window.innerHeight
      const wScroll = window.scrollY
      const itemHeight = component.$el.clientHeight
      const cHeight =
        itemHeight * (this.options.length > 9 ? 9 : this.options.length)

      dropdownList.style.width = width
      dropdownList.style.left = left
      let clTop = parseInt(top)
      if (clTop + cHeight - wScroll > wHeight && this.options.length > 0) {
        clTop = wHeight - cHeight + wScroll - 12
        dropdownList.style.borderTop = 'solid 1px #c0c0c0'
      }
      dropdownList.style.top = clTop + 'px'
    },
    onSearchHidePlaceholder() {
      this.hidePlaceholder = true
    },
    onCloseReturnPlaceholder() {
      this.hidePlaceholder = false
    },
  },
}
