var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"v-drawer-menu",class:{ 'v-drawer-menu--collapsed': _vm.collapsedMenu }},[_vm._l((_vm.drawItems),function(item,index){return [(!item.group)?_c('nuxt-link',{key:("drawer-menu-item-" + index),class:[
        'v-drawer-menu-item',
        'd-flex-n',
        'align-items-center',
        ("app-menu-item-" + (item.name)),
        {
          'link-disabled': item.disabled,
          'link-active': _vm.$route.name.includes(item.name),
        } ],attrs:{"exact":"","title":item.text,"to":item.to,"event":item.disabled ? '' : 'click'}},[_c('div',{staticClass:"v-drawer-menu-item__icon"},[_c('v-icon',{attrs:{"size":"20px"}},[_vm._v(_vm._s(item.icon))])],1),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.collapsedMenu),expression:"!collapsedMenu"}],staticClass:"v-drawer-menu-item__text text-truncate"},[_vm._v("\n        "+_vm._s(item.text)+"\n      ")])]):_vm._e(),_vm._v(" "),(item.group)?_c('div',{key:("drawer-menu-group-" + index),staticClass:"v-drawer-menu-group",class:[
        ("app-menu-item-" + (item.name)),
        _vm.expandedNodes[item.name] && 'v-drawer-menu-group--expanded',
        item === _vm.selectedNode && 'link-active' ],attrs:{"title":item.text}},[_c('div',{staticClass:"v-drawer-menu-group__content d-flex align-items-center",class:{ 'link-active': item === _vm.selectedNode },on:{"click":function($event){return _vm.toggleExpand(item)}}},[_c('div',{staticClass:"v-drawer-menu-item__icon"},[_c('v-icon',{attrs:{"size":"20px"}},[_vm._v(_vm._s(item.icon))])],1),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.collapsedMenu),expression:"!collapsedMenu"}],staticClass:"v-drawer-menu-item__text text-truncate"},[_vm._v("\n          "+_vm._s(item.text)+"\n        ")]),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.collapsedMenu),expression:"!collapsedMenu"}],staticClass:"v-drawer-menu-item__dropdown-icon"},[_c('v-icon',{attrs:{"size":"16px"}},[_vm._v("caret-down")])],1),_vm._v(" "),_c('v-popover',{attrs:{"id":("popover-" + (item.name)),"open":_vm.expandedNodes[item.name] && _vm.collapsedMenu,"placement":"right","trigger":"manual","boundaries-element":_vm.compactMenuBoundariesElement},on:{"hide":function($event){return _vm.toggleExpand(item, false)}}},[_c('template',{slot:"popover"},[_c('v-card',[_c('v-card-content',{staticClass:"p-0"},[_c('portal-target',{attrs:{"name":("compactGroupMenu" + (item.name))}})],1)],1)],1)],2)],1),_vm._v(" "),_c('v-collapse',{staticClass:"v-drawer-menu-group__items d-flex flex-column",attrs:{"value":!!_vm.expandedNodes[item.name]}},[_c('portal',{attrs:{"to":("compactGroupMenu" + (item.name)),"disabled":!_vm.collapsedMenu}},_vm._l((item.items),function(groupItem,groupItemIndex){return _c('div',{key:("drawer-menu-group-" + index + "-item-" + groupItemIndex)},[(groupItem.type !== 'br')?_c('nuxt-link',{staticClass:"v-drawer-menu-item d-flex-n align-items-center",class:[
                ("app-menu-item-" + (item.name) + "-child-" + (groupItem.name)),
                {
                  'link-disabled': groupItem.disabled,
                } ],attrs:{"exact":"","to":groupItem.to,"title":groupItem.text,"event":groupItem.disabled ? '' : 'click'}},[_c('div',{staticClass:"v-drawer-menu-item__text text-truncate",class:{
                  'link-active': _vm.$route.name.includes(groupItem.name),
                }},[_vm._v("\n                "+_vm._s(groupItem.text)+"\n              ")])]):_vm._e(),_vm._v(" "),(groupItem.type === 'br')?_c('div',{key:("drawer-menu-group-" + index + "-item-" + groupItemIndex),staticClass:"v-drawer-menu-item__divider"},[_vm._v("\n              "+_vm._s(groupItem.text)+"\n            ")]):_vm._e()],1)}),0)],1)],1):_vm._e()]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }