
import { Portal } from 'portal-vue'
// import VFadeTransition from '@/components/ui/transitions/VFadeTransition'
export default {
  name: 'VToast',
  model: {
    prop: 'value',
    event: 'toggle',
  },
  props: {
    position: {
      required: true,
      type: String,
    },
    noAutoHide: {
      required: false,
      type: Boolean,
      default: false,
    },
    // delay in ms
    autoHideDelay: {
      required: false,
      type: Number,
      default: 6000,
    },
    color: {
      required: false,
      type: String,
      default: null, // todo hex colors
    },
    value: {
      required: true,
      type: Boolean,
    },
  },
  data() {
    return {
      timeoutValue: null,
    }
  },
  computed: {
    classes() {
      const classes = {}

      const colorClass = this.getColorClass()
      if (colorClass !== null) {
        classes[colorClass] = true
      }

      return classes
    },
  },
  watch: {
    value: {
      handler(val, _oldVal) {
        if (val) {
          this.showToast()
        } else if (this.timeoutValue) {
          clearTimeout(this.timeoutValue)
          this.timeoutValue = false
        }
      },
      immediate: true,
    },
  },
  methods: {
    normalizePositionString(positionString) {
      const defaultPosition = 'top right'
      const verticalAxisWords = ['top', 'bottom']
      const horizontalAxisWords = ['left', 'right']
      const words = positionString.split(' ')
      if (words.length < 2) {
        return defaultPosition
      }
      if (
        verticalAxisWords.includes(words[0]) &&
        horizontalAxisWords.includes(words[1])
      ) {
        // all is ok (vertical horizontal)
        return positionString
      } else if (
        verticalAxisWords.includes(words[1]) &&
        horizontalAxisWords.includes(words[0])
      ) {
        // need to replace first and second words (horizontal vertical)
        return words[1] + ' ' + words[0]
      } else {
        return defaultPosition
      }
    },
    parsePositionString(normalizedPositionString) {
      const words = normalizedPositionString.split(' ')
      return {
        vertical: words[0],
        horizontal: words[1],
      }
    },
    showToast() {
      if (!this.noAutoHide) {
        this.timeoutValue = setTimeout(() => {
          this.$emit('toggle', false)
          // this.$destroy()
        }, this.autoHideDelay)
      }
    },
    getColorClass() {
      const colors = [
        'primary',
        'secondary',
        'success',
        'info',
        'warning',
        'danger',
      ]

      if (!colors.includes(this.color)) {
        return null
      }

      return `v-toast--${this.color}`
    },
  },
  render(createElement) {
    const normalizedString = this.normalizePositionString(this.position)
    const position = this.parsePositionString(normalizedString)
    const portalTargetName = `toaster-${position.vertical}-${position.horizontal}`

    return createElement(
      Portal,
      {
        props: {
          to: portalTargetName,
        },
      },
      [
        createElement(
          'transition',
          {
            props: {
              enterActiveClass:
                'animate__animated animate__faster animate__fadeInUp',
              leaveActiveClass:
                'animate__animated animate__faster animate__fadeOutDown',
            },
          },
          [
            createElement(
              'div',
              {
                class: {
                  'v-toast': true,
                  ...this.classes,
                },
                directives: [
                  {
                    name: 'show',
                    value: this.value,
                  },
                ],
                scopedSlots: {
                  default: () => createElement('div'),
                },
              },
              [
                this.$scopedSlots.default({
                  hideToast: () => {
                    this.$emit('toggle', false)
                  },
                }),
              ]
            ),
          ]
        ),
      ]
    )
  },
}
