var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('multiselect',{ref:"multiselect",staticClass:"v-product-select",attrs:{"limit":1,"value":_vm.value[0],"options":_vm.options,"options-limit":_vm.optionsLimit,"multiple":false,"label":_vm.label,"searchable":"","internal-search":false,"show-labels":false,"placeholder":_vm.placeholder,"allow-empty":_vm.allowEmpty},on:{"input":_vm.onInput,"search-change":_vm.onSearchDebounced,"open":_vm.onOpen},scopedSlots:_vm._u([(_vm.canClear)?{key:"clear",fn:function(ref){
var search = ref.search;
return [_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.value.length),expression:"value.length"}],staticClass:"multiselect__clear",on:{"mousedown":function($event){$event.preventDefault();$event.stopPropagation();return _vm.clearAll(search)}}},[_c('v-icon',{attrs:{"color":"secondary","size":"1rem"}},[_vm._v(" close-bold ")])],1)]}}:null,{key:"singleLabel",fn:function(ref){
var option = ref.option;
return [(_vm.icon)?_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(_vm._s(_vm.icon))]):_vm._e(),_vm._v(" "),_c('span',{staticClass:"text-truncate pr-5"},[_vm._v("\n      "+_vm._s(_vm.getSafeProductTitleByOption(option))+"\n    ")])]}},{key:"placeholder",fn:function(){return [(_vm.icon)?_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(_vm._s(_vm.icon))]):_vm._e(),_vm._v(" "+_vm._s(_vm.placeholder)+"\n  ")]},proxy:true},{key:"limit",fn:function(){return [_c('strong',{staticClass:"multiselect__strong"},[(_vm.icon)?_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(_vm._s(_vm.icon))]):_vm._e(),_vm._v("\n      "+_vm._s(_vm.limitText(1))+"\n    ")],1)]},proxy:true},{key:"noResult",fn:function(){return [_vm._v("No results found")]},proxy:true},{key:"option",fn:function(ref){
var option = ref.option;
return [_c('div',{staticClass:"d-flex align-items-center"},[_c('img',{staticClass:"v-product-multiselect__option-image",attrs:{"src":_vm.getSafeProductDetailItem(option, 'photo', '') ||
          '/product-placeholder-2.jpg',"alt":""}}),_vm._v(" "),_c('span',[_vm._v("\n        "+_vm._s(_vm.getSafeProductTitleByOption(option))+"\n        "),(_vm.showShopType)?_c('span',[_c('br'),_vm._v(" "),(option.shop_type === 'amazon')?_c('v-badge',{staticClass:"mt-1",attrs:{"color":"success"}},[_vm._v("\n            amazon\n          ")]):_vm._e(),_vm._v(" "),(option.shop_type === 'shopify')?_c('v-badge',{staticClass:"mt-1",attrs:{"color":"dark"}},[_vm._v("\n            shopify\n          ")]):_vm._e()],1):_vm._e()])])]}},{key:"afterList",fn:function(){return [_c('div',{staticClass:"v-product-multiselect__after-list"},[_c('div',{staticClass:"multiselect__option"},[_c('div',{staticClass:"d-flex justify-content-center align-items-center"},[_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],staticClass:"v-product-multiselect__spinner",attrs:{"color":"primary","size":"1rem"}},[_vm._v("\n            spinner\n          ")])],1)])])]},proxy:true}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }