
import VIcon from '@/components/ui/v-icon/VIcon'
import VCollapse from '@/components/ui/v-collapse/VCollapse'

export default {
  name: 'VDetail',
  props: {
    title: {
      required: true,
      type: String,
    },
    value: {
      required: true,
      type: String,
    },
    bold: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      groupOpen: this.title === 'Amazon fees',
    }
  },
  computed: {
    getTitle() {
      return this.title
    },
  },
  methods: {
    getNestedItems() {
      if (
        this.$slots.default === undefined ||
        this.$slots.default.length === 0
      ) {
        return []
      }
      return this.$slots.default
    },

    toggleCollapse(value = null) {
      this.groupOpen = value === null ? !this.groupOpen : value
    },

    /// render methods
    genContentRow(isGroup = false) {
      const children = [
        // content
        this.$createElement(
          'div',
          {
            class: {
              'v-detail__content': true,
            },
          },
          [
            // title
            this.$createElement(
              'div',
              {
                class: {
                  'v-detail__title': true,
                },
              },
              [this.getTitle]
            ),

            // value
            this.$createElement(
              'div',
              {
                class: {
                  'v-detail__value': true,
                },
              },
              [this.value]
            ),
          ]
        ),
      ]

      const listeners = {}
      if (isGroup) {
        listeners.click = () => {
          this.toggleCollapse()
        }
      }

      if (isGroup) {
        // add prepend icon
        children.unshift(
          this.$createElement(
            VIcon,
            {
              class: {
                'v-detail__caret': true,
                'v-detail__caret--open': this.groupOpen,
              },
              props: {
                size: '1rem',
                color: 'primary',
              },
            },
            'caret-down'
          )
        )
      }

      return this.$createElement(
        'div',
        {
          class: {
            'v-detail__content-row': true,
          },
          on: listeners,
        },
        children
      )
    },
  },
  render(h) {
    const nestedItems = this.getNestedItems()
    const isGroup = nestedItems.length > 0

    const children = [this.genContentRow(isGroup)]

    if (isGroup) {
      // add collapse with nested items
      children.push(
        h(
          VCollapse,
          {
            class: {
              'v-detail__group-content': true,
            },
            props: {
              value: this.groupOpen,
            },
          },
          nestedItems
        )
      )
    }

    return h(
      'div',
      {
        class: {
          'v-detail': true,
          'v-detail--group': isGroup,
          'font-weight-bold': this.bold,
        },
      },
      children
    )
  },
}
