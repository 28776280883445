/**
 * Convert value to css units value
 * If value is number (or string with number) will return value with 'px' suffix
 * Else will return value as is (because it already is css unit value)
 * @param value
 * @return {string|*}
 */
function convertToCssUnits(value) {
  if (value === null || value === undefined) {
    return '0'
  }

  if (!isNaN(value)) {
    return `${value}px`
  }

  return value
}

export default convertToCssUnits
