export { default as AgGridVue } from '../../components/ag-grid/AgGridVue.vue'
export { default as ColumnSettingsMenu } from '../../components/ag-grid/ColumnSettingsMenu.vue'
export { default as CustomDateFilter } from '../../components/ag-grid/CustomDateFilter.vue'
export { default as AmazonLinkField } from '../../components/fields/AmazonLinkField.vue'
export { default as AsinField } from '../../components/fields/AsinField.vue'
export { default as BaseField } from '../../components/fields/BaseField.vue'
export { default as CrtField } from '../../components/fields/CrtField.vue'
export { default as ParentAsinField } from '../../components/fields/ParentAsinField.vue'
export { default as ParentField } from '../../components/fields/ParentField.vue'
export { default as ProductField } from '../../components/fields/ProductField.vue'
export { default as RatingField } from '../../components/fields/RatingField.vue'
export { default as Logo } from '../../components/layouts/Logo.vue'
export { default as VueTippy } from '../../components/vue-tippy/VueTippy.vue'
export { default as AsinCellRenderer } from '../../components/ag-grid/CellRenderers/AsinCellRenderer.vue'
export { default as LinkCellRenderer } from '../../components/ag-grid/CellRenderers/LinkCellRenderer.vue'
export { default as TooltipCellRenderer } from '../../components/ag-grid/CellRenderers/TooltipCellRenderer.vue'
export { default as VAlertTypesMultiselect } from '../../components/common/v-alert-types-multiselect/VAlertTypesMultiselect.vue'
export { default as VCountryMultiselect } from '../../components/common/v-country-multiselect/VCountryMultiselect.vue'
export { default as VCountrySelect } from '../../components/common/v-country-select/VCountrySelect.vue'
export { default as VErrorsBag } from '../../components/common/v-errors-bag/VErrorsBag.vue'
export { default as VProductMultiselect } from '../../components/common/v-product-multiselect/VProductMultiselect.vue'
export { default as VProductSelect } from '../../components/common/v-product-select/VProductSelect.vue'
export { default as VApp } from '../../components/layouts/app/VApp.vue'
export { default as VDrawer } from '../../components/layouts/drawer/VDrawer.vue'
export { default as VDrawerLogo } from '../../components/layouts/drawer/VDrawerLogo.vue'
export { default as VDrawerMenu } from '../../components/layouts/drawer/VDrawerMenu.vue'
export { default as VHeader } from '../../components/layouts/header/VHeader.vue'
export { default as VMain } from '../../components/layouts/main/VMain.vue'
export { default as VPageTitle } from '../../components/layouts/v-page-title/VPageTitle.vue'
export { default as JsonView } from '../../components/ui/json-view/JsonView.vue'
export { default as DefaultPreloader } from '../../components/ui/loaders/DefaultPreloader.js'
export { default as VOverlayPreloader } from '../../components/ui/loaders/VOverlayPreloader.vue'
export { default as RightPanel } from '../../components/ui/right-panel/RightPanel.vue'
export { default as StatusLabel } from '../../components/ui/status-label/status-label.vue'
export { default as VActionsGroup } from '../../components/ui/v-actions-group/VActionsGroup.vue'
export { default as VAlert } from '../../components/ui/v-alert/VAlert.vue'
export { default as VBadge } from '../../components/ui/v-badge/VBadge.vue'
export { default as VBtn } from '../../components/ui/v-btn/VBtn.vue'
export { default as VCard } from '../../components/ui/v-card/VCard.vue'
export { default as VCardContent } from '../../components/ui/v-card/VCardContent.vue'
export { default as VCardFooter } from '../../components/ui/v-card/VCardFooter.vue'
export { default as VCardHeader } from '../../components/ui/v-card/VCardHeader.vue'
export { default as VCheckbox } from '../../components/ui/v-checkbox/VCheckbox.vue'
export { default as VCollapse } from '../../components/ui/v-collapse/VCollapse.vue'
export { default as VCopiedValue } from '../../components/ui/v-copied-value/VCopiedValue.vue'
export { default as VDateInput } from '../../components/ui/v-date-input/VDateInput.vue'
export { default as VDetail } from '../../components/ui/v-details-popover/VDetail.vue'
export { default as VDetailsPopover } from '../../components/ui/v-details-popover/VDetailsPopover.vue'
export { default as VFileInput } from '../../components/ui/v-file-input/VFileInput.vue'
export { default as VFormGroup } from '../../components/ui/v-form-group/VFormGroup.vue'
export { default as VIcon } from '../../components/ui/v-icon/VIcon.vue'
export { default as VInput } from '../../components/ui/v-input/VInput.vue'
export { default as VMenu } from '../../components/ui/v-menu/VMenu.vue'
export { default as VMenuItem } from '../../components/ui/v-menu/VMenuItem.vue'
export { default as VModal } from '../../components/ui/v-modal/VModal.vue'
export { default as VPopoverCloseBtn } from '../../components/ui/v-popover-close-btn/VPopoverCloseBtn.vue'
export { default as VPopup } from '../../components/ui/v-popup/VPopup.vue'
export { default as VRadio } from '../../components/ui/v-radio/VRadio.vue'
export { default as VRating } from '../../components/ui/v-rating/VRating.vue'
export { default as VSelect } from '../../components/ui/v-select/VSelect.vue'
export { default as VSpacer } from '../../components/ui/v-spacer/VSpacer.vue'
export { default as VStarRating } from '../../components/ui/v-star-rating/VStarRating.vue'
export { default as VStepInput } from '../../components/ui/v-step-input/VStepInput.vue'
export { default as VSwitch } from '../../components/ui/v-switch/VSwitch.vue'
export { default as VTab } from '../../components/ui/v-tabs/VTab.vue'
export { default as VTabItem } from '../../components/ui/v-tabs/VTabItem.vue'
export { default as VTabs } from '../../components/ui/v-tabs/VTabs.vue'
export { default as VTextarea } from '../../components/ui/v-textarea/VTextarea.vue'
export { default as VToast } from '../../components/ui/v-toast/VToast.vue'
export { default as VWidget } from '../../components/ui/v-widget/VWidget.vue'
export { default as VWidgetColumn } from '../../components/ui/v-widget/VWidgetColumn.ts'
export { default as VWidgetsContainer } from '../../components/ui/v-widget/VWidgetsContainer.vue'
export { default as VWorklog } from '../../components/ui/v-worklog/VWorklog.vue'
export { default as AmazonTheme } from '../../components/ui/charts/themes/amazonTheme.js'
export { default as VLineChart } from '../../components/ui/charts/v-line-chart/VLineChart.vue'
export { default as FilterableTable } from '../../components/ui/tables/mixins/filterableTable.js'
export { default as VDataIterator } from '../../components/ui/tables/v-data-iterator/VDataIterator.vue'
export { default as VDataTable } from '../../components/ui/tables/v-data-table/VDataTable.vue'
export { default as VDataTableConfigModal } from '../../components/ui/tables/v-data-table/VDataTableConfigModal.vue'
export { default as VDataTableFilters } from '../../components/ui/tables/v-data-table/VDataTableFilters.vue'
export { default as VPagination } from '../../components/ui/tables/v-data-table/VPagination.vue'
export { default as VSimpleTable } from '../../components/ui/tables/v-simple-table/VSimpleTable.vue'

export const LazyAgGridVue = import('../../components/ag-grid/AgGridVue.vue' /* webpackChunkName: "components/ag-grid-vue" */).then(c => wrapFunctional(c.default || c))
export const LazyColumnSettingsMenu = import('../../components/ag-grid/ColumnSettingsMenu.vue' /* webpackChunkName: "components/column-settings-menu" */).then(c => wrapFunctional(c.default || c))
export const LazyCustomDateFilter = import('../../components/ag-grid/CustomDateFilter.vue' /* webpackChunkName: "components/custom-date-filter" */).then(c => wrapFunctional(c.default || c))
export const LazyAmazonLinkField = import('../../components/fields/AmazonLinkField.vue' /* webpackChunkName: "components/amazon-link-field" */).then(c => wrapFunctional(c.default || c))
export const LazyAsinField = import('../../components/fields/AsinField.vue' /* webpackChunkName: "components/asin-field" */).then(c => wrapFunctional(c.default || c))
export const LazyBaseField = import('../../components/fields/BaseField.vue' /* webpackChunkName: "components/base-field" */).then(c => wrapFunctional(c.default || c))
export const LazyCrtField = import('../../components/fields/CrtField.vue' /* webpackChunkName: "components/crt-field" */).then(c => wrapFunctional(c.default || c))
export const LazyParentAsinField = import('../../components/fields/ParentAsinField.vue' /* webpackChunkName: "components/parent-asin-field" */).then(c => wrapFunctional(c.default || c))
export const LazyParentField = import('../../components/fields/ParentField.vue' /* webpackChunkName: "components/parent-field" */).then(c => wrapFunctional(c.default || c))
export const LazyProductField = import('../../components/fields/ProductField.vue' /* webpackChunkName: "components/product-field" */).then(c => wrapFunctional(c.default || c))
export const LazyRatingField = import('../../components/fields/RatingField.vue' /* webpackChunkName: "components/rating-field" */).then(c => wrapFunctional(c.default || c))
export const LazyLogo = import('../../components/layouts/Logo.vue' /* webpackChunkName: "components/logo" */).then(c => wrapFunctional(c.default || c))
export const LazyVueTippy = import('../../components/vue-tippy/VueTippy.vue' /* webpackChunkName: "components/vue-tippy" */).then(c => wrapFunctional(c.default || c))
export const LazyAsinCellRenderer = import('../../components/ag-grid/CellRenderers/AsinCellRenderer.vue' /* webpackChunkName: "components/asin-cell-renderer" */).then(c => wrapFunctional(c.default || c))
export const LazyLinkCellRenderer = import('../../components/ag-grid/CellRenderers/LinkCellRenderer.vue' /* webpackChunkName: "components/link-cell-renderer" */).then(c => wrapFunctional(c.default || c))
export const LazyTooltipCellRenderer = import('../../components/ag-grid/CellRenderers/TooltipCellRenderer.vue' /* webpackChunkName: "components/tooltip-cell-renderer" */).then(c => wrapFunctional(c.default || c))
export const LazyVAlertTypesMultiselect = import('../../components/common/v-alert-types-multiselect/VAlertTypesMultiselect.vue' /* webpackChunkName: "components/v-alert-types-multiselect" */).then(c => wrapFunctional(c.default || c))
export const LazyVCountryMultiselect = import('../../components/common/v-country-multiselect/VCountryMultiselect.vue' /* webpackChunkName: "components/v-country-multiselect" */).then(c => wrapFunctional(c.default || c))
export const LazyVCountrySelect = import('../../components/common/v-country-select/VCountrySelect.vue' /* webpackChunkName: "components/v-country-select" */).then(c => wrapFunctional(c.default || c))
export const LazyVErrorsBag = import('../../components/common/v-errors-bag/VErrorsBag.vue' /* webpackChunkName: "components/v-errors-bag" */).then(c => wrapFunctional(c.default || c))
export const LazyVProductMultiselect = import('../../components/common/v-product-multiselect/VProductMultiselect.vue' /* webpackChunkName: "components/v-product-multiselect" */).then(c => wrapFunctional(c.default || c))
export const LazyVProductSelect = import('../../components/common/v-product-select/VProductSelect.vue' /* webpackChunkName: "components/v-product-select" */).then(c => wrapFunctional(c.default || c))
export const LazyVApp = import('../../components/layouts/app/VApp.vue' /* webpackChunkName: "components/v-app" */).then(c => wrapFunctional(c.default || c))
export const LazyVDrawer = import('../../components/layouts/drawer/VDrawer.vue' /* webpackChunkName: "components/v-drawer" */).then(c => wrapFunctional(c.default || c))
export const LazyVDrawerLogo = import('../../components/layouts/drawer/VDrawerLogo.vue' /* webpackChunkName: "components/v-drawer-logo" */).then(c => wrapFunctional(c.default || c))
export const LazyVDrawerMenu = import('../../components/layouts/drawer/VDrawerMenu.vue' /* webpackChunkName: "components/v-drawer-menu" */).then(c => wrapFunctional(c.default || c))
export const LazyVHeader = import('../../components/layouts/header/VHeader.vue' /* webpackChunkName: "components/v-header" */).then(c => wrapFunctional(c.default || c))
export const LazyVMain = import('../../components/layouts/main/VMain.vue' /* webpackChunkName: "components/v-main" */).then(c => wrapFunctional(c.default || c))
export const LazyVPageTitle = import('../../components/layouts/v-page-title/VPageTitle.vue' /* webpackChunkName: "components/v-page-title" */).then(c => wrapFunctional(c.default || c))
export const LazyJsonView = import('../../components/ui/json-view/JsonView.vue' /* webpackChunkName: "components/json-view" */).then(c => wrapFunctional(c.default || c))
export const LazyDefaultPreloader = import('../../components/ui/loaders/DefaultPreloader.js' /* webpackChunkName: "components/default-preloader" */).then(c => wrapFunctional(c.default || c))
export const LazyVOverlayPreloader = import('../../components/ui/loaders/VOverlayPreloader.vue' /* webpackChunkName: "components/v-overlay-preloader" */).then(c => wrapFunctional(c.default || c))
export const LazyRightPanel = import('../../components/ui/right-panel/RightPanel.vue' /* webpackChunkName: "components/right-panel" */).then(c => wrapFunctional(c.default || c))
export const LazyStatusLabel = import('../../components/ui/status-label/status-label.vue' /* webpackChunkName: "components/status-label" */).then(c => wrapFunctional(c.default || c))
export const LazyVActionsGroup = import('../../components/ui/v-actions-group/VActionsGroup.vue' /* webpackChunkName: "components/v-actions-group" */).then(c => wrapFunctional(c.default || c))
export const LazyVAlert = import('../../components/ui/v-alert/VAlert.vue' /* webpackChunkName: "components/v-alert" */).then(c => wrapFunctional(c.default || c))
export const LazyVBadge = import('../../components/ui/v-badge/VBadge.vue' /* webpackChunkName: "components/v-badge" */).then(c => wrapFunctional(c.default || c))
export const LazyVBtn = import('../../components/ui/v-btn/VBtn.vue' /* webpackChunkName: "components/v-btn" */).then(c => wrapFunctional(c.default || c))
export const LazyVCard = import('../../components/ui/v-card/VCard.vue' /* webpackChunkName: "components/v-card" */).then(c => wrapFunctional(c.default || c))
export const LazyVCardContent = import('../../components/ui/v-card/VCardContent.vue' /* webpackChunkName: "components/v-card-content" */).then(c => wrapFunctional(c.default || c))
export const LazyVCardFooter = import('../../components/ui/v-card/VCardFooter.vue' /* webpackChunkName: "components/v-card-footer" */).then(c => wrapFunctional(c.default || c))
export const LazyVCardHeader = import('../../components/ui/v-card/VCardHeader.vue' /* webpackChunkName: "components/v-card-header" */).then(c => wrapFunctional(c.default || c))
export const LazyVCheckbox = import('../../components/ui/v-checkbox/VCheckbox.vue' /* webpackChunkName: "components/v-checkbox" */).then(c => wrapFunctional(c.default || c))
export const LazyVCollapse = import('../../components/ui/v-collapse/VCollapse.vue' /* webpackChunkName: "components/v-collapse" */).then(c => wrapFunctional(c.default || c))
export const LazyVCopiedValue = import('../../components/ui/v-copied-value/VCopiedValue.vue' /* webpackChunkName: "components/v-copied-value" */).then(c => wrapFunctional(c.default || c))
export const LazyVDateInput = import('../../components/ui/v-date-input/VDateInput.vue' /* webpackChunkName: "components/v-date-input" */).then(c => wrapFunctional(c.default || c))
export const LazyVDetail = import('../../components/ui/v-details-popover/VDetail.vue' /* webpackChunkName: "components/v-detail" */).then(c => wrapFunctional(c.default || c))
export const LazyVDetailsPopover = import('../../components/ui/v-details-popover/VDetailsPopover.vue' /* webpackChunkName: "components/v-details-popover" */).then(c => wrapFunctional(c.default || c))
export const LazyVFileInput = import('../../components/ui/v-file-input/VFileInput.vue' /* webpackChunkName: "components/v-file-input" */).then(c => wrapFunctional(c.default || c))
export const LazyVFormGroup = import('../../components/ui/v-form-group/VFormGroup.vue' /* webpackChunkName: "components/v-form-group" */).then(c => wrapFunctional(c.default || c))
export const LazyVIcon = import('../../components/ui/v-icon/VIcon.vue' /* webpackChunkName: "components/v-icon" */).then(c => wrapFunctional(c.default || c))
export const LazyVInput = import('../../components/ui/v-input/VInput.vue' /* webpackChunkName: "components/v-input" */).then(c => wrapFunctional(c.default || c))
export const LazyVMenu = import('../../components/ui/v-menu/VMenu.vue' /* webpackChunkName: "components/v-menu" */).then(c => wrapFunctional(c.default || c))
export const LazyVMenuItem = import('../../components/ui/v-menu/VMenuItem.vue' /* webpackChunkName: "components/v-menu-item" */).then(c => wrapFunctional(c.default || c))
export const LazyVModal = import('../../components/ui/v-modal/VModal.vue' /* webpackChunkName: "components/v-modal" */).then(c => wrapFunctional(c.default || c))
export const LazyVPopoverCloseBtn = import('../../components/ui/v-popover-close-btn/VPopoverCloseBtn.vue' /* webpackChunkName: "components/v-popover-close-btn" */).then(c => wrapFunctional(c.default || c))
export const LazyVPopup = import('../../components/ui/v-popup/VPopup.vue' /* webpackChunkName: "components/v-popup" */).then(c => wrapFunctional(c.default || c))
export const LazyVRadio = import('../../components/ui/v-radio/VRadio.vue' /* webpackChunkName: "components/v-radio" */).then(c => wrapFunctional(c.default || c))
export const LazyVRating = import('../../components/ui/v-rating/VRating.vue' /* webpackChunkName: "components/v-rating" */).then(c => wrapFunctional(c.default || c))
export const LazyVSelect = import('../../components/ui/v-select/VSelect.vue' /* webpackChunkName: "components/v-select" */).then(c => wrapFunctional(c.default || c))
export const LazyVSpacer = import('../../components/ui/v-spacer/VSpacer.vue' /* webpackChunkName: "components/v-spacer" */).then(c => wrapFunctional(c.default || c))
export const LazyVStarRating = import('../../components/ui/v-star-rating/VStarRating.vue' /* webpackChunkName: "components/v-star-rating" */).then(c => wrapFunctional(c.default || c))
export const LazyVStepInput = import('../../components/ui/v-step-input/VStepInput.vue' /* webpackChunkName: "components/v-step-input" */).then(c => wrapFunctional(c.default || c))
export const LazyVSwitch = import('../../components/ui/v-switch/VSwitch.vue' /* webpackChunkName: "components/v-switch" */).then(c => wrapFunctional(c.default || c))
export const LazyVTab = import('../../components/ui/v-tabs/VTab.vue' /* webpackChunkName: "components/v-tab" */).then(c => wrapFunctional(c.default || c))
export const LazyVTabItem = import('../../components/ui/v-tabs/VTabItem.vue' /* webpackChunkName: "components/v-tab-item" */).then(c => wrapFunctional(c.default || c))
export const LazyVTabs = import('../../components/ui/v-tabs/VTabs.vue' /* webpackChunkName: "components/v-tabs" */).then(c => wrapFunctional(c.default || c))
export const LazyVTextarea = import('../../components/ui/v-textarea/VTextarea.vue' /* webpackChunkName: "components/v-textarea" */).then(c => wrapFunctional(c.default || c))
export const LazyVToast = import('../../components/ui/v-toast/VToast.vue' /* webpackChunkName: "components/v-toast" */).then(c => wrapFunctional(c.default || c))
export const LazyVWidget = import('../../components/ui/v-widget/VWidget.vue' /* webpackChunkName: "components/v-widget" */).then(c => wrapFunctional(c.default || c))
export const LazyVWidgetColumn = import('../../components/ui/v-widget/VWidgetColumn.ts' /* webpackChunkName: "components/v-widget-column" */).then(c => wrapFunctional(c.default || c))
export const LazyVWidgetsContainer = import('../../components/ui/v-widget/VWidgetsContainer.vue' /* webpackChunkName: "components/v-widgets-container" */).then(c => wrapFunctional(c.default || c))
export const LazyVWorklog = import('../../components/ui/v-worklog/VWorklog.vue' /* webpackChunkName: "components/v-worklog" */).then(c => wrapFunctional(c.default || c))
export const LazyAmazonTheme = import('../../components/ui/charts/themes/amazonTheme.js' /* webpackChunkName: "components/amazon-theme" */).then(c => wrapFunctional(c.default || c))
export const LazyVLineChart = import('../../components/ui/charts/v-line-chart/VLineChart.vue' /* webpackChunkName: "components/v-line-chart" */).then(c => wrapFunctional(c.default || c))
export const LazyFilterableTable = import('../../components/ui/tables/mixins/filterableTable.js' /* webpackChunkName: "components/filterable-table" */).then(c => wrapFunctional(c.default || c))
export const LazyVDataIterator = import('../../components/ui/tables/v-data-iterator/VDataIterator.vue' /* webpackChunkName: "components/v-data-iterator" */).then(c => wrapFunctional(c.default || c))
export const LazyVDataTable = import('../../components/ui/tables/v-data-table/VDataTable.vue' /* webpackChunkName: "components/v-data-table" */).then(c => wrapFunctional(c.default || c))
export const LazyVDataTableConfigModal = import('../../components/ui/tables/v-data-table/VDataTableConfigModal.vue' /* webpackChunkName: "components/v-data-table-config-modal" */).then(c => wrapFunctional(c.default || c))
export const LazyVDataTableFilters = import('../../components/ui/tables/v-data-table/VDataTableFilters.vue' /* webpackChunkName: "components/v-data-table-filters" */).then(c => wrapFunctional(c.default || c))
export const LazyVPagination = import('../../components/ui/tables/v-data-table/VPagination.vue' /* webpackChunkName: "components/v-pagination" */).then(c => wrapFunctional(c.default || c))
export const LazyVSimpleTable = import('../../components/ui/tables/v-simple-table/VSimpleTable.vue' /* webpackChunkName: "components/v-simple-table" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
export function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
