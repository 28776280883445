//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VModal from '@/components/ui/v-modal/VModal'
import VActionsGroup from '@/components/ui/v-actions-group/VActionsGroup'
import VBtn from '@/components/ui/v-btn/VBtn'
import VCheckbox from '@/components/ui/v-checkbox/VCheckbox'
import headersVisibilityMixin from '@/components/ui/tables/mixins/headersVisibilityMixin'
import VSwitch from '@/components/ui/v-switch/VSwitch'

export default {
  name: 'VDataTableConfigModal',
  components: { VSwitch, VCheckbox, VBtn, VActionsGroup, VModal },
  mixins: [headersVisibilityMixin],
  model: {
    prop: 'value',
    event: 'toggle',
  },
  props: {
    enableWidgets: {
      required: false,
      type: Boolean,
      default: false,
    },
    widgetsVisible: {
      required: false,
      type: [Boolean, Object], // Object for null
      default: null,
    },
    headers: {
      required: true,
      type: Array, // Array of objects. better to rewrite with TS
    },
    value: {
      required: true,
      type: Boolean,
    },
    alwaysVisibleHeaders: {
      required: false,
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      widgetsVisibleLocal: true,
      widgetsVisibleLocalPrevValue: true,
    }
  },
  created() {
    if (this.widgetsVisible !== null) {
      this.widgetsVisibleLocal = this.widgetsVisible
      this.$watch('value', (val, _oldVal) => {
        if (val === false) {
          // sync value with prop
          this.widgetsVisibleLocal = this.widgetsVisible
        }
      })
    } else {
      this.$watch('value', (val, _oldVal) => {
        if (val === false) {
          // reset value
          this.widgetsVisibleLocal = this.widgetsVisibleLocalPrevValue
        }
      })
    }
  },
  methods: {
    getPath() {
      const version = 'default'
      return `${this.$route.name}.${this.$store.state.base.buildVersion}.${version}`
    },
    onToggle(value) {
      this.$emit('toggle', value)
    },
    saveConfig() {
      window.localStorage.setItem(
        this.getPath() + '.settings',
        JSON.stringify(this.visibleHeaders)
      )
      this.$emit('update:visibleHeaders', this.visibleHeaders)
      if (this.enableWidgets) {
        this.$emit('update:widgetsVisibility', this.widgetsVisibleLocal)

        // update 'reset' value
        if (this.widgetsVisible === null) {
          this.widgetsVisibleLocalPrevValue = this.widgetsVisibleLocal
        }
      }
    },
  },
}
