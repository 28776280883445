import { render, staticRenderFns } from "./VFileInput.vue?vue&type=template&id=96bc0c64&scoped=true&"
import script from "./VFileInput.vue?vue&type=script&lang=js&"
export * from "./VFileInput.vue?vue&type=script&lang=js&"
import style0 from "./VFileInput.vue?vue&type=style&index=0&id=96bc0c64&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "96bc0c64",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {VBtn: require('/app/components/ui/v-btn/VBtn.vue').default,VInput: require('/app/components/ui/v-input/VInput.vue').default,VIcon: require('/app/components/ui/v-icon/VIcon.vue').default,VMenuItem: require('/app/components/ui/v-menu/VMenuItem.vue').default,VMenu: require('/app/components/ui/v-menu/VMenu.vue').default})
