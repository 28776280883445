import { render, staticRenderFns } from "./VSelect.vue?vue&type=template&id=337cdf67&scoped=true&"
import script from "./VSelect.vue?vue&type=script&lang=js&"
export * from "./VSelect.vue?vue&type=script&lang=js&"
import style0 from "./VSelect.vue?vue&type=style&index=0&id=337cdf67&lang=scss&scoped=true&"
import style1 from "./VSelect.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "337cdf67",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {VSelect: require('/app/components/ui/v-select/VSelect.vue').default,VInput: require('/app/components/ui/v-input/VInput.vue').default})
