export default {
  setDashboardData(state, value) {
    state.dashboardData = value
  },
  setCompetitor(state, value) {
    state.competitor = value
  },
  setCompetitorsRowData(state, value) {
    state.competitorsRowData = value
  },
  setFilterType(state, value) {
    state.filterType = value
  },
  setOurProducts(state, value) {
    state.ourProducts = value
  },
  setCompetitorBSR(state, value) {
    state.competitorBSR = value
  },
  setCompetitorsAsinList(state, value) {
    state.competitorsAsinList = value
  },
  setCountries(state, value) {
    state.countries = value
  },
  setProducts(state, value) {
    state.products = value
  },
  setOurProductBSR(state, value) {
    state.ourProductBSR = value
  },
  setOurRowData(state, value) {
    state.ourRowData = value
  },
  setAsin(state, value) {
    state.asin = value
  },
  setImage(state, value) {
    state.image = value
  },
  setCountry(state, value) {
    state.country = value
  },
  setProduct(state, value) {
    state.product = value
  },
  setBestsellerPaths(state, value) {
    state.bestsellerPaths = value
  },
  setDateRange(state, value) {
    state.dateRange = value.dateRange
    state.lowerAvailableDate = value.lowerAvailableDate
    state.upperAvailableDate = value.upperAvailableDate
  },
  setProductId(state, value) {
    state.productId = value
  },
}
