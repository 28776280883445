import { render, staticRenderFns } from "./VDrawerMenu.vue?vue&type=template&id=533d9099&scoped=true&"
import script from "./VDrawerMenu.vue?vue&type=script&lang=js&"
export * from "./VDrawerMenu.vue?vue&type=script&lang=js&"
import style0 from "./VDrawerMenu.vue?vue&type=style&index=0&id=533d9099&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "533d9099",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {VIcon: require('/app/components/ui/v-icon/VIcon.vue').default,VCardContent: require('/app/components/ui/v-card/VCardContent.vue').default,VCard: require('/app/components/ui/v-card/VCard.vue').default,VCollapse: require('/app/components/ui/v-collapse/VCollapse.vue').default})
