//
//
//
//
//
//
//

export default {
  name: 'VBadge',
  props: {
    /**
     * Semitransparent style
     */
    semitransparent: {
      required: false,
      type: Boolean,
      default: false,
    },
    /**
     * Pill style
     */
    pill: {
      required: false,
      type: Boolean,
      default: false,
    },
    /**
     * Color of badge
     * @values primary, secondary, success, info, warning, danger, light, dark
     */
    color: {
      required: false,
      type: String,
      default: null,
    },
    /**
     * Text color
     */
    textColor: {
      required: false,
      type: String,
      default: null,
    },
  },
  computed: {
    classes() {
      const classes = {
        'v-badge--semitransparent': this.semitransparent,
        'v-badge--pill': this.pill,
      }

      const colorClass = this.getColorClass(false)
      if (colorClass !== null) {
        classes[colorClass] = true
      }

      const textColorClass = this.getColorClass(true)
      if (textColorClass !== null) {
        classes[textColorClass] = true
      }

      return classes
    },
    style() {
      const style = {}
      const colorClass = this.getColorClass(false)
      if (colorClass === null && this.color) {
        style.background = this.color
      }
      const textColorClass = this.getColorClass(true)
      if (textColorClass === null && this.textColor) {
        style.color = this.textColor
      }
      return style
    },
  },
  methods: {
    getColorClass(forText = false) {
      const colors = [
        'primary',
        'secondary',
        'success',
        'info',
        'warning',
        'danger',
        'light',
        'dark',
        'body-bg',
        'body-color',
      ]

      const color = forText ? this.textColor : this.color

      if (!colors.includes(color)) {
        return null
      }

      if (forText === true) {
        return `v-badge--text-${color}`
      }

      return `v-badge--${color}`
    },
  },
}
