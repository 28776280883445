//
//
//
//

import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
import am4themesAnimated from '@amcharts/amcharts4/themes/animated'
import am4themesAmazon from '../themes/amazonTheme'

// eslint-disable-next-line camelcase
am4core.useTheme(am4themesAnimated)
am4core.useTheme(am4themesAmazon)

export default {
  name: 'VLineChart',
  props: {
    /**
     * Chart's data
     */
    data: {
      required: true,
      type: Array,
    },

    /**
     * A list of chart's series.
     */
    series: {
      required: true,
      type: Array,
    },

    /**
     * Update chart via callback function
     * proto: `postInitCallback({
     *           chart,
     *           dateAxis,
     *           valueAxis,
     *           extraData,
     *         })`
     */
    postInitCallback: {
      required: false,
      type: Function,
      default: null,
    },

    /**
     * Extra data for current chart (current instance of chart component).
     * Used in `postInitCallback`
     */
    extraData: {
      required: false,
      type: [Object, String, Number, Array],
      default() {
        return null
      },
    },

    /**
     * disableScrollbarX - ScrollbarX will not be rendered
     */
    disableScrollbarX: {
      required: false,
      type: Boolean,
      default: false,
    },

    /**
     * Setting for dateAxis.renderer.minGridDistance
     */
    dateAxisMinGridDistance: {
      required: false,
      type: Number,
      default: undefined,
    },

    /**
     * Setting for valueAxis.renderer.minGridDistance
     */
    valueAxisMinGridDistance: {
      required: false,
      type: Number,
      default: undefined,
    },

    /**
     * Hide default legend
     */
    disableLegend: {
      required: false,
      type: Boolean,
      default: false,
    },

    /**
     * Hide default charts date axis grid (vertical lines)
     */
    disableDateAxisGrid: {
      required: false,
      type: Boolean,
      default: true,
    },

    /**
     * Hide default date axis labels
     */
    disableDateAxisLabels: {
      required: false,
      type: Boolean,
      default: false,
    },

    /**
     * Hide default date axis tooltip (bottom tooltip)
     */
    disableDateAxisCursorTooltip: {
      required: false,
      type: Boolean,
      default: false,
    },

    /**
     * Hide default charts value axis grid (horizontal lines)
     */
    disableValueAxisGrid: {
      required: false,
      type: Boolean,
      default: false,
    },

    /**
     * Hide default charts value axis base grid (horizontal lines at zero value)
     */
    disableValueAxisBaseGrid: {
      required: false,
      type: Boolean,
      default: false,
    },

    /**
     * Hide default value axis labels
     */
    disableValueAxisLabels: {
      required: false,
      type: Boolean,
      default: false,
    },

    /**
     * Hide default value axis tooltip
     */
    disableValueAxisCursorTooltip: {
      required: false,
      type: Boolean,
      default: false,
    },

    /**
     * Remove default chart's paddings
     */
    noPaddings: {
      required: false,
      type: Boolean,
      default: false,
    },

    /**
     * Date axis start location
     * @see https://www.amcharts.com/docs/v4/concepts/axes/positioning-axis-elements/#Setting_where_axis_starts_and_ends for defails
     */
    dateAxisStartLocation: {
      required: false,
      type: Number,
      default: 0.5,
    },

    /**
     * Date axis end location
     * @see https://www.amcharts.com/docs/v4/concepts/axes/positioning-axis-elements/#Setting_where_axis_starts_and_ends for defails
     */
    dateAxisEndLocation: {
      required: false,
      type: Number,
      default: 0.5,
    },

    /**
     * Disable horizontal line on cursor's position
     */
    disableCursorLineY: {
      required: false,
      type: Boolean,
      default: true,
    },

    /**
     * Disable vertical line on cursor's position
     */
    disableCursorLineX: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      /**
       * amCharts chart instance
       */
      chartInstance: null,
    }
  },
  watch: {
    data(val) {
      if (this.chartInstance !== null && this.chartInstance !== undefined) {
        this.chartInstance.data = val
      }
    },
  },
  mounted() {
    // Create chart
    const chart = am4core.create(this.$refs.chartContainer, am4charts.XYChart)

    // Chart's properties
    chart.paddingRight = 20
    if (this.noPaddings) {
      chart.padding(0, 0, 0, 0)
    }

    // Chart's data
    chart.data = this.data

    // date axis (horizontal)
    const dateAxis = chart.xAxes.push(new am4charts.DateAxis())
    dateAxis.renderer.grid.template.location = 0
    dateAxis.renderer.grid.template.disabled = this.disableDateAxisGrid
    dateAxis.renderer.labels.template.disabled = this.disableDateAxisLabels
    dateAxis.cursorTooltipEnabled = !this.disableDateAxisCursorTooltip
    dateAxis.renderer.grid.template.strokeOpacity = 1
    dateAxis.renderer.grid.template.stroke = am4core.color('#c6dfff')
    if (
      this.dateAxisMinGridDistance !== undefined &&
      !isNaN(this.dateAxisMinGridDistance)
    ) {
      dateAxis.renderer.minGridDistance = this.dateAxisMinGridDistance
    }

    // @see https://www.amcharts.com/docs/v4/concepts/axes/positioning-axis-elements/#Setting_where_axis_starts_and_ends
    dateAxis.startLocation = this.dateAxisStartLocation
    dateAxis.endLocation = this.dateAxisEndLocation
    dateAxis.renderer.labels.template.location = 0.0001
    dateAxis.renderer.tooltipLocation = 0

    // value axis (vertical)
    const valueAxis = chart.yAxes.push(new am4charts.ValueAxis())
    valueAxis.renderer.grid.template.disabled = this.disableValueAxisGrid
    valueAxis.renderer.baseGrid.disabled = this.disableValueAxisBaseGrid
    valueAxis.renderer.labels.template.disabled = this.disableValueAxisLabels
    valueAxis.cursorTooltipEnabled = !this.disableValueAxisCursorTooltip
    valueAxis.tooltip.disabled = true
    valueAxis.renderer.minWidth = 35
    valueAxis.renderer.grid.template.strokeOpacity = 1
    valueAxis.renderer.grid.template.stroke = am4core.color('#c6dfff')
    valueAxis.renderer.opposite = true
    if (
      this.valueAxisMinGridDistance !== undefined &&
      !isNaN(this.valueAxisMinGridDistance)
    ) {
      valueAxis.renderer.minGridDistance = this.valueAxisMinGridDistance
    }

    // Chart's scrollbar with series on it's background
    const scrollbarX = new am4charts.XYChartScrollbar()
    chart.scrollbarX = scrollbarX
    chart.scrollbarX.disabled = this.disableScrollbarX
    chart.scrollbarX.parent = chart.bottomAxesContainer
    chart.scrollbarX.height = 32
    // chart.scrollbarX.background.fill = am4core.color('#f2f5fd')
    chart.scrollbarX.background.fill = am4core.color('rgba(46, 136, 249, 0.08)')

    // Chart's line series
    chart.series.template = new am4charts.LineSeries()
    chart.series.template.strokeWidth = 2
    chart.series.template.minBulletDistance = 20
    for (const seriesDataItem of this.series) {
      const series = chart.series.create()
      series.dataFields.dateX = seriesDataItem.dataFields.dateX || 'date'
      series.dataFields.valueY = seriesDataItem.dataFields.dateY || 'value'
      series.dataItems.template.locations.dateX = 0

      if (seriesDataItem.name) {
        series.name = seriesDataItem.name
      }

      series.tooltipText = seriesDataItem.tooltipText || '{valueY.value}'

      // gradient fill
      if (seriesDataItem.gradient !== false) {
        series.fillOpacity = 1
      }
      const fillModifier = new am4core.LinearGradientModifier()
      fillModifier.opacities = [0.3, 0]
      fillModifier.offsets = [0, 1]
      fillModifier.gradient.rotation = 90
      series.segments.template.fillModifier = fillModifier

      // Optional parameters
      if (seriesDataItem.generalColor) {
        series.stroke = am4core.color(seriesDataItem.generalColor)
        series.fill = am4core.color(seriesDataItem.generalColor)
        series.tooltip.background.fill = am4core.color(
          seriesDataItem.generalColor
        )
      }
      if (seriesDataItem.strokeColor) {
        series.stroke = am4core.color(seriesDataItem.strokeColor)
      }
      if (seriesDataItem.fillColor) {
        series.fill = am4core.color(seriesDataItem.fillColor)
      }
      if (seriesDataItem.tooltipColor) {
        series.tooltip.getFillFromObject = false
        series.tooltip.background.fill = am4core.color(
          seriesDataItem.tooltipColor
        )
      }

      if (seriesDataItem.bullet) {
        const bullet = series.bullets.push(new am4charts.CircleBullet())
        bullet.circle.radius = seriesDataItem.bullet.radius || 3.5
        bullet.circle.strokeWidth = 2
        if (seriesDataItem.bullet.fillColor) {
          bullet.circle.fill = am4core.color(seriesDataItem.bullet.fillColor)
        }

        const bulletHover = bullet.states.create('hover')
        bulletHover.properties.scale = 1.3
      }

      if (
        seriesDataItem.tensionX !== undefined &&
        seriesDataItem.tensionX !== null
      ) {
        series.tensionX = seriesDataItem.tensionX
      }

      if (
        seriesDataItem.tensionY !== undefined &&
        seriesDataItem.tensionY !== null
      ) {
        series.tensionY = seriesDataItem.tensionY
      }

      // add series to scrollbar
      scrollbarX.series.push(series)
    }

    chart.series.template.strokeWidth = 2

    // Re-enable colors of scrollbar chart
    // @see https://www.amcharts.com/docs/v4/tutorials/customizing-chart-scrollbar/#Re_enabling_colors
    chart.scrollbarX.scrollbarChart.plotContainer.filters.clear()
    chart.scrollbarX.scrollbarChart.series.each((item) => {
      item.fillOpacity = 1
      const fillModifier = new am4core.LinearGradientModifier()
      fillModifier.opacities = [0.3, 0]
      fillModifier.offsets = [0, 1]
      fillModifier.gradient.rotation = 90
      item.segments.template.fillModifier = fillModifier
    })

    // Chart's legend
    if (!this.disableLegend) {
      chart.legend = new am4charts.Legend()
      chart.legend.useDefaultMarker = false
      chart.legend.position = 'top'
      chart.legend.contentAlign = 'left'
      chart.legend.marginBottom = 16
      chart.legend.markers.template.height = 2
    }

    // Chart's cursor
    chart.cursor = new am4charts.XYCursor()
    chart.cursor.lineY.disabled = this.disableCursorLineY
    chart.cursor.lineX.disabled = this.disableCursorLineX
    // chart.cursor.xAxis = dateAxis

    // postInitCallback
    if (this.postInitCallback !== null && this.postInitCallback !== undefined) {
      this.postInitCallback({
        chart,
        dateAxis,
        valueAxis,
        extraData: this.extraData,
      })
    }

    this.chartInstance = chart
  },

  beforeDestroy() {
    if (this.chartInstance) {
      this.chartInstance.dispose()
    }
  },
}
