import { DirectiveOptions, VNodeDirective, VNode } from 'vue'
import randomString from '@/utils/randomString'

type FixedInsideDirectiveContextItem = {
  ticking: boolean
  scrollHandler: (event: Event) => void
  fixedElementClass: string
  fixedElementWrapperClass: string
}

type FixedInsideVueDirectiveContextVar = {
  items?: Array<FixedInsideDirectiveContextItem>
}

/* eslint-disable camelcase */
interface WindowWithFixedInsideDirectiveContext extends Window {
  __fixed_inside_vue_directive__?: FixedInsideVueDirectiveContextVar
}
/* eslint-enable camelcase */

/**
 * You should add this directive manual to your component.
 * IMPORTANT: Target element/component MUST be with absolute position and 100% height and width
 * AND its parent element MUST be with relative position
 * e.g. usage
 * ```js
 * import fixedInsideDirective from '@/utils/fixedInsideDirective'
 *
 * export default {
 *   // ...
 *   directives: {
 *     fixedInside: fixedInsideDirective
 *   }
 *   // ...
 * }
 * ```
 */
const fixedInsideDirective: DirectiveOptions = {
  inserted(
    el: HTMLElement,
    _binding: VNodeDirective, // DirectiveBinding
    _vnode: VNode,
    _oldVnode: VNode
  ) {
    const parentElement = el.parentElement
    if (parentElement !== null && window !== undefined) {
      const rndString = randomString() // unique id
      const fixedElementClass = 'fi-' + rndString
      const fixedElementWrapperClass = 'fi-wrapper-' + rndString
      el.classList.add(fixedElementClass)
      parentElement.classList.add(fixedElementWrapperClass)

      const onWrapperScroll = (event: Event) => {
        if (
          window !== undefined &&
          window.requestAnimationFrame !== undefined
        ) {
          if (fixedInsideDirectiveContextItem.ticking === false) {
            window.requestAnimationFrame(() => {
              el.style.top = `${(event.target as HTMLElement).scrollTop}px`
              el.style.left = `${(event.target as HTMLElement).scrollLeft}px`
              fixedInsideDirectiveContextItem.ticking = false
            })
            fixedInsideDirectiveContextItem.ticking = true
          }
        } else {
          // Without requestAnimationFrame
          el.style.top = `${(event.target as HTMLElement).scrollTop}px`
          el.style.left = `${(event.target as HTMLElement).scrollLeft}px`
        }
      }

      const windowWithFixedInsideDirectiveContext = window as WindowWithFixedInsideDirectiveContext
      windowWithFixedInsideDirectiveContext.__fixed_inside_vue_directive__ = windowWithFixedInsideDirectiveContext.__fixed_inside_vue_directive__ || {
        items: [],
      }

      // store handler in global context item (see unbind method)
      const fixedInsideDirectiveContextItem: FixedInsideDirectiveContextItem = {
        ticking: false,
        scrollHandler: onWrapperScroll,
        fixedElementClass,
        fixedElementWrapperClass,
      }

      // add item to global context
      windowWithFixedInsideDirectiveContext.__fixed_inside_vue_directive__?.items?.push(
        fixedInsideDirectiveContextItem
      )

      // Initial "scrolled" position
      el.style.top = `${parentElement.scrollTop}px`
      el.style.left = `${parentElement.scrollLeft}px`

      // Add listener
      parentElement.addEventListener(
        'scroll',
        fixedInsideDirectiveContextItem.scrollHandler
      )
    }
  },
  unbind(
    el: HTMLElement,
    _binding: VNodeDirective, // DirectiveBinding
    _vnode: VNode,
    _oldVnode: VNode
  ) {
    const needleClass = el.className.split(' ').find((value) => {
      return value.includes('fi-')
    })
    if (needleClass === undefined) {
      return
    }

    const needleWrapperClass = el.className
      .split(' ')
      .find((value) => {
        return value.includes('fi-')
      })
      ?.replace('fi-', 'fi-wrapper-')
    if (needleWrapperClass === undefined) {
      return
    }

    const windowWithFixedInsideDirectiveContext = window as WindowWithFixedInsideDirectiveContext
    const needleWindowContextItem = windowWithFixedInsideDirectiveContext.__fixed_inside_vue_directive__?.items?.find(
      (value) => {
        return value.fixedElementWrapperClass === needleWrapperClass
      }
    )
    if (
      needleWindowContextItem === undefined ||
      needleWindowContextItem === null
    ) {
      return
    }

    const parentElement = document.querySelector('.' + needleWrapperClass)

    if (
      parentElement !== null &&
      parentElement !== undefined &&
      needleWindowContextItem.scrollHandler !== undefined
    ) {
      parentElement.removeEventListener(
        'scroll',
        needleWindowContextItem.scrollHandler
      )
      parentElement.classList.remove(needleWrapperClass)
    }
  },
}

export default fixedInsideDirective
