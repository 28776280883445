//
//
//
//

export default {
  name: 'JsonView',
  props: {
    data: {
      required: true,
      type: Object,
    },
  },
}
