//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import loadingMixin from '@/components/ui/loaders/loadingMixin'
import VBtn from '~/components/ui/v-btn/VBtn'
import VIcon from '~/components/ui/v-icon/VIcon'

export default {
  name: 'VPagination',
  components: {
    VBtn,
    VIcon,
  },
  mixins: [loadingMixin],
  props: {
    /**
     * Count of all pages
     */
    totalPages: {
      required: true,
      type: Number,
    },
    /**
     * Number of current page
     */
    currentPage: {
      required: true,
      type: Number,
    },
  },
  data() {
    return {
      /**
       * How much buttons with pages numbers will be rendered
       * (the half of them will be before current page and the half after)
       */
      visiblePagesCount: 3,
    }
  },
  computed: {
    visiblePagesRange() {
      return Math.max(2, this.visiblePagesCount)
    },
    displayPages() {
      const pages = []

      // currentPage relative position
      if (this.currentPage < this.visiblePagesRange) {
        // left
        // first N (this.visiblePagesRange or less) pages
        for (
          let i = 1;
          i <= Math.min(this.visiblePagesRange, this.totalPages);
          i++
        ) {
          pages.push({
            number: i,
            to: null,
            disabled: this.currentPage === i,
          })
        }

        // dots
        pages.push({
          number: '...',
          to: null,
          disabled: true,
        })

        // last page
        pages.push({
          number: this.totalPages,
          to: null,
          disabled: this.currentPage === this.totalPages,
        })
      } else if (
        this.currentPage >
        this.totalPages - (this.visiblePagesRange - 1)
      ) {
        // right
        // first page
        pages.push({
          number: 1,
          to: null,
          disabled: this.currentPage === 1,
        })

        // dots
        pages.push({
          number: '...',
          to: null,
          disabled: true,
        })

        // last N pages (this.visiblePagesRange)
        for (let i = this.visiblePagesRange - 1; i >= 0; i--) {
          const pageNumber = this.totalPages - i
          pages.push({
            number: pageNumber,
            to: null,
            disabled: this.currentPage === pageNumber,
          })
        }
      } else {
        // middle
        // first page
        pages.push({
          number: 1,
          to: null,
          disabled: this.currentPage === 1,
        })

        // dots
        if (this.currentPage >= this.visiblePagesRange) {
          pages.push({
            number: '...',
            to: null,
            disabled: true,
          })
        }

        // how many pages need to subtract from visiblePagesRange before dividing
        // if visiblePagesRange even - subtract only current page, else - current page and another one (in order to to get even number)
        let middlePagesRangeFactor = this.visiblePagesRange % 2 === 0 ? 2 : 1

        // show only 1 page in the middle if need to show 3
        // example: 1 ... 3 ... 26 | but NOT: 1 ... 2 3 4 ... 26
        if (this.visiblePagesRange === 3) {
          middlePagesRangeFactor = 3
        }

        // middle N pages (this.visiblePagesRange)
        for (
          let i = -((this.visiblePagesRange - middlePagesRangeFactor) / 2);
          i <= (this.visiblePagesRange - middlePagesRangeFactor) / 2;
          i++
        ) {
          const pageNumber = this.currentPage + i
          pages.push({
            number: pageNumber,
            to: null,
            disabled: this.currentPage === pageNumber,
          })
        }

        // dots
        pages.push({
          number: '...',
          to: null,
          disabled: true,
        })

        // last page
        pages.push({
          number: this.totalPages,
          to: null,
          disabled: this.currentPage === this.totalPages,
        })
      }

      return pages
    },
  },
  watch: {
    '$breakpoints.smAndDown': {
      handler(val, _oldVal) {
        if (val) {
          this.visiblePagesCount = 3
        } else {
          this.visiblePagesCount = 5
        }
      },
      // immediate: true,
    },
  },
  mounted() {
    this.visiblePagesCount = this.$breakpoints.smAndDown ? 3 : 5
  },
  methods: {
    onPageClick(page) {
      /**
       * Current page changed
       */
      this.$emit('page-click', page)
    },
  },
}
