function ucfirst(str) {
  if (str.charAt(1) === str.charAt(1).toUpperCase()) {
    return str.toUpperCase()
  }
  return str.replace(
    /(\w)(\w*)/g,
    (_, g1, g2) => g1.toUpperCase() + g2.toLowerCase()
  )
}

export default {
  name: 'BreadcrumbsMixin',
  head() {
    return {
      title: this.breadcrumbs.map((item) => item.text).join(' | '),
    }
  },
  computed: {
    breadcrumbs() {
      const fullPath = this.$route.fullPath
      const crumbs = fullPath.split('/').splice(1)
      let curUrl = ''

      const breadcrumbs = crumbs.map((crumb) => {
        curUrl += '/' + crumb
        const route = this.$router.resolve(curUrl)
        const to = route.resolved.matched.length === 0 ? null : curUrl
        return { to, text: ucfirst(crumb).replaceAll('-', ' ') }
      })

      const lastBreadcrumbs = {
        text: ucfirst(breadcrumbs.pop().text.split(/[?&]/)[0]),
        to: null,
      }

      return [...breadcrumbs, lastBreadcrumbs]
    },
  },
}
