import { render, staticRenderFns } from "./VStarRating.vue?vue&type=template&id=14c54930&scoped=true&"
import script from "./VStarRating.vue?vue&type=script&lang=js&"
export * from "./VStarRating.vue?vue&type=script&lang=js&"
import style0 from "./VStarRating.vue?vue&type=style&index=0&id=14c54930&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "14c54930",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {VIcon: require('/app/components/ui/v-icon/VIcon.vue').default})
