export const state = () => ({
  details: false,
})

export const mutations = {
  showDetails(state, value) {
    state.details = value
  },
  hideDetails(state) {
    state.details = false
  },
}
