import getSelectedNode from '~/utils/getSelectedNode'

export default function ({ route }) {
  const items = localStorage.menuItems ? JSON.parse(localStorage.menuItems) : []
  const lastModule = getSelectedNode(route, items)

  if (lastModule) {
    localStorage.lastModule = lastModule?.to.name
  }
}
