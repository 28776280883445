//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'VApp',
  computed: {
    isMobile() {
      return this.$breakpoints.smAndDown // fixme: fix reactivity
    },
  },
}
