//
//
//
//
//
//
//

export default {
  name: 'BaseField',
  props: {
    title: {
      required: true,
      type: String,
    },
    icon: {
      required: false,
      type: String,
      default: null,
    },
  },
}
