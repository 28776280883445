//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Multiselect from 'vue-multiselect'
import VIcon from '@/components/ui/v-icon/VIcon'
import VProductSelect from '@/components/common/v-product-select/VProductSelect'

export default {
  name: 'VCountrySelect',
  components: {
    Multiselect,
    VIcon,
  },
  mixins: [VProductSelect],
  props: {
    label: {
      required: false,
      type: String,
      default: 'full_name',
    },
  },
  methods: {
    getCustomLabel(selectedItem) {
      return `Selected: ${selectedItem.full_name}`
    },
  },
}
