export default {
  getOrders({ commit }) {
    return this.$axios.get(`api-v2/api.php?action=orders`).then((response) => {
      commit('setOrders', response.data)
    })
  },
  updateOrder({ commit }, order) {
    return this.$axios
      .get(`api-v2/api.php?action=order&id=${order.id}`)
      .then((response) => {
        commit('updateOrder', response.data)
      })
  },
  getShipments({ commit }) {
    return this.$axios
      .get(`api-v2/api.php?action=shipments`)
      .then((response) => {
        commit('setShipments', response.data)
      })
  },
  getProducts({ commit }) {
    return this.$axios
      .get(`api-v2/api.php?action=products`)
      .then((response) => {
        commit('setProducts', response.data)
      })
  },
  getProductParams({ commit }) {
    return this.$axios
      .get(`/api-v1/warehouse-dashboard/get-data`)
      .then((response) => {
        commit('setProductParams', response.data)
      })
  },
  getItems({ commit }) {
    return this.$axios.get(`api-v2/api.php?action=items`).then((response) => {
      commit('setItems', response.data)
    })
  },
  getPersons({ commit }) {
    return this.$axios.get(`api-v2/api.php?action=persons`).then((response) => {
      commit('setPersons', response.data)
    })
  },
  getWarehousesStock({ commit }) {
    return this.$axios
      .get(`api-v2/api.php?action=getWarehousesStockAll`)
      .then((response) => {
        commit('setWarehousesStock', response.data)
      })
  },
  getSuppliers({ commit }) {
    return this.$axios
      .get(`api-v2/api.php?action=suppliers`)
      .then((response) => {
        commit('setSuppliers', response.data)
      })
  },
  getCountries({ commit }) {
    return this.$axios
      .get(`api-v2/api.php?action=countries`)
      .then((response) => {
        commit('setCountries', response.data)
      })
  },
  getShippingStatuses({ commit }) {
    return this.$axios
      .get(`api-v2/api.php?action=shipping_statuses`)
      .then((response) => {
        commit('setShippingStatuses', response.data)
      })
  },
  getLocalShippingStatuses({ commit }) {
    return this.$axios
      .get(`api-v2/api.php?action=local_shipping_statuses`)
      .then((response) => {
        commit('setLocalShippingStatuses', response.data)
      })
  },
  getManufacturingStatuses({ commit }) {
    return this.$axios
      .get(`api-v2/api.php?action=manufacturing_statuses`)
      .then((response) => {
        commit('setManufacturingStatuses', response.data)
      })
  },
  getShippingMethods({ commit }) {
    return this.$axios
      .get(`api-v2/api.php?action=shipping_methods`)
      .then((response) => {
        commit('setShippingMethods', response.data)
      })
  },
  getShippingTypes({ commit }) {
    return this.$axios
      .get(`api-v2/api.php?action=shipmentTypes`)
      .then((response) => {
        commit('setShippingTypes', response.data)
      })
  },
  getWarehouses({ commit }) {
    return this.$axios
      .get(`api-v2/api.php?action=warehouses`)
      .then((response) => {
        commit('setWarehouses', response.data)
      })
  },
  async addShipment({ commit }, newShipment) {
    const shipmentResponse = await this.$axios.post(
      '/api-v2/api.php?action=addShipment',
      newShipment
    )
    const shipment = shipmentResponse.data
    shipment.items_ids = [] // hack

    commit('addShipment', shipment)
    return shipment
  },
  async editShipment({ commit }, editedShipmentData) {
    await this.$axios.post(
      '/api-v2/api.php?action=editShipment',
      editedShipmentData
    )

    const changeShipment = await this.$axios.get(
      `/api-v2/api.php?action=shipment&id=${editedShipmentData.shipment_id}`
    )

    commit('editShipment', changeShipment.data)
  },
  async addOrder(
    { commit, dispatch },
    {
      items,
      supplier,
      cargo_ready_date: cargoReadyDate,
      est_departure_date: estDepatrureDate,
      number,
    }
  ) {
    const orderResponse = await this.$axios.post(
      '/api-v2/api.php?action=addOrder',
      {
        supplier_id: supplier?.id,
        cargo_ready_date: cargoReadyDate,
        est_departure_date: estDepatrureDate,
        number,
      }
    )

    const order = orderResponse.data

    commit('addOrder', order)

    const newItems = items.map((item) =>
      Object({
        order_id: order.id,
        product_id: item.product.id,
        count: +item.count,
        manufacturing_status_id: item.manufacturing_status.id,
      })
    )

    await dispatch('addItems', newItems)

    return order
  },
  async editOrder({ commit }, editedOrderData) {
    await this.$axios.post('/api-v2/api.php?action=editOrder', editedOrderData)

    commit('editOrder', editedOrderData)
  },
  async addItems({ commit }, items) {
    const rawItems = items.map((item) =>
      Object({
        product_id: item.product_id,
        count: item.count,
        order_id: item.order_id,
        manufacturing_status_id: item.manufacturing_status_id,
      })
    )

    const itemsResponse = await this.$axios.post(
      '/api-v2/api.php?action=addItems',
      rawItems
    )

    if (itemsResponse.data.success === false) {
      alert(itemsResponse.data.message)
    } else {
      commit('addItems', itemsResponse.data)
    }

    return itemsResponse.data
  },
  async editItems({ commit }, items) {
    await this.$axios.post('/api-v2/api.php?action=editItems', items)

    commit('editItems', items)
  },
  getAll({ dispatch, commit, state }) {
    if (state.fetched) return

    const ordersPromise = dispatch('getOrders')
    const shipmentsPromise = dispatch('getShipments')
    const productsPromise = dispatch('getProducts')
    const itemsPromise = dispatch('getItems')
    const personsPromise = dispatch('getPersons')
    const suppliersPromise = dispatch('getSuppliers')
    const countriesPromise = dispatch('getCountries')
    const manufacturingStatusesPromise = dispatch('getManufacturingStatuses')
    const shippingStatusesPromise = dispatch('getShippingStatuses')
    const localShippingStatusesPromise = dispatch('getLocalShippingStatuses')
    const shippingMethodsPromise = dispatch('getShippingMethods')
    const shippingTypesPromise = dispatch('getShippingTypes')
    const warehousesPromise = dispatch('getWarehouses')
    const warehousesStock = dispatch('getWarehousesStock')
    const productParamsPromise = dispatch('getProductParams')
    Promise.all([
      ordersPromise,
      shipmentsPromise,
      productsPromise,
      itemsPromise,
      suppliersPromise,
      personsPromise,
      countriesPromise,
      shippingStatusesPromise,
      localShippingStatusesPromise,
      manufacturingStatusesPromise,
      shippingMethodsPromise,
      shippingTypesPromise,
      warehousesPromise,
      warehousesStock,
      manufacturingStatusesPromise,
      productParamsPromise,
    ]).then(() => {
      commit('linkData')
      commit('setFetched', true)
    })
  },
}
