
import draggable from 'vuedraggable'
import fixedInsideDirective from '@/utils/fixedInsideDirective'
import headersVisibilityMixin from '@/components/ui/tables/mixins/headersVisibilityMixin'
import genDefaultPreloaderMixin from '@/components/ui/loaders/genDefaultPreloaderMixin'
import loadingMixin from '@/components/ui/loaders/loadingMixin'
import VIcon from '@/components/ui/v-icon/VIcon'
import VCheckbox from '@/components/ui/v-checkbox/VCheckbox'

export default {
  name: 'VSimpleTable',
  directives: {
    fixedInside: fixedInsideDirective,
  },
  mixins: [loadingMixin, headersVisibilityMixin, genDefaultPreloaderMixin],
  props: {
    /**
     * Array of headers (objects)
     * @type {Array<{
     *   text: string,
     *   value: string,
     *   width?: string,
     *   align?: 'left' | 'center' | 'right',
     *   alwaysVisible: boolean
     * }>}
     */
    headers: {
      required: true,
      type: Array, // Array of objects. better to rewrite with TS
    },
    /**
     * List of headers (from `headers` prop) that must be hidden on mobile devices' screens
     */
    hideMobileHeaders: {
      required: false,
      type: Array, // Array of strings
      default() {
        return []
      },
    },
    /**
     * Array of items (entities) for displaying
     */
    items: {
      required: true,
      type: Array, // Array of objects. better to rewrite with TS
    },
    /**
     * Highlight table body's tr via border-left.
     * This is array of [rowIndex => colorString | null]
     */
    rowsColors: {
      required: false,
      type: Array,
      default() {
        return []
      },
    },
    /**
     * Make table rows seletable: add new column with select form controls (checkboxes).
     * Uses with `selected` prop (with `.sync` modifier)
     */
    selectable: {
      required: false,
      type: Boolean,
      default: false,
    },
    /**
     * Allow to select only one row. Uses with `selectable` prop. **Hides select form controls (checkboxes)**
     */
    singleSelect: {
      required: false,
      type: Boolean,
      default: false,
    },
    /**
     * When true table must have al least one selected row. Uses with `selectable` prop
     */
    disallowSingleDeselect: {
      required: false,
      type: Boolean,
      default: false,
    },
    /**
     * List of selected rows. Uses with `selectable` prop
     */
    selected: {
      required: false,
      type: [Array, Object],
      default() {
        // return []
        return this.singleSelect ? null : []
      },
    },
    /**
     * Wrap table within responsive wrapper (div with auto scrollbars)
     */
    responsive: {
      required: false,
      type: Boolean,
      default: false,
    },
    /**
     * Make th's sticky on overflowed table.
     * With this prop you should add
     * `max-height` css rule on div with class
     * `v-simple-table__wrap-(default|responsive) v-simple-table--sticky-headers`
     * (recommend to use `.v-simple-table--sticky-headers`)
     */
    stickyHeaders: {
      required: false,
      type: Boolean,
      default: false,
    },
    /**
     * Label for empty table
     */
    emptyText: {
      required: false,
      type: String,
      default: 'Empty',
    },
    /**
     * Determines have table expanding rows or not. Uses with `expanded` prop (with `.sync` modifier)
     */
    expanding: {
      required: false,
      type: Boolean,
      default: false,
    },
    /**
     * Table's expanded rows. You should manually expand/collapse rows using toggleExpanding method. Uses with `expanding` prop
     */
    expanded: {
      required: false,
      type: [Array, Object],
      default() {
        // return this.singleExpand ? null : [] // use this if need to implement single expand feature
        return []
      },
    },

    /**
     * Determines if rows can be dragged
     */
    draggableRows: {
      required: false,
      type: Boolean,
      default: false,
    },
    /**
     * Vue Draggable (Sortable.js) handle class
     * @see https://sortablejs.github.io/Vue.Draggable/#/handle
     */
    draggableRowsHandler: {
      required: false,
      type: String,
      default: null,
    },

    /**
     * Object with sorting info. Empty for no sorting.
     * Should be used with `.sync` modifier (value applies only on component initialization)
     * Type: Record<string, 'asc' | 'desc' | null>
     */
    sortBy: {
      required: false,
      type: Object,
      default: null,
    },

    /**
     * Allow sorting only by one field at a time.
     */
    singleSortBy: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      /**
       * Local selected rows
       */
      selectedItems: [],

      /**
       * Local expanded rows
       */
      expandedItems: [],

      /**
       * Local sortBy value
       */
      sortByValue: {},
    }
  },
  computed: {
    checkAll: {
      get() {
        if (this.singleSelect) {
          return false
        }
        if (this.items.length === 0) {
          return false
        }
        if (this.selectedItems.length === this.items.length) {
          return true
        }
        if (this.selectedItems.length === 0) {
          return false
        }
        return 'some' // checked not all items
      },
      set(value) {
        // uncheck all
        if (value === false) {
          this.selectedItems = []
        } else {
          // check all
          this.selectedItems = [...this.items]
        }
        this.$emit('update:selected', this.selectedItems)
      },
    },
    isMobile() {
      // fixme ssr
      if (process.server) {
        return false
      }
      return this.$breakpoints.smAndDown
    },
    alwaysVisibleHeaders() {
      return this.headers.filter((header) => header.alwaysVisible === true)
    },
  },
  watch: {
    selected: {
      handler(val, _oldVal) {
        this.selectedItems = val
      },
      immediate: true,
    },
    expanded: {
      handler(val, _oldVal) {
        this.expandedItems = val
      },
      immediate: true,
    },
    sortByValue: {
      handler(_val, _oldVal) {
        if (this.singleSortBy === true) {
          this.$emit('update:sortBy', this.getFirstActiveSortByField())
        } else {
          this.$emit('update:sortBy', this.sortByValue)
        }
      },
      deep: true,
    },
  },
  created() {
    if (this.sortBy) {
      this.sortByValue = this.sortBy
    }
  },
  methods: {
    selectItem(item) {
      if (!this.singleSelect) {
        if (!this.selectedItems.includes(item)) {
          this.selectedItems.push(item)
          this.$emit('update:selected', this.selectedItems)
        }
      } else {
        this.selectedItems = item
        this.$emit('update:selected', this.selectedItems)
      }
    },
    deselectItem(item) {
      if (!this.singleSelect) {
        if (this.selectedItems.includes(item)) {
          const index = this.selectedItems.indexOf(item)
          this.selectedItems.splice(index, 1)
          /**
           * Selected rows list changed
           * @property {Array<Object>} selected Array of selected rows (items)
           */
          this.$emit('update:selected', this.selectedItems)
        }
      } else if (!this.disallowSingleDeselect) {
        this.selectedItems = null
        this.$emit('update:selected', this.selectedItems)
      }
    },
    toggleAllItemsChecked() {
      this.checkAll = this.checkAll !== true
    },
    toggleExpanding(item) {
      if (this.expanding) {
        if (this.expandedItems.includes(item)) {
          const index = this.expandedItems.indexOf(item)
          this.expandedItems.splice(index, 1)
          /**
           * Expanded rows list changed
           */
          this.$emit('update:expanded', this.expandedItems)
        } else {
          this.expandedItems.push(item)
          /**
           * Expanded rows list changed
           * @property {Array<Object>} expanded Array of expanded rows (items)
           */
          this.$emit('update:expanded', this.expandedItems)
        }
      }
    },

    /**
     * Toggle sortBy value for given column
     */
    toggleSortBy(header) {
      if (!this.loading) {
        if (!Object.hasOwnProperty.call(this.sortByValue, header.value)) {
          let newSortByValue = {}

          // if singleSortBy === true -> other columns will not be kept
          if (!this.singleSortBy) {
            newSortByValue = Object.assign(newSortByValue, this.sortByValue)
          }

          newSortByValue = {
            ...newSortByValue,
            [header.value]: null,
          }

          this.sortByValue = newSortByValue
        }

        if (this.sortByValue[header.value] === 'asc') {
          this.sortByValue[header.value] = 'desc'
        } else if (this.sortByValue[header.value] === 'desc') {
          this.sortByValue[header.value] = null
        } else {
          this.sortByValue[header.value] = 'asc'
        }
      }
    },

    getFirstActiveSortByField() {
      for (const key in this.sortByValue) {
        if (
          this.sortByValue[key] !== null &&
          this.sortByValue[key] !== undefined
        ) {
          return {
            [key]: this.sortByValue[key],
          }
        }
      }
      return {}
    },

    /// Animation flow
    beforeEnter(el) {
      // collapsed state before enter transition start
      el.style.height = 0
    },
    enter(el) {
      // set height equal to contents height
      el.style.height = getComputedStyle(el.querySelector('td')).height
      // IMPORTANT: be careful with transition property - width can be animated and it cause "visual" problem
      el.style.width = getComputedStyle(el.parentElement).width
    },
    afterEnter(el) {
      // reset height (height will be auto on expanded state)
      el.style.height = ''
      el.style.width = ''
    },
    beforeLeave(el) {
      // expanded state, ready to collapse: height will be fixed before leave transition start
      el.style.height = getComputedStyle(el.querySelector('td')).height
      el.style.width = getComputedStyle(el.parentElement).width
    },
    leave(el) {
      // set height equal to zero ONLY AFTER beforeLeave hook make height fixed
      requestAnimationFrame(() => {
        el.style.height = 0
      })
    },

    /// render methods
    /**
     * @return Array<VNode>
     */
    genTableHeadThSlots() {
      const tableHeadThSlots = []

      if (this.selectable === true && !this.singleSelect) {
        const selectableHeadTh = this.$createElement(
          'th',
          {
            style: {
              // width: '24px',
              'max-width': '24px',
              'text-align': 'center',
            },
          },
          [
            this.$createElement(VCheckbox, {
              props: {
                groupCheckbox: true,
                inputValue: this.checkAll,
              },
              on: {
                change: this.toggleAllItemsChecked,
              },
            }),
          ]
        )
        tableHeadThSlots.push(selectableHeadTh)
      }

      for (let i = 0; i < this.headers.length; i++) {
        /** @type {{text: string, value: string, width?: string, align?: 'left' | 'center' | 'right', alwaysVisible: boolean, sortable?: boolean }} */
        const header = this.headers[i]

        // skip hidden header
        if (this.visibleHeaders[header.value] === false) {
          continue
        }

        // skip hidden mobile header
        if (
          this.hideMobileHeaders.includes(header.value) &&
          !this.alwaysVisibleHeaders.includes(header) &&
          this.isMobile
        ) {
          continue
        }

        if (this.$scopedSlots['header.' + header.value] !== undefined) {
          const slot = this.$createElement(
            'th',
            {
              style: {
                width: header.width || 'auto',
                'text-align': header.align || 'center',
              },
            },
            this.$scopedSlots['header.' + header.value]({
              header,
            })
          )
          tableHeadThSlots.push(slot)
        } else {
          const thContent = [header.text]

          if (header.sortable === true) {
            thContent.push(this.genSortingArrows(header))
          }

          const slot = this.$createElement(
            'th',
            {
              style: {
                width: header.width || 'auto',
                'text-align': header.align || 'left',
              },
            },
            thContent
          )
          tableHeadThSlots.push(slot)
        }
      }
      return tableHeadThSlots
    },

    /**
     * @return VNode
     */
    genSortingArrows(header) {
      return this.$createElement(
        'div',
        {
          class: {
            'sorting-arrows': true,
          },
          on: {
            click: (_event) => {
              this.toggleSortBy(header)
            },
          },
        },
        [
          this.$createElement(
            VIcon,
            {
              props: {
                width: '8px',
                height: '11px',
                color:
                  this.sortByValue[header.value] &&
                  this.sortByValue[header.value] === 'asc'
                    ? '#1951D9'
                    : '#C7D0E9',
              },
            },
            ['sort-asc']
          ),
          this.$createElement(
            VIcon,
            {
              props: {
                width: '8px',
                height: '11px',
                color:
                  this.sortByValue[header.value] &&
                  this.sortByValue[header.value] === 'desc'
                    ? '#1951D9'
                    : '#C7D0E9',
              },
            },
            ['sort-desc']
          ),
        ]
      )
    },

    /**
     * @return Array<VNode>
     */
    genTableHeadSlot() {
      if (this.$scopedSlots.thead !== undefined) {
        return this.$scopedSlots.thead({
          headers: this.headers,
        })
      }
      return this.$createElement('tr', {}, this.genTableHeadThSlots())
    },
    /**
     * @return Array<VNode>
     */
    genTableHead() {
      return this.$createElement('thead', {}, [this.genTableHeadSlot()])
    },

    /**
     * @return Array<VNode>
     */
    genTableBodyTdSlots(index) {
      const tableBodyTdSlots = []
      const item = this.items[index]

      if (this.selectable === true && !this.singleSelect) {
        const selectableBodyTd = this.$createElement(
          'td',
          {
            style: {
              'max-width': '24px',
              'text-align': 'center',
            },
          },
          [
            this.$createElement(VCheckbox, {
              props: {
                inputValue: this.selectedItems.includes(item),
              },
              on: {
                change: (value) => {
                  if (value === true) {
                    this.selectItem(item)
                  } else {
                    this.deselectItem(item)
                  }
                },
              },
            }),
          ]
        )
        tableBodyTdSlots.push(selectableBodyTd)
      }

      for (let i = 0; i < this.headers.length; i++) {
        /** @type {{text: string, value: string, width?: string, align?: 'left' | 'center' | 'right', alwaysVisible: boolean, sortable?: boolean }} */
        const header = this.headers[i]

        // skip hidden header
        if (this.visibleHeaders[header.value] === false) {
          continue
        }

        // skip hidden mobile header
        if (
          this.hideMobileHeaders.includes(header.value) &&
          !this.alwaysVisibleHeaders.includes(header) &&
          this.isMobile
        ) {
          continue
        }

        if (this.$scopedSlots['item.' + header.value] !== undefined) {
          const slot = this.$createElement(
            'td',
            {
              style: {
                'max-width': header.width || 'none',
                'text-align': header.align || 'left',
              },
            },
            this.$scopedSlots['item.' + header.value]({
              item,
              header,
              headers: this.headers,
              index,
              selected: this.selectedItems,
              expanded: this.expandedItems,
              isExpanded: this.expandedItems.includes(item),
              toggleExpanding: this.toggleExpanding,
            })
          )
          tableBodyTdSlots.push(slot)
        } else {
          const slot = this.$createElement(
            'td',
            {
              style: {
                'max-width': header.width || 'none',
                'text-align': header.align || 'left',
              },
            },
            item[header.value]
          )
          tableBodyTdSlots.push(slot)
        }
      }
      return tableBodyTdSlots
    },
    /**
     * @return Array<VNode>
     */
    genTableBodySlot() {
      if (this.$scopedSlots.tbody !== undefined) {
        return this.$scopedSlots.tbody({
          headers: this.headers,
          items: this.items,
          selected: this.selectedItems,
          toggleExpanding: this.toggleExpanding,
        })
      }
      const tableBodyRows = []
      for (let i = 0; i < this.items.length; i++) {
        const item = this.items[i]
        // singleSelect: select on click
        const singleSelectListeners = {}
        if (this.singleSelect) {
          singleSelectListeners.click = (event) => {
            // fixme or leave as is
            if (event.target.tagName.toLowerCase() !== 'td') {
              return
            }

            if (this.selectedItems === this.items[i]) {
              this.deselectItem(this.items[i])
            } else {
              this.selectItem(this.items[i])
            }
          }
        }

        const trStyleClasses = {}
        if (this.rowsColors[i] && this.rowsColors[i] !== null) {
          trStyleClasses[`table-row--${this.rowsColors[i]}`] = true
        }

        tableBodyRows.push(
          this.$createElement(
            'tr',
            {
              class: {
                'table-row--highlighting': item.highlighting,
                selected: !this.singleSelect
                  ? this.selectedItems.includes(this.items[i])
                  : this.selectedItems === this.items[i],
                'selectable-row--single': this.singleSelect,
                ...trStyleClasses,
              },
              on: {
                ...singleSelectListeners,
              },
              key: `table-row-${i}`,
            },
            this.genTableBodyTdSlots(i)
          )
        )

        if (this.expanding) {
          const children = []
          if (this.expandedItems.includes(this.items[i])) {
            children.push(
              this.$createElement(
                'tr',
                {
                  style: {
                    overflow: 'hidden',
                  },
                  class: {
                    // inherit from "parent" above
                    selected: !this.singleSelect
                      ? this.selectedItems.includes(this.items[i])
                      : this.selectedItems === this.items[i],
                    'selectable-row--single': this.singleSelect,
                    ...trStyleClasses,
                  },
                  key: `table-row-${i}-expanding-row`,
                },
                [
                  this.$createElement(
                    'td',
                    {
                      attrs: {
                        colspan:
                          this.headers.length +
                          (this.selectable === true ? 1 : 0),
                      },
                    },
                    [
                      this.$scopedSlots.expandedItem({
                        item: this.items[i],
                        headers: this.headers,
                      }),
                    ]
                  ),
                ]
              )
            )
          }
          tableBodyRows.push(
            this.$createElement(
              'transition',
              {
                props: {
                  name: 'row-expanding',
                },
                on: {
                  beforeEnter: this.beforeEnter,
                  enter: this.enter,
                  afterEnter: this.afterEnter,
                  leave: this.leave,
                  beforeLeave: this.beforeLeave,
                },
              },
              children
            )
          )
        }
      }

      // empty table
      if (this.items.length === 0) {
        tableBodyRows.push(
          this.$createElement('tr', [
            this.$createElement(
              'td',
              {
                attrs: {
                  colspan:
                    this.headers.length +
                    (this.selectable && !this.singleSelect),
                },
                class: {
                  'text-center': true,
                  'text-secondary': true,
                },
              },
              this.emptyText
            ),
          ])
        )
      }

      // footer table
      if (this.$scopedSlots.footer) {
        tableBodyRows.push(
          this.$createElement('tr', [
            this.$createElement(
              'td',
              {
                attrs: {
                  colspan:
                    this.headers.length +
                    (this.selectable && !this.singleSelect),
                  style: 'padding: 0',
                },
              },
              this.$scopedSlots.footer()
            ),
          ])
        )
      }

      // after all tr's
      if (this.$scopedSlots['tbody.append'] !== undefined) {
        tableBodyRows.push(
          this.$scopedSlots['tbody.append']({
            headers: this.headers,
            items: this.items,
            selected: this.selectedItems,
          })
        )
      }

      return tableBodyRows
    },
    /**
     * @return Array<VNode>
     */
    genTableBody() {
      if (this.loading && !this.items) {
        return this.$createElement('tbody', {}, [
          this.$createElement('tr', [
            this.$createElement(
              'td',
              {
                attrs: {
                  colspan: this.headers.length,
                },
                class: {
                  'text-center': true,
                },
              },
              ['Loading...']
            ),
          ]),
        ])
      }

      if (this.draggableRows) {
        return this.$createElement(
          draggable,
          {
            props: {
              value: this.items,
              tag: 'tbody',
            },
            attrs: {
              handle: this.draggableRowsHandler,
            },
            on: {
              input: (value) => {
                /**
                 * Vue draggable input event (uses in v-model)
                 * @property {Array<Object>} expanded Array of all rows - ordered (items)
                 */
                this.$emit('update:items', value)
              },
              change: (value) => {
                /**
                 * drag change event
                 * @see https://github.com/SortableJS/Vue.Draggable#events
                 * @property {Object} Event object
                 */
                this.$emit('drag:change', value)
              },
            },
          },
          this.genTableBodySlot()
        )
      }

      return this.$createElement('tbody', {}, this.genTableBodySlot())
    },

    /**
     * @return Array<VNode>
     */
    genTable() {
      const children = [this.genTableHead(), this.genTableBody()]
      return this.$createElement(
        'table',
        {
          class: {
            'v-simple-table': true,
            'v-simple-table--border-highlighting': this.rowsColors.length > 0,
          },
          ref: 'table',
          refInFor: false,
        },
        children
      )
    },
    /**
     * @return VNode
     */
    genSimpleTable() {
      // default table
      let wrapClass = 'v-simple-table__wrap-default'

      if (this.responsive) {
        // add responsive wrapper
        wrapClass = 'v-simple-table__wrap-responsive'
      }

      const children = [this.genTable()]

      if (this.loading) {
        children.unshift(
          this.genDefaultPreloader({
            class: {
              'v-simple-table__preloader': true,
            },
            directives: [
              {
                name: 'fixed-inside',
              },
            ],
          })
        )
      }

      return this.$createElement(
        'div',
        {
          class: {
            [wrapClass]: true,
            'v-simple-table--sticky-headers': this.stickyHeaders,
          },
        },
        children
      )
    },
  },
  /**
   * Slot for specific header
   * @slot 'header.' + header.value
   * @binding {{
   *   text: string,
   *   value: string,
   *   width?: string,
   *   align?: 'left' | 'center' | 'right',
   *   alwaysVisible: boolean
   * }} header Header object
   */
  /**
   * Slot for table's thead content
   * @slot headers
   * @binding {Array<{
   *   text: string,
   *   value: string,
   *   width?: string,
   *   align?: 'left' | 'center' | 'right',
   *   alwaysVisible: boolean
   * }>} headers Headers list
   */
  /**
   * Slot for specific column (every cell in the column)
   * @slot 'item.' + header.value
   * @binding {Object} item Current row's item
   * @binding {{
   *   text: string,
   *   value: string,
   *   width?: string,
   *   align?: 'left' | 'center' | 'right',
   *   alwaysVisible: boolean
   * }} header Header object for current column
   * @binding {Array<{
   *   text: string,
   *   value: string,
   *   width?: string,
   *   align?: 'left' | 'center' | 'right',
   *   alwaysVisible: boolean
   * }>} headers Headers list
   * @binding {number} index Row index
   * @binding {Array<Object>} selected List of selected rows
   * @binding {Array<Object>} expanded List of expanded rows
   * @binding {boolean} isExpanded Determines if current row is expanded
   * @binding {(item) => void} toggleExpanding Method that toggle expanding state for given item
   */
  /**
   * Slot for table's tbody content
   * @slot tbody
   * @binding {Array<Object>} items Array of items (entities)
   * @binding {Array<{
   *   text: string,
   *   value: string,
   *   width?: string,
   *   align?: 'left' | 'center' | 'right',
   *   alwaysVisible: boolean
   * }>} headers Headers list
   * @binding {Array<Object>} selected List of selected rows
   * @binding {(item) => void} toggleExpanding Method that toggle expanding state for given item
   */
  /**
   * Slot for expanded row
   * @slot expandedItem
   * @binding {Object} item Current row's item
   * @binding {Array<{
   *   text: string,
   *   value: string,
   *   width?: string,
   *   align?: 'left' | 'center' | 'right',
   *   alwaysVisible: boolean
   * }>} headers Headers list
   */
  /**
   * Table boyd append slot. Append content after all tr's
   * @slot tbody.append
   * @binding {Array<{
   *   text: string,
   *   value: string,
   *   width?: string,
   *   align?: 'left' | 'center' | 'right',
   *   alwaysVisible: boolean
   * }>} headers Headers list
   * @binding {Array<Object>} items Array of items (entities)
   * @binding {Array<Object>} selected List of selected rows
   */
  render() {
    return this.genSimpleTable()
  },
}
