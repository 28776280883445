//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VCard from '@/components/ui/v-card/VCard'
import VCardHeader from '@/components/ui/v-card/VCardHeader'
import VCardContent from '@/components/ui/v-card/VCardContent'
import DefaultPreloader from '@/components/ui/loaders/DefaultPreloader'
import loadingMixin from '@/components/ui/loaders/loadingMixin'

export default {
  name: 'VWidget',
  components: {
    VCardContent,
    VCardHeader,
    VCard,
    DefaultPreloader,
  },
  mixins: [loadingMixin],
  props: {
    active: {
      required: false,
      type: Boolean,
      default: false,
    },
    widgetColor: {
      required: true,
      type: String,
    },
  },
}
