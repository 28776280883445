
import Vue from 'vue'
import VueTippy, { TippyComponent } from 'vue-tippy'

Vue.use(VueTippy, {
  theme: 'translucent',
  animateFill: false,
  distance: 0,
})

export default {
  extends: TippyComponent,
}
