import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
import themeColors from '~/config/colors'

const borderColor = '#C7D0E9' // extra color // TODO add to theme colors
// const lightBackgroundColor = '#F2F5FD' // extra color // TODO add to theme colors

// eslint-disable-next-line camelcase
function am4themes_amazon(target) {
  if (target instanceof am4core.ColorSet) {
    target.list = [
      am4core.color(themeColors.primary),
      am4core.color(themeColors.danger),
      am4core.color(themeColors.success),
      am4core.color(themeColors.warning),
      am4core.color(themeColors.secondary),
      am4core.color(themeColors.info),
    ]
  }

  if (target instanceof am4core.InterfaceColorSet) {
    // Used for button fill
    target.setFor('primaryButton', am4core.color(themeColors.primary))
    target.setFor(
      'primaryButtonHover',
      am4core.color(themeColors.primary).brighten(0.25)
    )
    target.setFor(
      'primaryButtonDown',
      am4core.color(themeColors.primary).brighten(0.5)
    )
    target.setFor('primaryButtonActive', am4core.color(themeColors.secondary))

    target.setFor('secondaryButton', am4core.color('#ffffff'))
    target.setFor('secondaryButtonHover', am4core.color('#ffffff'))
    target.setFor('secondaryButtonDown', am4core.color('#ffffff').brighten(10))

    // Used for grip lines
    target.setFor('alternativeText', am4core.color(borderColor))
  }

  if (target instanceof am4core.ResizeButton) {
    target.paddingLeft = 4
    target.paddingRight = 4
    target.paddingTop = 8
    target.paddingBottom = 8

    target.background.fill = am4core.color('#fff')
    target.background.width = 20
    target.background.height = am4core.percent(100)
    target.background.cornerRadius(6, 6, 6, 6)
    target.background.stroke = am4core.color(borderColor)

    if (target.icon) {
      target.icon.stroke = am4core.color(borderColor)
    }

    const backgroundHoverState = target.background.states.create('hover')
    backgroundHoverState.properties.fill = am4core.color(themeColors.light)
    backgroundHoverState.properties.stroke = am4core.color(borderColor)

    const backgroundDownState = target.background.states.create('down')
    backgroundDownState.properties.fill = am4core
      .color(themeColors.light)
      .brighten(15)
    backgroundDownState.properties.stroke = am4core.color(borderColor)
  }

  if (target instanceof am4charts.XYChartScrollbar) {
    target.minHeight = 32
  }

  // fixme. now trying to apply to many other classes
  // if (target instanceof am4charts.Axis) {
  //   console.log(target)
  //   target.renderer.grid.template.fill = am4core.color('#c6dfff')
  // }
}

// eslint-disable-next-line camelcase
export default am4themes_amazon
