import { render, staticRenderFns } from "./VDrawerLogo.vue?vue&type=template&id=aa669988&scoped=true&"
import script from "./VDrawerLogo.vue?vue&type=script&lang=js&"
export * from "./VDrawerLogo.vue?vue&type=script&lang=js&"
import style0 from "./VDrawerLogo.vue?vue&type=style&index=0&id=aa669988&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aa669988",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {VIcon: require('/app/components/ui/v-icon/VIcon.vue').default,Logo: require('/app/components/layouts/Logo.vue').default})
