//
//
//
//
//
//

export default {
  name: 'VTabItem',
  props: {
    value: {
      required: true,
      type: [Number, String],
    },
  },
  data() {
    return {
      tabItemValue: null,
    }
  },
  created() {
    if (!isNaN(this.value)) {
      this.tabItemValue = +this.value
    } else {
      // eslint-disable-next-line no-console
      console.error("[VTabItem] prop 'value' must be a number")
    }
  },
}
