//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'VSwitch',
  model: {
    event: 'change',
    prop: 'value',
  },
  props: {
    disabled: {
      required: false,
      type: Boolean,
    },
    // Uses in v-model
    value: {
      required: true,
      type: Boolean,
    },
  },
  computed: {
    isActive() {
      return this.value === true
    },
    selectionClasses() {
      return {
        'v-switch__selection--active': this.isActive,
      }
    },
  },
  methods: {
    onChange(event) {
      event.preventDefault()
      this.$emit('change', !this.value)
      this.$refs.input.checked = this.isActive // fixes dom-element checked prop's value
    },
  },
}
