export default {
  setPreviousViewMode(state, value) {
    state.previousViewMode = value
  },
  setModalAddCategoryVisible(state, value) {
    state.modalAddCategoryVisible = value
  },
  setModalAddComplaintVisible(state, value) {
    state.modalAddComplaintVisible = value
  },
  setModalEditComplaint(state, value) {
    state.modalEditComplaint = value
  },
}
