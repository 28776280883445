//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ssrBootableMixin from '@/mixins/ssrBootableMixin'
import VCard from '@/components/ui/v-card/VCard'
import VPopoverCloseBtn from '@/components/ui/v-popover-close-btn/VPopoverCloseBtn'

export default {
  name: 'VDetailsPopover',
  components: { VCard, VPopoverCloseBtn },
  mixins: [ssrBootableMixin],
  props: {
    width: {
      required: false,
      type: [Number, String],
      default: 'auto',
    },
    popperOptions: {
      required: false,
      type: Object,
      default() {
        return {}
      },
    },
  },
  computed: {
    cardStyle() {
      let width = this.width
      if (!isNaN(width)) {
        width = `${width}px`
      }
      return {
        width,
      }
    },
  },
}
