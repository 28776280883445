import { render, staticRenderFns } from "./VWidget.vue?vue&type=template&id=e28db9c0&"
import script from "./VWidget.vue?vue&type=script&lang=js&"
export * from "./VWidget.vue?vue&type=script&lang=js&"
import style0 from "./VWidget.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./VWidget.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {VCardHeader: require('/app/components/ui/v-card/VCardHeader.vue').default,DefaultPreloader: require('/app/components/ui/loaders/DefaultPreloader.js').default,VCardContent: require('/app/components/ui/v-card/VCardContent.vue').default,VCard: require('/app/components/ui/v-card/VCard.vue').default})
