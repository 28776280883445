//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// using external datepicker: https://www.npmjs.com/package/vue2-datepicker
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'

export default {
  name: 'VDateInput',
  components: { DatePicker },
  props: {
    value: {
      required: false,
      type: [Date, Number],
      default: null,
    },
    type: {
      requred: false,
      type: String,
      default: 'date',
    },
    format: {
      requred: false,
      type: String,
      default: 'MM/DD/YYYY',
    },
    ranged: {
      required: false,
      type: Boolean,
      default: false,
    },
    clearable: {
      required: false,
      type: Boolean,
      default: true,
    },
    label: {
      required: false,
      type: String,
      default: null,
    },
    small: {
      required: false,
      type: Boolean,
      default: false,
    },
    // color: dark, middle, white. Without prop default
    formStyle: {
      required: false,
      type: String,
      default: null,
    },
    hint: {
      required: false,
      type: String,
      default: '',
    },
    error: {
      required: false,
      type: Boolean,
      default: false,
    },
    errorMessage: {
      required: false,
      type: [String, Boolean],
      default: '',
    },
    hideDetails: {
      required: false,
      type: Boolean,
      default: false,
    },
    readonly: {
      required: false,
      type: Boolean,
      default: false,
    },
    // "date"
    returnType: {
      required: false,
      type: String,
      default: 'Number',
    },
    placeholder: {
      required: false,
      type: String,
      default: null,
    },
  },
  data() {
    return {
      dateInternal: this.value ? new Date(this.value) : null,
    }
  },
  computed: {
    isError() {
      return this.error || !!this.errorMessage
    },
    formControlClasses() {
      return {
        datepicker: true,
        default: true,
        dark: this.formStyle === 'dark',
        middle: this.formStyle === 'middle',
        white: this.formStyle === 'white',
        error: this.isError,
        small: this.small,
      }
    },
    localDate() {
      return this.dateInternal && this.dateInternal.toLocaleDateString('en-US')
    },
  },
  watch: {
    value() {
      this.dateInternal = this.value ? new Date(this.value) : null
    },
  },
  methods: {
    addSlashes(v) {
      v.target.value = v.target.value
        .replace(/^(\d\d)(\d)$/g, '$1/$2')
        .replace(/^(\d\d\/\d\d)(\d+)$/g, '$1/$2')
        .replace(/[^\d/]/g, '')
    },
    onInput() {
      if (this.returnType === 'date') {
        this.$emit('input', this.dateInternal ? this.dateInternal : null)
      } else {
        this.$emit(
          'input',
          this.dateInternal ? this.dateInternal.getTime() : null
        )
      }
    },
  },
}
