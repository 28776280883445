//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VIcon from '@/components/ui/v-icon/VIcon'

export default {
  name: 'VAlert',
  components: {
    VIcon,
  },
  props: {
    /**
     * Alert's type
     * @values info, danger
     */
    type: {
      required: false,
      type: String,
      default: 'info',
    },
  },
  computed: {
    prependIconComponent() {
      let iconComponent = null

      switch (this.type) {
        case 'info':
          iconComponent = {
            icon: 'info',
            pack: 'ui-icons',
          }
          break
        case 'danger':
          iconComponent = {
            icon: 'info-circle',
            pack: 'default',
            size: '1.5em',
            color: '#f81135',
          }
          break
      }
      return iconComponent
    },
    classes() {
      return {
        'v-alert--info': this.type === 'info',
        'v-alert--danger': this.type === 'danger',
      }
    },
  },
}
