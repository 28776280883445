//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'VWorkLog',
  props: {
    workLogList: {
      required: false,
      type: [Array],
      default: null,
    },
  },
  data: () => ({
    showMoreComplaint: false,
  }),
  computed: {
    currentWorkLogList() {
      if (!this.workLogList) return null
      const workLoglength = this.workLogList.length
      return this.workLogList.slice(
        0,
        this.showMoreComplaint ? workLoglength : 2
      )
    },
  },
}
