
import { VPopover } from 'v-tooltip'
import ssrBootableMixin from '@/mixins/ssrBootableMixin'
import VBtn from '../v-btn/VBtn'
import VCard from '../v-card/VCard'
import VIcon from '~/components/ui/v-icon/VIcon'

export default {
  name: 'VMenu',
  mixins: [ssrBootableMixin],
  props: {
    menuOverlay: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      active: false,
      activatorHeight: 0,
    }
  },
  computed: {
    menuOffset() {
      if (this.menuOverlay === false) {
        return 0
      }
      if (!isNaN(this.activatorHeight)) {
        return this.activatorHeight * -1
      }
      return +this.activatorHeight.replace(/\D+/g, '') * -1
    },
  },
  mounted() {
    const activatorWrapper = this.$refs.menu.$el.querySelector(
      '.v-menu__activator-wrapper'
    )
    if (activatorWrapper) {
      this.activatorHeight = getComputedStyle(activatorWrapper).height
    }
  },
  methods: {
    parseNodes() {
      const slot = this.$slots.default || []
      const menuItems = []
      const activator = this.$scopedSlots.activator
        ? this.$scopedSlots.activator({
            toggle: this.toggle,
          })[0]
        : null
      const nodesLength = slot.length
      if (nodesLength > 0) {
        for (let i = 0; i < nodesLength; i++) {
          /** @type {VNode} */
          const node = slot[i]
          if (node.componentOptions) {
            // if node's constructor name is VMenuItem
            if (node.componentOptions.Ctor.options.name === 'VMenuItem') {
              menuItems.push(node)
            }
          }
        }
      }

      return {
        menuItems,
        activator, // button that toggle menu
      }
    },
    toggle(value = null) {
      if (value === null || typeof value !== 'boolean') {
        this.active = !this.active
      } else {
        this.active = value
      }
    },
  },
  render(createElement) {
    // eslint-disable-next-line prefer-const
    let { menuItems, activator } = this.parseNodes()
    if (activator === null) {
      activator = createElement(
        VBtn,
        {
          class: {
            'v-menu-activator': true,
          },
          props: {
            iconContent: true,
            text: true,
            color: 'secondary',
            hoverColor: 'primary',
          },
          on: {
            click: () => {
              this.toggle()
            },
          },
          slot: 'default',
        },
        [createElement(VIcon, 'more')]
      )
    }

    return createElement(
      VPopover,
      {
        class: {
          'v-menu': true,
        },
        props: {
          trigger: 'manual',
          open: this.active,
          placement: this.menuOverlay ? 'top-end' : 'bottom-end',
          popoverClass: 'tooltip-no-arrows',
          popoverWrapperClass: [
            'wrapper',
            {
              'd-none': !this.isBooted,
            },
          ],
          offset: this.menuOffset,
        },
        on: {
          'update:open': this.toggle,
        },
        ref: 'menu',
        refInFor: false,
      },
      [
        // visible activator button
        createElement(
          'div',
          {
            class: {
              'v-menu__activator-wrapper': true,
            },
          },
          [activator]
        ),

        // menu items inside popover
        createElement(
          VCard,
          {
            slot: 'popover',
            class: {
              'v-menu__card': true,
            },
          },
          [
            // menu items with close-popover directive
            menuItems.map((item) => {
              item.data = item.data || {}
              item.data.directives = item.data.directives || []
              item.data.directives.push({
                name: 'close-popover',
                value: true,
              })

              return item
            }),
          ]
        ),
      ]
    )
  },
}
