//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Multiselect from 'vue-multiselect'
import VIcon from '@/components/ui/v-icon/VIcon'
import VBtn from '@/components/ui/v-btn/VBtn'
import VCheckbox from '@/components/ui/v-checkbox/VCheckbox'
import VProductMultiselect from '@/components/common/v-product-multiselect/VProductMultiselect'

export default {
  name: 'VAlertTypesMultiselect',
  components: {
    VCheckbox,
    Multiselect,
    VIcon,
    VBtn,
  },
  mixins: [VProductMultiselect],
  props: {
    label: {
      required: false,
      type: String,
      default: 'name',
    },
  },
  methods: {
    limitText(count) {
      let word = 'alert types'
      if (count === 1) {
        word = 'alert type'
      }
      return `Selected: ${count} ${word}`
    },
  },
}
