export default {
  updateModalToggle(state, item) {
    state.updateModal = item
  },
  imageModalToggle(state, item) {
    state.imageModal = item
  },
  MonitorModalToggle(state, item) {
    state.MonitorModal = item
  },
}
