//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { AgGridVue } from 'ag-grid-vue'
import CustomDateFilter from '@/components/ag-grid/CustomDateFilter'
import columnState from '@/mixins/ColumnState'
import ColumnSettingsMenu from './ColumnSettingsMenu'

export default {
  name: 'AgGridVue',
  components: { ColumnSettingsMenu },
  extends: AgGridVue,
  mixins: [columnState],
  props: {
    columnState: {
      type: Boolean,
      default: true,
    },
    customDefaultFilterModel: {
      type: Object,
      default: () => ({}),
    },
    notSavingFilterTableState: {
      type: Boolean,
      default: false,
    },
    title: {
      require: false,
      type: String,
      default: null,
    },
    configurationPossibility: {
      require: false,
      type: Boolean,
      default: true,
    },
    headerPanel: {
      require: false,
      type: Boolean,
      default: true,
    },
    columnsStateVersion: {
      require: false,
      type: String,
      default: 'default',
    },
    exportExcelButton: {
      require: false,
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      showColumnSettings: false,
    }
  },

  beforeMount() {
    this.gridOptions = Object.assign(this.gridOptions, {
      getMainMenuItems: this.uepGetMainMenuItems,
      getContextMenuItems: this.uepGetContextMenuItems,
    })

    if (this.frameworkComponents && !this.frameworkComponents.agDateInput) {
      this.frameworkComponents = Object.assign(this.frameworkComponents, {
        agDateInput: CustomDateFilter,
      })
    } else {
      this.gridOptions = Object.assign(this.gridOptions, {
        frameworkComponents: {
          agDateInput: CustomDateFilter,
        },
      })
    }

    this.defaultColDef = Object.assign(this.defaultColDef, {
      menuTabs:
        this.headerPanel || !this.configurationPossibility
          ? ['generalMenuTab', 'filterMenuTab']
          : ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'],
      cellStyle(params) {
        if (typeof params.value === 'number') {
          return { 'text-align': 'right' }
        } else {
          return null
        }
      },
    })
  },
  methods: {
    afterSetDefaultFilters() {
      this.gridOptions.api.setFilterModel(this.customDefaultFilterModel)
    },
    uepGetMainMenuItems(params) {
      if (params) {
        params.defaultItems?.push({
          name: 'Reset Filters',
          action: () => {
            this.gridOptions.api.setFilterModel(null)
          },
        })
      }

      return params.defaultItems
    },
    toggleColumnSettingsMenu() {
      this.showColumnSettings = !this.showColumnSettings
    },
    exportExcel() {
      this.gridOptions.api.exportDataAsExcel()
    },
    uepGetContextMenuItems() {
      return ['copy', 'paste']
    },
  },
}
