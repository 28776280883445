//
//
//
//
//
//

import { mapState } from 'vuex'

export default {
  name: 'VHeader',
  data() {
    return {}
  },
  computed: {
    ...mapState('application/layout', ['drawerWidth']),
  },
}
