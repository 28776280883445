// NOTE: to override scss variables edit $grid-breakpoints variable
const breakpoints = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
}

export { breakpoints }
export default breakpoints
