export default {
  data() {
    return {
      window: {
        innerHeight: 0,
        innerWidth: 0,
      },
    }
  },
  mounted() {
    window.addEventListener('resize', this.updatePageDimension)
    window.addEventListener('orientationchange', this.updatePageDimension)
    this.updatePageDimension()
  },
  methods: {
    updatePageDimension() {
      if (window !== undefined) {
        this.window.innerHeight = window.innerHeight
        this.window.innerWidth = window.innerWidth
      }
    },
  },
}
