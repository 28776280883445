// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/icons/ui-icons/textarea-resizer.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "::-webkit-resizer{background:transparent url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");height:.875rem;width:.875rem}.v-textarea{transition:color .2s ease-in-out}@media(prefers-reduced-motion:reduce){.v-textarea{transition:none}}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
