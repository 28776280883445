export default function ({ store, route }) {
  const path = route.path.split('/')
  const module = path[1] || null
  const section = path[2] || null

  const res = {
    module,
    section,
    time: new Date().getTime(),
    url: route.path,
    target: Object.assign(route.query, route.params),
  }

  store.commit('base/pushTracking', res)
}
