//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'AppLogo',
  data() {
    return {
      envs: ['development', 'production'],
    }
  },
  computed: {
    env() {
      return __NUXT__.config.NODE_ENV
    },
  },
}
