
export default {
  name: 'VMenuItem',
  props: {
    color: {
      required: false,
      type: String,
      default: null, // todo hex colors
    },
    /**
     * Make link (tag a)
     */
    href: {
      required: false,
      type: [String, Object],
      default: null,
    },
    /**
     * Make nuxt-link
     */
    to: {
      required: false,
      type: [String, Object],
      default: null,
    },
  },
  computed: {
    classes() {
      const classes = {}

      const colorClass = this.getColorClass()
      if (colorClass !== null) {
        classes[colorClass] = true
      }

      return classes
    },
  },
  methods: {
    getColorClass() {
      const colors = [
        'primary',
        'secondary',
        'success',
        'info',
        'warning',
        'danger',
      ]

      if (!colors.includes(this.color)) {
        return null
      }

      return `v-menu-item--${this.color}`
    },
    onClick(event) {
      this.$emit('click', event)
    },
  },
  render(createElement) {
    let VMenuItemTag = 'div'
    if (this.to !== null) {
      VMenuItemTag = 'nuxt-link'
    }
    if (this.href !== null) {
      VMenuItemTag = 'a'
    }

    const props = {}
    const listeners = {}
    const attrs = {}

    if (this.to !== null) {
      props.to = this.to
    }
    if (this.to === null) {
      listeners.click = this.onClick
    }

    if (this.href !== null) {
      attrs.href = this.href
    }

    return createElement(
      VMenuItemTag,
      {
        class: {
          'v-menu-item': true,
          ...this.classes,
        },
        props: {
          ...props,
        },
        attrs,
        on: {
          ...listeners,
        },
      },
      [
        // prepend icon
        createElement(
          'div',
          {
            class: {
              'v-menu-item__prepend-icon': true,
              'v-menu-item__prepend-icon--empty':
                this.$slots.prependIcon === undefined ||
                this.$slots.prependIcon.length === 0,
            },
          },
          [this.$slots.prependIcon]
        ),

        // content
        createElement(
          'div',
          {
            class: {
              'v-menu-item__content': true,
            },
          },
          [this.$slots.default]
        ),
      ]
    )
  },
}
