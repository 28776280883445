export default () => ({
  orders: [],
  ordersMap: null,
  shipments: [],
  shipmentsMap: null,
  shippingStatuses: [],
  shippingStatusesMap: null,
  manufacturingStatuses: [],
  manufacturingStatusesMap: null,
  localShippingStatuses: [],
  localShippingStatusesMap: null,
  products: [],
  productsMap: null,
  withoutInboundProductParamsMap: null, // hack
  withInboundProductParamsMap: null, // hack
  persons: [],
  shipmentsHasWarehouse: null,
  items: [],
  tableView: localStorage.lastPOTrackerTableView || 'direct',
  itemsMap: null,
  countries: [],
  warehouses: [],
  warehousesStock: [],
  stockControl: [],
  modalViewPO: null,
  modalEditPO: null,
  modalViewShipment: null,
  modalViewLocalShipment: null,
  modalEditShipment: null,
  modalSplitShipment: null,
  modalCreateShipment: false,
  modalCreateLocalShipment: false,
  modalCreateShipmentSelectedItem: null,
  modalCogWindow: null,
  modalEnroutePage: null,
  modalReceiveWindow: null,
  modalReceiveWarehouseId: null,
  createShipmentData: null,
  editShipmentCallback: () => {},
  createShipmentCallback: () => {},
  recieveShipmentCallback: () => {},
  createOrderCallback: () => {},
  editOrderCallback: () => {},
  splitShipmentCallback: () => {},
  cogCallback: () => {},
  modalCreateShipmentOnSave: () => {},
  saveOrderRows: () => {},
  saveShipmentRows: () => {},
  reversePO: false,
  fetched: false,
})
