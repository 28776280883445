//
//
//
//
//
//
//
//
//
//

import Vue from 'vue'

export default Vue.extend({
  name: 'LinkRenderer',
  computed: {
    link() {
      return this.params.link(this.params)
    },
    title() {
      return this.params.title(this.params)
    },
    placeholder() {
      return this.params.placeholder
    },
  },
})
