import { defineNuxtPlugin, reactive } from '@nuxtjs/composition-api'
import { breakpoints } from '@/config/breakpoints'

export class Breakpoints {
  constructor(ssrMode = false) {
    this.ssrMode = ssrMode

    this.threholds = {}
    this.threholds.xs = breakpoints.xs
    this.threholds.sm = breakpoints.sm
    this.threholds.md = breakpoints.md
    this.threholds.lg = breakpoints.lg
    this.threholds.xl = breakpoints.xl

    this.xxs = true
    this.xs = true
    this.sm = false
    this.md = false
    this.lg = false
    this.xl = false

    this.xxsOnly = true
    this.xsOnly = true
    this.smOnly = false
    this.mdOnly = false
    this.lgOnly = false
    this.xlOnly = false
    this.xxlOnly = false
    this.xsAndUp = true
    this.smAndUp = false
    this.mdAndUp = false
    this.lgAndUp = false
    this.xlAndUp = false
    this.xsAndDown = true
    this.smAndDown = false
    this.mdAndDown = false
    this.lgAndDown = false
    this.xlAndDown = false

    // current breakpoint name
    this.name = 'xs'

    if (!this.ssrMode && window !== undefined) {
      window.addEventListener(
        'resize',
        () => {
          setTimeout(() => {
            this.update()
          }, 4)
        },
        { passive: true }
      )
      window.addEventListener(
        'orientationchange',
        () => {
          setTimeout(() => {
            this.update()
          }, 4)
        },
        { passive: true }
      )
    }

    this.update()
  }

  update() {
    // const height = window ? window.innerHeight || 0 : 0
    let width = 0
    if (!this.ssrMode) {
      width = window ? window.innerWidth || 0 : 0
    }

    this.xxs = width < this.threholds.xs
    this.xs = width >= this.threholds.xs
    this.sm = width >= this.threholds.sm
    this.md = width >= this.threholds.md
    this.lg = width >= this.threholds.lg
    this.xl = width >= this.threholds.xl

    this.xxsOnly = this.xxs && !this.xs
    this.xsOnly = this.xs && !this.sm
    this.smOnly = this.sm && !this.md
    this.mdOnly = this.md && !this.lg
    this.lgOnly = this.lg && !this.xl
    this.xlOnly = this.xl

    // this.xsAndUp = this.xs
    // this.smAndUp = this.sm && !this.xs
    // this.mdAndUp = this.md && !(this.xs || this.sm)
    // this.lgAndUp = this.lg && !(this.xs || this.sm || this.md)
    // this.xlAndUp = this.xl && !(this.xs || this.sm || this.md || this.lg)
    this.xsAndUp = this.xs
    this.smAndUp = this.sm
    this.mdAndUp = this.md
    this.lgAndUp = this.lg
    this.xlAndUp = this.xl

    this.xsAndDown = this.xs && !(this.sm || this.md || this.lg || this.xl)
    this.smAndDown = (this.xs || this.sm) && !(this.md || this.lg || this.xl)
    this.mdAndDown = (this.xs || this.sm || this.md) && !(this.lg || this.xl)
    this.lgAndDown = (this.xs || this.sm || this.md || this.lg) && !this.xl
    this.xlAndDown = this.xs || this.sm || this.md || this.lg || this.xl

    if (this.xxs) {
      this.name = 'xxs'
    }
    if (this.xs) {
      this.name = 'xs'
    }
    if (this.sm) {
      this.name = 'sm'
    }
    if (this.md) {
      this.name = 'md'
    }
    if (this.lg) {
      this.name = 'lg'
    }
    if (this.xl) {
      this.name = 'xl'
    }
  }
}

export default defineNuxtPlugin((_context, inject) => {
  const breakpoints = new Breakpoints(process.server)
  inject('breakpoints', reactive(breakpoints))
})
