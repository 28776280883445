export default {
  setEditImageModal(state, value) {
    state.editImageModal = Object.assign(state.editImageModal, value)
  },
  hideEditImageModal(state) {
    state.editImageModal = Object.assign(state.editImageModal, {
      visible: false,
      pos: null,
      zone: null,
      selectedImageId: null,
      setImage: () => {},
    })
  },
  setProductId(state, value) {
    state.productId = value
  },
  setSetId(state, value) {
    state.setId = value
  },
  setZones(state, value) {
    state.zones = value
  },
  setImage(state, params) {
    const pos = state.zones
      .find((zone) => zone.zone_id === params.zone_id)
      .positions.find((position) => position.pos_id === params.pos_id)

    pos.object_link = params.objectLink
    pos.object_id = params.objectId
    pos.thumbnail_link = params.thumbnailLink
    state.editImageModal.setImage(pos)
  },
}
