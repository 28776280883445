export const state = () => ({
  drawerState: localStorage.drawerState || 'expanded', // collapsed | expanded
  drawerWidth: 0,
  headerHeight: 0,
})

export const mutations = {
  toggleDrawerState(state, drawerState = null) {
    if (drawerState !== null) {
      if (typeof drawerState === 'boolean') {
        drawerState = drawerState === true ? 'expanded' : 'collapsed'
      }
      state.drawerState = drawerState
    } else {
      state.drawerState =
        state.drawerState === 'collapsed' ? 'expanded' : 'collapsed'
    }
    localStorage.drawerState = state.drawerState
  },
  setApplicationDrawerWidth(state, width) {
    state.drawerWidth = width
  },
  setApplicationHeaderHeight(state, height) {
    state.headerHeight = height
  },
}
