export default () => ({
  editImageModal: {
    visible: false,
    zone: null,
    pos: null,
    selectedImageId: null,
    setImage: () => {},
  },
  productId: null,
  setId: null,
})
