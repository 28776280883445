import { render, staticRenderFns } from "./VErrorsBag.vue?vue&type=template&id=bef7fdf8&"
import script from "./VErrorsBag.vue?vue&type=script&lang=ts&"
export * from "./VErrorsBag.vue?vue&type=script&lang=ts&"
import style0 from "./VErrorsBag.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {VAlert: require('/app/components/ui/v-alert/VAlert.vue').default})
