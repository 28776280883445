const isProduction =
  window.location.host === 'v2.amzmanager.net' ? '?adb=1' : ''

export const state = () => ({
  buildVersion: __NUXT__.config.BUILD_DATE || 'default',
  buildBranch: `${__NUXT__.config.GIT_BRANCH}`,
  buildEnv: `${__NUXT__.config.NODE_ENV}`,
  tracking: [],
  userinfo: null,
  countries: null,
})

export const mutations = {
  pushTracking(state, value) {
    state.tracking.push({
      ...value,
      authorization: window.localStorage.getItem('auth._token.local'),
    })
  },
  clearTracking(state) {
    state.tracking = []
  },
  setUserInfo(state, value) {
    state.userinfo = value
  },
  setCountries(state, value) {
    state.countries = value
  },
}

export const actions = {
  sendTracking({ commit, state }) {
    const tracking = state.tracking
    if (tracking.length) {
      commit('clearTracking')
      navigator.sendBeacon(
        `/api-v3/services/usertracker.php${isProduction}`,
        JSON.stringify(tracking)
      )
    }
  },
  runTracker({ dispatch }) {
    const localeStorageInterval = Number(
      window.localStorage.getItem('tracking_interval')
    )
    window.addEventListener('beforeunload', () => dispatch('sendTracking'))
    setInterval(() => dispatch('sendTracking'), localeStorageInterval || 60000)
  },
  setUserInfo({ commit }) {
    this.$axios.get('/api-v3/auth/userinfo.php').then((res) => {
      commit('setUserInfo', res.data)
    })
  },
  getCountries({ commit }) {
    this.$axios.get('/api-v3/api.php?action=countries').then((resolve) => {
      commit('setCountries', resolve.data)
    })
  },
}
