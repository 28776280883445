import VInput from '@/components/ui/v-input/VInput'
import Multiselect from 'vue-multiselect'
import VProductMultiselect from '@/components/common/v-product-multiselect/VProductMultiselect'
import VCountryMultiselect from '@/components/common/v-country-multiselect/VCountryMultiselect'
import VAlertTypesMultiselect from '@/components/common/v-alert-types-multiselect/VAlertTypesMultiselect'
import popovers from '@/config/popovers'

const datePickerPopoverConfig = {
  ...popovers,
}

/**
 * Generates form controls for filters
 */
export default {
  methods: {
    /**
     * Generate date picker form control
     * @param props
     * @param children
     * @return {VNode}
     */
    genDatePickerFilter({ name, props, children }) {
      return this.$createElement('client-only', [
        this.$createElement(
          'v-date-picker',
          {
            class: {
              'v-data-table-filter': true,
            },
            props: {
              value: this.filtersValue[name],
              mode: props.mode || 'single',
              popover: datePickerPopoverConfig,
            },
            on: {
              input: (value) => {
                const newVal = Object.assign({}, this.filtersValue)
                newVal[name] = value
                this.$emit('update:filtersValue', newVal)
              },
            },
            scopedSlots: {
              default: ({ inputClass, inputValue, inputEvents }) =>
                this.$createElement(
                  VInput,
                  {
                    props: {
                      inputClass,
                      value: inputValue,
                      hideDetails: true,
                      noLabel: true,
                      placeholder:
                        props.placeholder ||
                        (props.mode !== 'range' && props.mode !== 'multiple'
                          ? 'Select date'
                          : 'Select range of dates'),
                    },
                    on: {
                      ...inputEvents,
                    },
                  },
                  children
                ),
            },
          },
          children
        ),
      ])
    },

    /**
     * Generate multiselect form control
     * @param props
     * @param children
     * @param scopedSlots
     * @return {VNode}
     */
    genMultiselectFilter({ name, props, children, scopedSlots }) {
      return this.$createElement(
        Multiselect,
        {
          class: {
            'v-data-table-filter': true,
          },
          props: {
            ...props,
            value: this.filtersValue[name],
            options: props.options || [],
          },
          on: {
            input: (value) => {
              const newVal = Object.assign({}, this.filtersValue)
              newVal[name] = value
              this.$emit('update:filtersValue', newVal)
            },
          },
          scopedSlots,
        },
        children
      )
    },

    /**
     * Generate multiselect form control for products or country multiselect
     * @param componentConstructor
     * @param props
     * @param children
     * @param scopedSlots
     * @param icon
     * @return {VNode}
     */
    genCommonMultiselectFilter(
      componentConstructor,
      { name, props, children, scopedSlots, icon }
    ) {
      return this.$createElement(
        componentConstructor,
        {
          class: {
            'v-data-table-filter': true,
          },
          props: {
            ...props,
            value: this.filtersValue[name] || [], // it always requires defined value
            options: props.options || [],
            asyncSearch: props.asyncSearch || (() => []),
            icon,
          },
          on: {
            input: (value) => {
              const newVal = Object.assign({}, this.filtersValue)
              newVal[name] = value
              this.$emit('update:filtersValue', newVal)
            },
            'update:options': (options) => {
              props.options = options // RISKYm NEED TO TEST IT
            },
          },
          scopedSlots,
        },
        children
      )
    },

    /**
     * Generate product multiselect form control
     * @param props
     * @param children
     * @param scopedSlots
     * @param icon
     * @return {VNode}
     */
    genProductMultiselectFilter({ name, props, children, scopedSlots, icon }) {
      return this.genCommonMultiselectFilter(VProductMultiselect, {
        name,
        props,
        children,
        scopedSlots,
        icon,
      })
    },

    /**
     * Generate country multiselect form control
     * @param props
     * @param children
     * @param scopedSlots
     * @param icon
     * @return {VNode}
     */
    genCountryMultiselectFilter({ name, props, children, scopedSlots, icon }) {
      return this.genCommonMultiselectFilter(VCountryMultiselect, {
        name,
        props,
        children,
        scopedSlots,
        icon,
      })
    },

    /**
     * Generate country multiselect form control
     * @param props
     * @param children
     * @param scopedSlots
     * @param icon
     * @return {VNode}
     */
    genAlertTypesMultiselectFilter({
      name,
      props,
      children,
      scopedSlots,
      icon,
    }) {
      return this.genCommonMultiselectFilter(VAlertTypesMultiselect, {
        name,
        props,
        children,
        scopedSlots,
        icon,
      })
    },

    /**
     * Generate input form control
     * @param props
     * @param children
     * @return {VNode}
     */
    genInputFilter({ name, props, children }) {
      return this.$createElement(
        VInput,
        {
          class: {
            'v-data-table-filter': true,
          },
          props: {
            value: this.filtersValue[name],
            hideDetails: true,
            noLabel: true,
            placeholder: props.placeholder || 'Enter value',
          },
          on: {
            input: (value) => {
              this.filtersValue[name] = value
            },
          },
        },
        children
      )
    },
  },
}
