import { render, staticRenderFns } from "./centered.vue?vue&type=template&id=65a849f0&scoped=true&"
import script from "./centered.vue?vue&type=script&lang=js&"
export * from "./centered.vue?vue&type=script&lang=js&"
import style0 from "./centered.vue?vue&type=style&index=0&id=65a849f0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65a849f0",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Logo: require('/app/components/layouts/Logo.vue').default,VSpacer: require('/app/components/ui/v-spacer/VSpacer.vue').default,VHeader: require('/app/components/layouts/header/VHeader.vue').default,VMain: require('/app/components/layouts/main/VMain.vue').default,VApp: require('/app/components/layouts/app/VApp.vue').default})
