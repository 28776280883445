//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VInput from '@/components/ui/v-input/VInput'
import convertToCssUnits from '@/utils/convertToCssUnits'

export default {
  name: 'VTextarea',
  mixins: [VInput],
  model: {
    event: 'input',
    prop: 'value',
  },
  props: {
    rows: {
      required: false,
      type: [Number, String],
      default: null,
    },
    cols: {
      required: false,
      type: [Number, String],
      default: null,
    },
    maxHeight: {
      required: false,
      type: [Number, String],
      default: 'auto',
    },
    formStyle: {
      required: false,
      type: String,
      default: 'light',
    },
    hideDetails: {
      required: false,
      type: Boolean,
      default: false,
    },
    maxLength: {
      required: false,
      type: Number,
      default: 524288, // default by html5 standart
    },
  },
  data() {
    return {
      generatedId: 'v-textarea-' + Math.random().toString(36).substring(7),
    }
  },
  computed: {
    textareaStyle() {
      return {
        'max-height': convertToCssUnits(this.maxHeight),
      }
    },
    formControlClasses() {
      return {
        'v-input__form-control--dark': this.formStyle === 'dark',
        'v-input__form-control--middle': this.formStyle === 'middle',
        'v-input__form-control--white': this.formStyle === 'white',
      }
    },
  },
  methods: {
    onChange(event) {
      this.$emit('change', event.target.value, event)
    },
  },
}
