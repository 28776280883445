//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'
import Logo from '@/components/layouts/Logo'

export default {
  name: 'VDrawerLogo',
  components: { Logo },
  props: {
    height: {
      required: false,
      type: [String, Number],
      default: '60px',
    },
    heightMobile: {
      required: false,
      type: [String, Number],
      default: '56px',
    },
  },
  data() {
    return {}
  },
  computed: {
    ...mapState('application/layout', ['drawerState']),
  },
  methods: {
    toggleDrawerState() {
      this.$store.commit('application/layout/toggleDrawerState')
    },
  },
}
