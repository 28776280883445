import { render, staticRenderFns } from "./VCheckbox.vue?vue&type=template&id=014e02a8&scoped=true&"
import script from "./VCheckbox.vue?vue&type=script&lang=js&"
export * from "./VCheckbox.vue?vue&type=script&lang=js&"
import style0 from "./VCheckbox.vue?vue&type=style&index=0&id=014e02a8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "014e02a8",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {VIcon: require('/app/components/ui/v-icon/VIcon.vue').default})
