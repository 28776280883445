import { render, staticRenderFns } from "./VAlertTypesMultiselect.vue?vue&type=template&id=0bebfd58&"
import script from "./VAlertTypesMultiselect.vue?vue&type=script&lang=js&"
export * from "./VAlertTypesMultiselect.vue?vue&type=script&lang=js&"
import style0 from "./VAlertTypesMultiselect.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {VBtn: require('/app/components/ui/v-btn/VBtn.vue').default,VIcon: require('/app/components/ui/v-icon/VIcon.vue').default,VCheckbox: require('/app/components/ui/v-checkbox/VCheckbox.vue').default})
