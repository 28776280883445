import Vue from 'vue'
import { wrapFunctional } from './index'

const components = {
  AgGridVue: () => import('../../components/ag-grid/AgGridVue.vue' /* webpackChunkName: "components/ag-grid-vue" */).then(c => wrapFunctional(c.default || c)),
  ColumnSettingsMenu: () => import('../../components/ag-grid/ColumnSettingsMenu.vue' /* webpackChunkName: "components/column-settings-menu" */).then(c => wrapFunctional(c.default || c)),
  CustomDateFilter: () => import('../../components/ag-grid/CustomDateFilter.vue' /* webpackChunkName: "components/custom-date-filter" */).then(c => wrapFunctional(c.default || c)),
  AmazonLinkField: () => import('../../components/fields/AmazonLinkField.vue' /* webpackChunkName: "components/amazon-link-field" */).then(c => wrapFunctional(c.default || c)),
  AsinField: () => import('../../components/fields/AsinField.vue' /* webpackChunkName: "components/asin-field" */).then(c => wrapFunctional(c.default || c)),
  BaseField: () => import('../../components/fields/BaseField.vue' /* webpackChunkName: "components/base-field" */).then(c => wrapFunctional(c.default || c)),
  CrtField: () => import('../../components/fields/CrtField.vue' /* webpackChunkName: "components/crt-field" */).then(c => wrapFunctional(c.default || c)),
  ParentAsinField: () => import('../../components/fields/ParentAsinField.vue' /* webpackChunkName: "components/parent-asin-field" */).then(c => wrapFunctional(c.default || c)),
  ParentField: () => import('../../components/fields/ParentField.vue' /* webpackChunkName: "components/parent-field" */).then(c => wrapFunctional(c.default || c)),
  ProductField: () => import('../../components/fields/ProductField.vue' /* webpackChunkName: "components/product-field" */).then(c => wrapFunctional(c.default || c)),
  RatingField: () => import('../../components/fields/RatingField.vue' /* webpackChunkName: "components/rating-field" */).then(c => wrapFunctional(c.default || c)),
  Logo: () => import('../../components/layouts/Logo.vue' /* webpackChunkName: "components/logo" */).then(c => wrapFunctional(c.default || c)),
  VueTippy: () => import('../../components/vue-tippy/VueTippy.vue' /* webpackChunkName: "components/vue-tippy" */).then(c => wrapFunctional(c.default || c)),
  AsinCellRenderer: () => import('../../components/ag-grid/CellRenderers/AsinCellRenderer.vue' /* webpackChunkName: "components/asin-cell-renderer" */).then(c => wrapFunctional(c.default || c)),
  LinkCellRenderer: () => import('../../components/ag-grid/CellRenderers/LinkCellRenderer.vue' /* webpackChunkName: "components/link-cell-renderer" */).then(c => wrapFunctional(c.default || c)),
  TooltipCellRenderer: () => import('../../components/ag-grid/CellRenderers/TooltipCellRenderer.vue' /* webpackChunkName: "components/tooltip-cell-renderer" */).then(c => wrapFunctional(c.default || c)),
  VAlertTypesMultiselect: () => import('../../components/common/v-alert-types-multiselect/VAlertTypesMultiselect.vue' /* webpackChunkName: "components/v-alert-types-multiselect" */).then(c => wrapFunctional(c.default || c)),
  VCountryMultiselect: () => import('../../components/common/v-country-multiselect/VCountryMultiselect.vue' /* webpackChunkName: "components/v-country-multiselect" */).then(c => wrapFunctional(c.default || c)),
  VCountrySelect: () => import('../../components/common/v-country-select/VCountrySelect.vue' /* webpackChunkName: "components/v-country-select" */).then(c => wrapFunctional(c.default || c)),
  VErrorsBag: () => import('../../components/common/v-errors-bag/VErrorsBag.vue' /* webpackChunkName: "components/v-errors-bag" */).then(c => wrapFunctional(c.default || c)),
  VProductMultiselect: () => import('../../components/common/v-product-multiselect/VProductMultiselect.vue' /* webpackChunkName: "components/v-product-multiselect" */).then(c => wrapFunctional(c.default || c)),
  VProductSelect: () => import('../../components/common/v-product-select/VProductSelect.vue' /* webpackChunkName: "components/v-product-select" */).then(c => wrapFunctional(c.default || c)),
  VApp: () => import('../../components/layouts/app/VApp.vue' /* webpackChunkName: "components/v-app" */).then(c => wrapFunctional(c.default || c)),
  VDrawer: () => import('../../components/layouts/drawer/VDrawer.vue' /* webpackChunkName: "components/v-drawer" */).then(c => wrapFunctional(c.default || c)),
  VDrawerLogo: () => import('../../components/layouts/drawer/VDrawerLogo.vue' /* webpackChunkName: "components/v-drawer-logo" */).then(c => wrapFunctional(c.default || c)),
  VDrawerMenu: () => import('../../components/layouts/drawer/VDrawerMenu.vue' /* webpackChunkName: "components/v-drawer-menu" */).then(c => wrapFunctional(c.default || c)),
  VHeader: () => import('../../components/layouts/header/VHeader.vue' /* webpackChunkName: "components/v-header" */).then(c => wrapFunctional(c.default || c)),
  VMain: () => import('../../components/layouts/main/VMain.vue' /* webpackChunkName: "components/v-main" */).then(c => wrapFunctional(c.default || c)),
  VPageTitle: () => import('../../components/layouts/v-page-title/VPageTitle.vue' /* webpackChunkName: "components/v-page-title" */).then(c => wrapFunctional(c.default || c)),
  JsonView: () => import('../../components/ui/json-view/JsonView.vue' /* webpackChunkName: "components/json-view" */).then(c => wrapFunctional(c.default || c)),
  DefaultPreloader: () => import('../../components/ui/loaders/DefaultPreloader.js' /* webpackChunkName: "components/default-preloader" */).then(c => wrapFunctional(c.default || c)),
  VOverlayPreloader: () => import('../../components/ui/loaders/VOverlayPreloader.vue' /* webpackChunkName: "components/v-overlay-preloader" */).then(c => wrapFunctional(c.default || c)),
  RightPanel: () => import('../../components/ui/right-panel/RightPanel.vue' /* webpackChunkName: "components/right-panel" */).then(c => wrapFunctional(c.default || c)),
  StatusLabel: () => import('../../components/ui/status-label/status-label.vue' /* webpackChunkName: "components/status-label" */).then(c => wrapFunctional(c.default || c)),
  VActionsGroup: () => import('../../components/ui/v-actions-group/VActionsGroup.vue' /* webpackChunkName: "components/v-actions-group" */).then(c => wrapFunctional(c.default || c)),
  VAlert: () => import('../../components/ui/v-alert/VAlert.vue' /* webpackChunkName: "components/v-alert" */).then(c => wrapFunctional(c.default || c)),
  VBadge: () => import('../../components/ui/v-badge/VBadge.vue' /* webpackChunkName: "components/v-badge" */).then(c => wrapFunctional(c.default || c)),
  VBtn: () => import('../../components/ui/v-btn/VBtn.vue' /* webpackChunkName: "components/v-btn" */).then(c => wrapFunctional(c.default || c)),
  VCard: () => import('../../components/ui/v-card/VCard.vue' /* webpackChunkName: "components/v-card" */).then(c => wrapFunctional(c.default || c)),
  VCardContent: () => import('../../components/ui/v-card/VCardContent.vue' /* webpackChunkName: "components/v-card-content" */).then(c => wrapFunctional(c.default || c)),
  VCardFooter: () => import('../../components/ui/v-card/VCardFooter.vue' /* webpackChunkName: "components/v-card-footer" */).then(c => wrapFunctional(c.default || c)),
  VCardHeader: () => import('../../components/ui/v-card/VCardHeader.vue' /* webpackChunkName: "components/v-card-header" */).then(c => wrapFunctional(c.default || c)),
  VCheckbox: () => import('../../components/ui/v-checkbox/VCheckbox.vue' /* webpackChunkName: "components/v-checkbox" */).then(c => wrapFunctional(c.default || c)),
  VCollapse: () => import('../../components/ui/v-collapse/VCollapse.vue' /* webpackChunkName: "components/v-collapse" */).then(c => wrapFunctional(c.default || c)),
  VCopiedValue: () => import('../../components/ui/v-copied-value/VCopiedValue.vue' /* webpackChunkName: "components/v-copied-value" */).then(c => wrapFunctional(c.default || c)),
  VDateInput: () => import('../../components/ui/v-date-input/VDateInput.vue' /* webpackChunkName: "components/v-date-input" */).then(c => wrapFunctional(c.default || c)),
  VDetail: () => import('../../components/ui/v-details-popover/VDetail.vue' /* webpackChunkName: "components/v-detail" */).then(c => wrapFunctional(c.default || c)),
  VDetailsPopover: () => import('../../components/ui/v-details-popover/VDetailsPopover.vue' /* webpackChunkName: "components/v-details-popover" */).then(c => wrapFunctional(c.default || c)),
  VFileInput: () => import('../../components/ui/v-file-input/VFileInput.vue' /* webpackChunkName: "components/v-file-input" */).then(c => wrapFunctional(c.default || c)),
  VFormGroup: () => import('../../components/ui/v-form-group/VFormGroup.vue' /* webpackChunkName: "components/v-form-group" */).then(c => wrapFunctional(c.default || c)),
  VIcon: () => import('../../components/ui/v-icon/VIcon.vue' /* webpackChunkName: "components/v-icon" */).then(c => wrapFunctional(c.default || c)),
  VInput: () => import('../../components/ui/v-input/VInput.vue' /* webpackChunkName: "components/v-input" */).then(c => wrapFunctional(c.default || c)),
  VMenu: () => import('../../components/ui/v-menu/VMenu.vue' /* webpackChunkName: "components/v-menu" */).then(c => wrapFunctional(c.default || c)),
  VMenuItem: () => import('../../components/ui/v-menu/VMenuItem.vue' /* webpackChunkName: "components/v-menu-item" */).then(c => wrapFunctional(c.default || c)),
  VModal: () => import('../../components/ui/v-modal/VModal.vue' /* webpackChunkName: "components/v-modal" */).then(c => wrapFunctional(c.default || c)),
  VPopoverCloseBtn: () => import('../../components/ui/v-popover-close-btn/VPopoverCloseBtn.vue' /* webpackChunkName: "components/v-popover-close-btn" */).then(c => wrapFunctional(c.default || c)),
  VPopup: () => import('../../components/ui/v-popup/VPopup.vue' /* webpackChunkName: "components/v-popup" */).then(c => wrapFunctional(c.default || c)),
  VRadio: () => import('../../components/ui/v-radio/VRadio.vue' /* webpackChunkName: "components/v-radio" */).then(c => wrapFunctional(c.default || c)),
  VRating: () => import('../../components/ui/v-rating/VRating.vue' /* webpackChunkName: "components/v-rating" */).then(c => wrapFunctional(c.default || c)),
  VSelect: () => import('../../components/ui/v-select/VSelect.vue' /* webpackChunkName: "components/v-select" */).then(c => wrapFunctional(c.default || c)),
  VSpacer: () => import('../../components/ui/v-spacer/VSpacer.vue' /* webpackChunkName: "components/v-spacer" */).then(c => wrapFunctional(c.default || c)),
  VStarRating: () => import('../../components/ui/v-star-rating/VStarRating.vue' /* webpackChunkName: "components/v-star-rating" */).then(c => wrapFunctional(c.default || c)),
  VStepInput: () => import('../../components/ui/v-step-input/VStepInput.vue' /* webpackChunkName: "components/v-step-input" */).then(c => wrapFunctional(c.default || c)),
  VSwitch: () => import('../../components/ui/v-switch/VSwitch.vue' /* webpackChunkName: "components/v-switch" */).then(c => wrapFunctional(c.default || c)),
  VTab: () => import('../../components/ui/v-tabs/VTab.vue' /* webpackChunkName: "components/v-tab" */).then(c => wrapFunctional(c.default || c)),
  VTabItem: () => import('../../components/ui/v-tabs/VTabItem.vue' /* webpackChunkName: "components/v-tab-item" */).then(c => wrapFunctional(c.default || c)),
  VTabs: () => import('../../components/ui/v-tabs/VTabs.vue' /* webpackChunkName: "components/v-tabs" */).then(c => wrapFunctional(c.default || c)),
  VTextarea: () => import('../../components/ui/v-textarea/VTextarea.vue' /* webpackChunkName: "components/v-textarea" */).then(c => wrapFunctional(c.default || c)),
  VToast: () => import('../../components/ui/v-toast/VToast.vue' /* webpackChunkName: "components/v-toast" */).then(c => wrapFunctional(c.default || c)),
  VWidget: () => import('../../components/ui/v-widget/VWidget.vue' /* webpackChunkName: "components/v-widget" */).then(c => wrapFunctional(c.default || c)),
  VWidgetColumn: () => import('../../components/ui/v-widget/VWidgetColumn.ts' /* webpackChunkName: "components/v-widget-column" */).then(c => wrapFunctional(c.default || c)),
  VWidgetsContainer: () => import('../../components/ui/v-widget/VWidgetsContainer.vue' /* webpackChunkName: "components/v-widgets-container" */).then(c => wrapFunctional(c.default || c)),
  VWorklog: () => import('../../components/ui/v-worklog/VWorklog.vue' /* webpackChunkName: "components/v-worklog" */).then(c => wrapFunctional(c.default || c)),
  AmazonTheme: () => import('../../components/ui/charts/themes/amazonTheme.js' /* webpackChunkName: "components/amazon-theme" */).then(c => wrapFunctional(c.default || c)),
  VLineChart: () => import('../../components/ui/charts/v-line-chart/VLineChart.vue' /* webpackChunkName: "components/v-line-chart" */).then(c => wrapFunctional(c.default || c)),
  FilterableTable: () => import('../../components/ui/tables/mixins/filterableTable.js' /* webpackChunkName: "components/filterable-table" */).then(c => wrapFunctional(c.default || c)),
  VDataIterator: () => import('../../components/ui/tables/v-data-iterator/VDataIterator.vue' /* webpackChunkName: "components/v-data-iterator" */).then(c => wrapFunctional(c.default || c)),
  VDataTable: () => import('../../components/ui/tables/v-data-table/VDataTable.vue' /* webpackChunkName: "components/v-data-table" */).then(c => wrapFunctional(c.default || c)),
  VDataTableConfigModal: () => import('../../components/ui/tables/v-data-table/VDataTableConfigModal.vue' /* webpackChunkName: "components/v-data-table-config-modal" */).then(c => wrapFunctional(c.default || c)),
  VDataTableFilters: () => import('../../components/ui/tables/v-data-table/VDataTableFilters.vue' /* webpackChunkName: "components/v-data-table-filters" */).then(c => wrapFunctional(c.default || c)),
  VPagination: () => import('../../components/ui/tables/v-data-table/VPagination.vue' /* webpackChunkName: "components/v-pagination" */).then(c => wrapFunctional(c.default || c)),
  VSimpleTable: () => import('../../components/ui/tables/v-simple-table/VSimpleTable.vue' /* webpackChunkName: "components/v-simple-table" */).then(c => wrapFunctional(c.default || c))
}

for (const name in components) {
  Vue.component(name, components[name])
  Vue.component('Lazy' + name, components[name])
}
