// js equivalent of styles/theme/_theme-colors.scss
// NOTE: to override scss variables edit file above

export const themeColors = {
  primary: '#3670ff',
  secondary: '#7f839c',
  success: '#21c44c',
  info: '#17a2b8',
  warning: '#f8b833',
  danger: '#f81135',
  light: '#f8f9fa',
  dark: '#252733',
  bodyBg: '#f0f1f5',
  bodyColor: '#252733',
}

/**
 * Sequence of widget cards colors in Data Table component
 * @type {string[]}
 */
export const widgetsColorSequence = ['#2059E5', '#1848BD', '#687AA2', '#435784']

export default themeColors
