//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VIcon from '~/components/ui/v-icon/VIcon'

export default {
  name: 'VCheckbox',
  components: {
    VIcon,
  },
  model: {
    event: 'change',
    prop: 'inputValue',
  },
  props: {
    inline: {
      required: false,
      type: Boolean,
      default: false,
    },
    groupCheckbox: {
      required: false,
      type: Boolean,
      default: false,
    },
    // can be array for multiple checkboxes or boolean variable. Uses in v-model
    inputValue: {
      required: true,
      type: [Array, Boolean, Number, String], // string type -> 'some' - for group selects (this.groupCheckbox === true)
    },
    // value of checkbox for multiple checks. Optional prop.
    value: {
      required: false,
      type: [String, Boolean, Number, Object],
      default: null,
    },
    multiple: {
      required: false,
      type: Boolean,
      default: false,
    },
    circle: {
      required: false,
      type: Boolean,
      default: false,
    },
    disabled: {
      required: false,
      type: Boolean,
      default: false,
    },
    formStyle: {
      required: false,
      type: String,
      default: 'light',
    },
  },
  computed: {
    isMultiple() {
      if (this.multiple) {
        return true
      }
      return Array.isArray(this.inputValue)
    },
    isActive() {
      if (this.isMultiple) {
        return this.inputValue.includes(this.value)
      }
      return this.inputValue === true || this.inputValue === 1
    },
    isGroupSome() {
      if (this.isMultiple) {
        return false
      }
      if (this.groupCheckbox) {
        return this.inputValue === 'some'
      }
      return false
    },
    selectionClasses() {
      return {
        'v-checkbox__selection--active': this.isActive || this.isGroupSome,
        'v-checkbox__selection--circle': this.circle,
        'v-checkbox__selection--dark': this.formStyle === 'dark',
        'v-checkbox__selection--middle': this.formStyle === 'middle',
        'v-checkbox__selection--white': this.formStyle === 'white',
      }
    },
    checkboxClasses() {
      return {
        'v-checkbox--inline': this.inline,
        'v-checkbox--disabled': this.disabled,
      }
    },
  },
  created() {
    if (this.isMultiple && !this.value) {
      // eslint-disable-next-line no-console
      console.error(
        '[VCheckbox] Value must be not empty when checkbox is multiple'
      )
    }
  },
  methods: {
    getUpdatedMultipleValue() {
      if (this.inputValue.includes(this.value)) {
        return this.inputValue.filter((value) => {
          return value !== this.value
        })
      } else {
        return [...this.inputValue, this.value]
      }
    },
    onChange(event) {
      event.preventDefault()
      if (!this.disabled) {
        if (!this.isMultiple) {
          // return boolean if value was boolean and number (bit) of was number
          const newValue =
            typeof this.inputValue === 'number' && !isNaN(this.inputValue)
              ? +!this.inputValue
              : !this.inputValue
          this.$emit('change', newValue)
        } else {
          this.$emit('change', this.getUpdatedMultipleValue())
        }
        this.$refs.input.checked = this.isActive // fixes dom-element checked prop's value
      }
    },
  },
}
