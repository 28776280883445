
export default {
  name: 'VBtn',
  props: {
    /**
     * Color for button.
     * Can be theme color (recommended) or hex/rgb/rgba and any other css color
     */
    color: {
      required: false,
      type: String,
      default: null,
    },

    /**
     * Color for button on hover.
     * Can be theme color (recommended) or hex/rgb/rgba and any other css color
     */
    hoverColor: {
      required: false,
      type: String,
      default: null,
    },
    /**
     * `to` param for nuxt-link
     * If not set - render button (or link), else - render nuxt-link
     */
    to: {
      required: false,
      type: [String, Object],
      default: null,
    },
    /**
     * Make link with buttons style
     */
    href: {
      required: false,
      type: [String, Object],
      default: null,
    },
    defaultCursor: {
      required: false,
      type: Boolean,
      default: false,
    },
    disabled: {
      required: false,
      type: Boolean,
      default: false,
    },

    // Button style types
    icon: {
      required: false,
      type: Boolean,
      default: false,
    },
    iconContent: {
      required: false,
      type: Boolean,
      default: false,
    },
    iconCircle: {
      required: false,
      type: Boolean,
      default: false,
    },
    outline: {
      required: false,
      type: Boolean,
      default: false,
    },
    text: {
      required: false,
      type: Boolean,
      default: false,
    },
    block: {
      required: false,
      type: Boolean,
      default: false,
    },
    blockOnMobile: {
      required: false,
      type: Boolean,
      default: false,
    },
    gradient: {
      required: false,
      type: Boolean,
      default: false,
    },
    linkBtn: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {}
  },
  computed: {
    classes() {
      const classes = {
        'v-btn--icon': this.icon,
        'v-btn--icon-content': this.iconContent,
        'v-btn--icon-circle': this.iconCircle,
        'v-btn--outline': this.outline,
        'v-btn--text': this.text,
        'v-btn--block': this.block,
        'v-btn--block-mobile': this.blockOnMobile,
        'v-btn--gradient': this.gradient,
        'v-btn--link': this.linkBtn,
        'v-btn--disabled': this.disabled,
        'v-btn--default-cursor': this.defaultCursor,
      }

      const colorClass = this.getColorClass()
      const hoverColorClass = this.getColorClass(true)
      if (colorClass !== null) {
        classes[colorClass] = true
      }
      if (hoverColorClass !== null) {
        classes[hoverColorClass] = true
      }

      return classes
    },
    style() {
      const style = {}
      const colorClass = this.getColorClass()
      if (colorClass === null && this.color) {
        style.background = this.color
        style.borderColor = this.color
      }
      return style
    },
  },
  mounted() {
    this.initCssHoverColor()
  },
  methods: {
    getColorClass(hover = false) {
      const color = hover ? this.hoverColor : this.color

      const colors = [
        'primary',
        'secondary',
        'success',
        'info',
        'warning',
        'danger',
        'light',
        'dark',
        'btn-gray-shade',
        'btn-gray-content',
      ]

      if (!colors.includes(color)) {
        return null
      }

      if (hover === true) {
        return `v-btn--hover-${color}`
      }

      return `v-btn--${color}`
    },

    initCssHoverColor() {
      /** @type {Element} */
      const VBtnElement = this.$refs.VBtnNode.$el || this.$refs.VBtnNode
      const hoverColorClass = this.getColorClass(true)

      // more complex solution
      if (hoverColorClass === null && this.hoverColor) {
        // change color on mouseover
        VBtnElement.addEventListener('mouseover', () => {
          VBtnElement.style.background = this.hoverColor
        })

        // reset color on mouseout
        let onmouseoutCallback = () => {
          VBtnElement.style.background = undefined
        }
        if (this.getColorClass() === null && this.color) {
          onmouseoutCallback = () => {
            VBtnElement.style.background = this.color
          }
        }
        VBtnElement.addEventListener('mouseout', onmouseoutCallback)
      }
    },

    onClick(event) {
      this.$emit('click', event)
    },
  },
  render(createElement) {
    let VBtnTag = 'button'
    if (this.to !== null) {
      VBtnTag = 'nuxt-link'
    }
    if (this.href !== null) {
      VBtnTag = 'a'
    }

    const props = {}
    const listeners = {}
    const attrs = {
      disabled: this.disabled,
    }

    if (this.to !== null) {
      props.to = this.to
    }

    if (this.to === null) {
      listeners.click = this.onClick
    }

    if (this.href !== null) {
      attrs.href = this.href
    }

    return createElement(
      VBtnTag,
      {
        class: {
          'v-btn': true,
          ...this.classes,
        },
        style: this.style,
        props,
        attrs,
        on: listeners,
        ref: 'VBtnNode',
        refInFor: false,
      },
      [
        createElement(
          'span',
          {
            class: {
              'd-flex': true,
              'align-items-center': true,
              'justify-content-center': true,
            },
          },
          [
            // prepend content
            this.$slots.prepend
              ? createElement(
                  'span',
                  {
                    class: {
                      'v-btn__prepend-content': true,
                    },
                  },
                  this.$slots.prepend
                )
              : null,

            // content
            this.$slots.default,

            // append content
            this.$slots.append
              ? createElement(
                  'span',
                  {
                    class: {
                      'v-btn__append-content': true,
                    },
                  },
                  this.$slots.append
                )
              : null,
          ]
        ),
      ]
    )
  },
}
