
import filterableTable from '@/components/ui/tables/mixins/filterableTable'
import ssrBootableMixin from '@/mixins/ssrBootableMixin'
import DataTableFilterTypesMixin from '@/components/ui/tables/mixins/DataTableFilterTypesMixin'
import isMobileMixin from '~/mixins/isMobileMixin'
import VBtn from '~/components/ui/v-btn/VBtn'
import VIcon from '~/components/ui/v-icon/VIcon'
import VCollapse from '~/components/ui/v-collapse/VCollapse'

export default {
  name: 'VDataTableFilters',
  mixins: [
    filterableTable,
    isMobileMixin,
    ssrBootableMixin,
    DataTableFilterTypesMixin,
  ],
  props: {
    filtersValue: {
      required: false,
      type: Object,
      default() {
        return {}
      },
    },
    loading: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      filterCollapseOpen: false,
    }
  },
  methods: {
    toggleFilterCollapse(value = null) {
      this.filterCollapseOpen =
        value === null ? !this.filterCollapseOpen : value
    },
    onFilterButtonClick() {
      this.emitFilterDataEvent()
    },
    emitFilterDataEvent() {
      this.$emit('filter-data', this.filtersValue)
    },

    /// render methods

    /**
     * Generate row with button that toggle visibility of filters
     * @return VNode
     */
    genMobileTogglerButton() {
      return this.$createElement(
        VBtn,
        {
          props: {
            block: true,
            outline: true,
            color: 'primary',
          },
          on: {
            click: () => {
              this.toggleFilterCollapse()
            },
          },
        },
        [this.$createElement(VIcon, { slot: 'prepend' }, 'filter'), 'Filter']
      )
    },

    /**
     * Generate row with button that toggle visibility of filters
     * @return VNode
     */
    genMobileTogglerRow() {
      const children = []
      if (this.$scopedSlots.togglerRow) {
        children.push(
          this.$scopedSlots.togglerRow({
            toggleFilterCollapse: this.toggleFilterCollapse,
          })
        )
      } else {
        children.push(
          this.$createElement(
            'div',
            {
              class: {
                row: true,
              },
            },
            [
              this.$createElement(
                'div',
                {
                  class: {
                    'col-12': true,
                  },
                },
                [this.genMobileTogglerButton()]
              ),
            ]
          )
        )
      }
      return this.$createElement(
        'div',
        {
          class: {
            'col-12': true,
            'd-md-none': true,
            'mb-3': true,
          },
        },
        children
      )
    },

    /**
     * Generate column with filter
     * @return VNode
     */
    genFilter(filter) {
      const { cols, type, name, props = {}, icon = null } = filter

      if (this.$scopedSlots['filter.' + name]) {
        return this.$scopedSlots['filter.' + name]({
          cols,
          type,
          name,
          props,
          icon,
          emitFilterDataEvent: this.emitFilterDataEvent,
        })
      }

      let formControl = null
      const children = [] // prepend icon and other
      const scopedSlots = {} // prepend icon and other for multiselect and components with complex rendering

      switch (type) {
        case 'date-picker':
          if (icon !== null) {
            children.push(
              this.$createElement(
                VIcon,
                {
                  props: {
                    color: 'primary',
                  },
                  slot: 'prepend',
                },
                icon
              )
            )
          }

          // fixme ssr or leave as is - now will work only on client side
          formControl = this.genDatePickerFilter({ name, props, children })
          break
        case 'multiselect':
          if (icon !== null) {
            scopedSlots.singleLabel = ({ option }) => {
              let label =
                typeof option !== 'object'
                  ? option
                  : props.label
                  ? option[props.label]
                  : option.label
              label = label || props.placeholder || 'Select option'
              return [
                this.$createElement(
                  VIcon,
                  { props: { color: 'primary' } },
                  icon
                ),
                label,
              ]
            }
            scopedSlots.placeholder = ({ option }) => {
              let label =
                typeof option !== 'object'
                  ? option
                  : props.label
                  ? option[props.label]
                  : option.label
              label = label || props.placeholder || 'Select option'
              return [
                this.$createElement(
                  VIcon,
                  { props: { color: 'primary' } },
                  icon
                ),
                label,
              ]
            }
          }

          formControl = this.genMultiselectFilter({
            name,
            props,
            children,
            scopedSlots,
          })
          break
        case 'product-multiselect': // TODO unite with 'country-multiselect' and 'alert-types-multiselect'
          formControl = this.genProductMultiselectFilter({
            name,
            props,
            children,
            scopedSlots,
            icon,
          })
          break
        case 'country-multiselect': // TODO unite with 'product-multiselect' and 'alert-types-multiselect'
          formControl = this.genCountryMultiselectFilter({
            name,
            props,
            children,
            scopedSlots,
            icon,
          })
          break
        case 'alert-types-multiselect': // TODO unite with 'country-multiselect' and 'product-multiselect'
          formControl = this.genAlertTypesMultiselectFilter({
            name,
            props,
            children,
            scopedSlots,
            icon,
          })
          break
        case 'input':
        default:
          if (icon !== null) {
            children.push(
              this.$createElement(
                VIcon,
                {
                  props: {
                    color: 'primary',
                  },
                  slot: 'prepend',
                },
                icon
              )
            )
          }

          formControl = this.genInputFilter({ name, props, children })
      }

      // create col with filter form control
      return this.$createElement(
        'div',
        {
          class: {
            'v-data-table__filter-column': true,

            // fixed width of every column
            'col-12': true,
            [`col-md-${cols}`]:
              !this.filtersColumnFlexFill && !this.equalFiltersColumnWidth,

            // flex-fill'ed columns
            'col-sm-auto':
              this.filtersColumnFlexFill && !this.equalFiltersColumnWidth,
            'flex-sm-fill':
              this.filtersColumnFlexFill && !this.equalFiltersColumnWidth,

            // equal width of all columns
            'col-sm': this.equalFiltersColumnWidth,
          },
        },
        [formControl]
      )
    },

    /**
     * Generate row with filters
     * @return Array<VNode>
     */
    genFilters() {
      const filterNodes = []
      for (let i = 0; i < this.filters.length; i++) {
        filterNodes.push(this.genFilter(this.filters[i]))
      }
      return filterNodes
    },

    /**
     * Generate button for applying filters (and reload data)
     * @return VNode
     */
    genFilterButton() {
      if (this.$scopedSlots.filterButton) {
        return this.$scopedSlots.filterButton({
          onFilterButtonClick: this.onFilterButtonClick,
          loading: this.loading,
        })
      }

      /** @type {VNodeData} */
      const data = {
        class: {
          'v-data-table-filters__filter-button': true,
        },
        props: {
          disabled: this.loading,
          color: 'primary',
          block: true,
        },
        on: {
          click: () => {
            this.onFilterButtonClick()
          },
        },
      }

      return this.$createElement(VBtn, data, 'Filter')
    },

    /**
     * Generate column with filter button
     * Use it inside column
     * @return VNode
     */
    genFilterButtonColumn() {
      if (this.$scopedSlots.filterButtonColumn) {
        return this.$scopedSlots.filterButtonColumn({
          onFilterButtonClick: this.onFilterButtonClick,
          loading: this.loading,
        })
      }

      return this.$createElement(
        'div',
        {
          class: {
            'col-12': true,
            [`col-md-${this.filterButtonColumns}`]: true, // autosize on desktop
          },
        },
        [this.genFilterButton()]
      )
    },

    /**
     * Generate row with filters columns and filter button column.
     * Collapses on mobile screens.
     * Use it inside column
     * @return VNode
     */
    genFiltersRow() {
      const children = [this.genFilters(), this.genFilterButtonColumn()]
      const childrenRow = this.$createElement(
        'div',
        {
          class: {
            row: true,
          },
        },
        children
      )

      // Will be shown on Desktop, on mobile while its state is open
      // and with SSR (but in this case it will be hidden on mobile)
      return this.$createElement(
        VCollapse,
        {
          props: {
            value: !this.isMobile || this.filterCollapseOpen || !this.isBooted,
          },
          class: {
            'col-12': true,
            'd-none': !this.isBooted,
            'd-md-block': !this.isBooted,
          },
        },
        [childrenRow]
      )
    },
  },
  render(h) {
    return h(
      'div',
      {
        class: {
          'v-data-table-filters-wrap': true,
          row: true,
        },
      },
      [this.genMobileTogglerRow(), this.genFiltersRow()]
    )
  },
}
