// Popper.js config for v-tooltip and v-calendar
const popovers = {
  modifiers: {
    flip: {
      enabled: false,
    },
  },
}

export { popovers }
export default popovers
