
import { Portal } from 'portal-vue'
import PageDimensionMixin from '../../../mixins/PageDimensionMixin'
import VCard from '../v-card/VCard'
import VCardContent from '../v-card/VCardContent'
import VCardHeader from '../v-card/VCardHeader'
import VCardFooter from '../v-card/VCardFooter'
import VIcon from '~/components/ui/v-icon/VIcon'

export default {
  name: 'VModal',
  mixins: [PageDimensionMixin],
  model: {
    prop: 'value',
    event: 'toggle',
  },
  props: {
    width: {
      required: false,
      type: [Number, String],
      default: 'auto', // 350
    },
    centered: {
      required: false,
      type: Boolean,
      default: false,
    },
    // style for simple modal without header and footer with centered content
    dialog: {
      required: false,
      type: Boolean,
      default: false,
    },
    value: {
      required: true,
      type: Boolean,
    },
    scrollableBody: {
      required: false,
      type: Boolean,
      default: false,
    },
    bodyStyle: {
      required: false,
      type: String,
      default: 'light',
    },
  },
  data() {
    return {
      animating: false,
      mousePressed: false,
    }
  },
  computed: {
    modalWidth() {
      const overflowMaxWidth = this.window.innerWidth - 32 // 32px = 2rem = 1rem left + 1rem right paddings
      if (!isNaN(this.width)) {
        const width = Math.min(+this.width, overflowMaxWidth)
        return `${width}px`
      } else {
        if (this.width.includes('px')) {
          const stringParts = this.width.match(/(\d+)px/)
          if (stringParts.length < 2) {
            return this.width // should never be
          } else {
            const width = Math.min(+stringParts[1], overflowMaxWidth)
            return `${width}px`
          }
        }
        // if width not in pixels
        return this.width
      }
    },
    classes() {
      return {
        'v-modal--centered': this.centered,
        'v-modal--dialog': this.dialog,
        'v-modal--scrollable-body': this.scrollableBody,
      }
    },
  },
  destroyed() {
    // Should run when page changed.
    // Also can run when modal is closed and it was removed from page (no visible effect)
    this.unlockBodyScroll()
  },
  methods: {
    lockBodyScroll() {
      const isBodyOverflowY = document.body.scrollHeight > window.innerHeight
      document.body.classList.add('modal-opened')
      if (isBodyOverflowY) {
        document.body.classList.add('modal-opened--body-overflow')
      }
    },
    unlockBodyScroll() {
      document.body.classList.remove('modal-opened')
      document.body.classList.remove('modal-opened--body-overflow')
    },
    toggleAnimatingState(value = null) {
      this.animating = value === null ? !this.animating : !!value
    },
    onModalTransitionBeforeEnter() {
      this.lockBodyScroll()
      this.toggleAnimatingState(true)
    },
    onModalTransitionAfterEnter() {
      this.toggleAnimatingState(false)
    },
    onModalTransitionBeforeLeave() {
      this.toggleAnimatingState(true)
    },
    onModalTransitionAfterLeave() {
      this.unlockBodyScroll()
      this.toggleAnimatingState(false)
    },
    closeModal() {
      this.$emit('toggle', false)
    },
  },
  render(createElement) {
    const defaultScopedSlotChildren = this.value
      ? this.$scopedSlots.default({
          closeModal: this.closeModal,
        })
      : null

    let children = []
    if (this.dialog) {
      children = createElement(
        VCard,
        {
          props: {
            bordered: true,
          },
        },
        [
          createElement(
            VCardContent,
            {
              class: {
                'd-flex': true,
                'justify-content-center': true,
                'align-items-center': true,
              },
            },
            [
              createElement(
                'div',
                {
                  class: {
                    'v-modal__close-icon': true,
                  },
                  on: {
                    click: this.closeModal,
                  },
                },
                [createElement(VIcon, 'close')]
              ),
              defaultScopedSlotChildren,
            ]
          ),
        ]
      )
    } else {
      const modalCardChildren = []
      // header
      modalCardChildren.push(
        createElement(VCardHeader, [
          // close icon
          createElement(
            'div',
            {
              class: {
                'v-modal__close-icon': true,
              },
              on: {
                click: this.closeModal,
              },
            },
            [createElement(VIcon, 'close')]
          ),
          // header (content) slot
          this.$scopedSlots.header({
            closeModal: this.closeModal,
          }),
        ])
      )
      // content (body)
      modalCardChildren.push(
        createElement(
          VCardContent,
          {
            props: {
              scrollable: this.scrollableBody,
              background: this.bodyStyle === 'dark' ? '#f0f1f5' : 'transparent',
            },
          },
          [defaultScopedSlotChildren]
        )
      )
      // footer
      this.$scopedSlots.footer &&
        modalCardChildren.push(
          createElement(VCardFooter, [
            this.$scopedSlots.footer({
              closeModal: this.closeModal,
            }),
          ])
        )

      // modal's children
      children = createElement(
        VCard,
        {
          props: {
            bordered: true,
          },
          nativeOn: {
            click: (event) => {
              // prevent modal's close
              event.stopPropagation()
            },
            mousedown: () => {
              this.mousePressed = true
            },
            mouseup: () => {
              this.mousePressed = false
            },
          },
        },
        [modalCardChildren]
      )
    }

    return createElement(
      Portal,
      {
        props: {
          to: 'modals',
        },
      },
      [
        createElement(
          'transition',
          {
            props: {
              enterActiveClass:
                'animate__animated animate__faster animate__fadeIn',
              leaveActiveClass:
                'animate__animated animate__faster animate__fadeOut',
            },
          },
          [
            createElement(
              'div',
              {
                class: {
                  'v-modal-overlay': true,
                  'v-modal-overlay--animating': this.animating,
                },
                directives: [
                  {
                    name: 'show',
                    value: this.value,
                  },
                ],
                on: {
                  click: (_event) => {
                    // note: stop click event propagation on child element with modals content
                    if (!this.mousePressed) {
                      this.closeModal()
                    }
                    this.mousePressed = false
                  },
                },
              },
              [
                createElement(
                  'transition',
                  {
                    props: {
                      enterActiveClass:
                        'animate__animated animate__faster animate__fadeInUp',
                      leaveActiveClass:
                        'animate__animated animate__faster animate__fadeOutDown',
                    },
                    on: {
                      beforeEnter: this.onModalTransitionBeforeEnter,
                      afterEnter: this.onModalTransitionAfterEnter,
                      beforeLeave: this.onModalTransitionBeforeLeave,
                      afterLeave: this.onModalTransitionAfterLeave,
                    },
                  },
                  [
                    createElement(
                      'div',
                      {
                        staticClass: this.$vnode.data.staticClass || '',
                        class: {
                          'v-modal': true,
                          ...this.classes,
                        },
                        style: {
                          width: this.modalWidth,
                        },
                        scopedSlots: {
                          default: () => createElement('div'),
                        },
                        directives: [
                          {
                            name: 'show',
                            value: this.value,
                          },
                        ],
                      },
                      [children]
                    ),
                  ]
                ),
              ]
            ),
          ]
        ),
      ]
    )
  },
}
