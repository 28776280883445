//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'StatusLabel',
  props: {
    data: {
      required: true,
      type: Object,
      default: () => {},
    },
    // big or small
    size: {
      required: false,
      type: String,
      default: 'big',
    },
  },
  computed: {
    statusName() {
      return this.data?.name
    },

    statusColor() {
      return this.data?.color
    },

    statusColorText() {
      return this.data?.colorText
    },

    type() {
      return this.data?.type
    },
  },
}
