
export default {
  name: 'VTabs',
  props: {
    alternativeStyle: {
      required: false,
      type: Boolean,
      default: false,
    },
    value: {
      required: false,
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      tabsSliderWidth: 0,
      intersectionObserverInstance: null,
    }
  },
  computed: {
    activeItem() {
      if (this.value === null || !this.$refs.tabs) {
        return null
      }
      return this.$refs.tabs[this.value]
    },
    tabsSliderStyle() {
      if (this.activeItem === null) {
        return {}
      }

      const activeItemElement = this.activeItem.$el
      const tabsBar = this.$refs.tabsBar
      if (activeItemElement === null || !tabsBar) {
        return {}
      }

      // const width = getComputedStyle(activeItemElement).width
      const width = this.tabsSliderWidth
      // const leftOffset = activeItemElement.offsetLeft - tabsBar.offsetLeft
      const leftOffset = activeItemElement.offsetLeft
      const transform = `translateX(${leftOffset}px)`
      return {
        width,
        transform,
      }
    },
    tabs() {
      return this.$refs.tabs
    },
  },
  watch: {
    activeItem: {
      handler(_val, _oldVal) {
        this.computeTabsSliderWidth()
      },
    },
  },
  mounted() {
    this.$emit('input', 0)
    this.intersectionObserverInstance = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.intersectionRatio > 0) {
            this.computeTabsSliderWidth() // or use entry.boundingClientRect
          }
        })
      },
      {
        threshold: 1,
      }
    )
    if (this.activeItem !== null && this.activeItem !== undefined) {
      this.intersectionObserverInstance.observe(this.activeItem.$el)
    }
  },
  beforeDestroy() {
    this.intersectionObserverInstance.disconnect()
  },
  methods: {
    computeTabsSliderWidth() {
      this.tabsSliderWidth = getComputedStyle(this.activeItem.$el).width
    },
    parseNodes() {
      const slot = this.$slots.default || []
      const tabs = []
      const tabItems = []
      const nodesLength = slot.length
      if (nodesLength > 0) {
        for (let i = 0; i < nodesLength; i++) {
          /** @type {VNode} */
          const node = slot[i]
          if (node.componentOptions) {
            // if node's constructor name is VTab
            if (node.componentOptions.Ctor.options.name === 'VTab') {
              tabs.push(node)
            }
            // if node's constructor name is VTabItem
            if (node.componentOptions.Ctor.options.name === 'VTabItem') {
              tabItems.push(node)
            }
          }
        }
      }

      return {
        tabs,
        tabItems,
      }
    },
  },

  render(createElement) {
    const { tabs, tabItems } = this.parseNodes()

    return createElement(
      'div',
      {
        class: {
          'v-tabs': true,
          'v-tabs--alternative': this.alternativeStyle,
        },
      },
      [
        // render tabs-bar
        createElement(
          'div',
          {
            class: {
              'v-tabs-bar': true,
              'd-flex': true,
              'align-items-center': true,
              'flex-wrap': true,
            },
            ref: 'tabsBar',
          },
          [
            createElement(
              'div',
              {
                class: {
                  'v-tabs__wrapper': true,
                  'd-flex': true,
                  'align-items-center': true,
                },
              },
              [
                // render tabs-slider
                createElement('div', {
                  class: {
                    'v-tabs-slider': true,
                  },
                  style: this.tabsSliderStyle,
                  slot: 'default',
                }),

                // render tabs
                tabs.map((tabNode, tabIndex) => {
                  return createElement(
                    tabNode.componentOptions.Ctor,
                    {
                      staticClass: tabNode.data.staticClass || '',
                      class: {
                        ...tabNode.class,
                        'v-tab--active': this.value === tabIndex,
                      },
                      props: {
                        isGroupTab: true,
                      },
                      on: {
                        ...tabNode.componentOptions.listeners,
                        'tabs:change': () => {
                          this.$emit('input', tabIndex)
                        },
                      },
                      slot: 'default',
                      ref: 'tabs',
                      key: `tab-${tabIndex}`,
                      refInFor: true,
                    },
                    tabNode.componentOptions.children
                  )
                }),
              ]
            ),

            // tabs bar append
            this.$scopedSlots.tabsBarAppend
              ? createElement(
                  'div',
                  {
                    class: {
                      'v-tabs__append-wrapper': true,
                      'ml-auto': true,
                    },
                  },
                  this.$scopedSlots.tabsBarAppend({
                    activeTabIndex: this.value,
                  })
                )
              : null,
          ]
        ),
        // render tab items
        createElement(
          'div',
          {
            class: {
              'v-tab-items-container': true,
            },
          },
          [
            tabItems.map((tabItemNode, tabItemIndex) => {
              return createElement(
                tabItemNode.componentOptions.Ctor,
                {
                  props: {
                    ...tabItemNode.componentOptions.propsData,
                  },
                  style: {
                    display:
                      tabItemNode.componentOptions.propsData.value !==
                      this.value
                        ? 'none'
                        : null,
                  },
                  slot: 'default',
                  ref: 'tabItems',
                  key: `tab-item-${tabItemIndex}`,
                  refInFor: true,
                },
                tabItemNode.componentOptions.children
              )
            }),
          ]
        ),
      ]
    )
  },
}
