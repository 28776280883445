//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'VActionsGroup',
  props: {
    /**
     * Align content right (flex-end)
     */
    alignRight: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
}
