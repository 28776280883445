import filterableTable from '@/components/ui/tables/mixins/filterableTable'
import VDataTableFilters from '@/components/ui/tables/v-data-table/VDataTableFilters'
import loadingMixin from '@/components/ui/loaders/loadingMixin'
import { getScopedSlotsByRegexp } from '@/utils/slotUtils'

export default {
  mixins: [loadingMixin, filterableTable],
  data() {
    return {
      /**
       * Value of filters
       */
      filtersValue: {}, // same with VDataTableFilters
    }
  },
  created() {
    // set initial values
    if (
      this.filters !== null &&
      this.filters !== undefined &&
      this.filters.length > 0
    ) {
      this.filters.forEach(({ name, props: { value }, type }) => {
        if (value !== undefined && value !== null && type !== 'custom') {
          this.filtersValue[name] = value
        }
      })
    }
  },
  methods: {
    onFilterData() {
      /**
       * Emits after clicking on "Filter" button. Use for loading/reloading data
       */
      this.$emit('apply:filter', this.filtersValue)
    },

    // manual get / set filters value for custom filters
    getFiltersValue(key, defaultValue = null) {
      return this.filtersValue[key] || defaultValue
    },
    setFiltersValue(key, value) {
      const filtersValue = Object.assign({}, this.filtersValue)
      filtersValue[key] = value
      this.filtersValue = filtersValue
    },

    /**
     * @return VNode
     */
    genDataTableFilters() {
      const scopedSlots = {}
      // pass filterButtonColumn slot
      if (this.$scopedSlots.filterButtonColumn !== undefined) {
        scopedSlots.filterButtonColumn = ({ onFilterButtonClick, loading }) => {
          return this.$scopedSlots.filterButtonColumn({
            onFilterButtonClick,
            loading,
            getFiltersValue: this.getFiltersValue,
            setFiltersValue: this.setFiltersValue,
          })
        }
      }

      // pass filter.<name> slots (this is column with filter form controls)
      const filterScopedSlots = getScopedSlotsByRegexp(this, /filter\.\w+/)
      for (const key in filterScopedSlots) {
        scopedSlots[key] = (props) => {
          return filterScopedSlots[key]({
            ...props,
            getFiltersValue: this.getFiltersValue,
            setFiltersValue: this.setFiltersValue,
          })
        }
      }

      return this.$createElement(VDataTableFilters, {
        props: {
          loading: this.loading,
          filters: this.filters,
          filtersValue: this.filtersValue,
          filterButtonColumns: this.filterButtonColumns,
          filtersColumnFlexFill: this.filtersColumnFlexFill,
          equalFiltersColumnWidth: this.equalFiltersColumnWidth,
        },
        on: {
          'update:filtersValue': (filters) => {
            this.filtersValue = filters
          },
          'filter-data': this.onFilterData,
        },
        scopedSlots,
      })
    },
  },
}
