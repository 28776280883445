
import loadingMixin from '@/components/ui/loaders/loadingMixin'
import genDefaultPreloaderMixin from '@/components/ui/loaders/genDefaultPreloaderMixin'
import DataTableFilterMixin from '@/components/ui/tables/mixins/DataTableFilterMixin'
import DataTablePaginationMixin from '@/components/ui/tables/mixins/DataTablePaginationMixin'
import VCard from '@/components/ui/v-card/VCard'

export default {
  name: 'VDataIterator',
  mixins: [
    loadingMixin,
    genDefaultPreloaderMixin,
    DataTableFilterMixin,
    DataTablePaginationMixin,
  ],
  props: {
    /**
     * Array of items (entities) for displaying
     */
    items: {
      required: true,
      type: Array, // Array of objects. better to rewrite with TS
    },
  },
  methods: {
    /// render methods
    /**
     * @return VNode
     */
    genItemsWrapperPrependSlot() {
      if (!this.$scopedSlots.itemsWrapperPrepend) {
        return undefined
      }
      return this.$scopedSlots.itemsWrapperPrepend({
        items: this.items,
        meta: this.meta,
        loading: this.loading,
      })
    },
    /**
     * @return VNode
     */
    genItemSlot(item, index) {
      return this.$scopedSlots.item({
        item,
        index,
      })
    },
    /**
     * @return VNode
     */
    genItemsWrapperSlot() {
      if (this.$scopedSlots.itemsWrapper !== undefined) {
        return this.$scopedSlots.itemsWrapper({
          items: this.items,
          loading: this.loading,
        })
      }
      const itemsNodes = this.items.map((item, index) => {
        return this.genItemSlot(item, index)
      })

      const children = []
      if (this.loading && !this.items) {
        children.push(this.$createElement(VCard, [this.genDefaultPreloader()]))
      } else {
        children.push(itemsNodes)
        if (this.loading) {
          children.unshift(this.genDefaultPreloader())
        }
      }

      return this.$createElement(
        'div',
        {
          class: {
            'v-data-iterator__items-wrapper': true,
          },
        },
        children
      )
    },

    /**
     * @return VNode
     */
    genDataIteratorPagination() {
      return this.$createElement(
        'div',
        {
          class: {
            'v-data-iterator__pagination': true,
            'd-flex': true,
          },
        },
        [this.genDataTablePagination(), this.genPerPageDropdown()]
      )
    },

    genDataIterator() {
      const children = [
        this.genItemsWrapperPrependSlot(),
        this.genItemsWrapperSlot(),
        this.genDataIteratorPagination(),
      ]

      if (this.filters) {
        children.unshift(this.genDataTableFilters())
      }

      const data = {
        class: {
          'v-data-iterator-wrapper': true,
          'v-simple-table__wrap-responsive': this.responsive,
        },
      }

      return this.$createElement('div', data, children)
    },
  },
  /**
   * Items wrapper's prepend slot
   * @slot itemsWrapperPrepend
   */
  render() {
    return this.genDataIterator()
  },
}
