
import VDataTableConfigModal from '@/components/ui/tables/v-data-table/VDataTableConfigModal'
import DataTableFilterMixin from '@/components/ui/tables/mixins/DataTableFilterMixin'
import DataTablePaginationMixin from '@/components/ui/tables/mixins/DataTablePaginationMixin'
import VSpacer from '~/components/ui/v-spacer/VSpacer'
import VSimpleTable from '~/components/ui/tables/v-simple-table/VSimpleTable'
import VMenu from '~/components/ui/v-menu/VMenu'
import VBtn from '~/components/ui/v-btn/VBtn'
import VIcon from '~/components/ui/v-icon/VIcon'
import VMenuItem from '~/components/ui/v-menu/VMenuItem'

export default {
  name: 'VDataTable',
  mixins: [VSimpleTable, DataTableFilterMixin, DataTablePaginationMixin],
  props: {
    enableWidgets: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      widgetsVisible: true, // show widgets or not
      settingsModalActive: false,
    }
  },
  methods: {
    onUpdateWidgetsVisibility(widgetsVisible) {
      this.widgetsVisible = widgetsVisible
      this.settingsModalActive = false
    },

    onSettingsButtonClick() {
      this.settingsModalActive = !this.settingsModalActive
    },

    onModalToggle(value) {
      this.settingsModalActive = value
    },

    onUpdateVisibleHeaders(visibleHeaders) {
      this.visibleHeaders = Object.assign({}, visibleHeaders)
      this.settingsModalActive = false
    },

    /// render methods

    /**
     * @return Array<VNode>
     */
    genDataTableSettingsButtonSlot() {
      if (this.$scopedSlots.settingsButton !== undefined) {
        return this.$scopedSlots.settingsButton({
          items: this.items,
          headers: this.headers,
          onSettingsButtonClick: this.onSettingsButtonClick,
          settingsModalActive: this.settingsModalActive,
          enableWidgets: this.enableWidgets,
          widgetsVisible: this.widgetsVisible,
          onUpdateVisibleHeaders: this.onUpdateVisibleHeaders,
          onUpdateWidgetsVisibility: this.onUpdateWidgetsVisibility,
          modalToggle: this.onModalToggle,
          alwaysVisibleHeaders: this.alwaysVisibleHeaders,
        })
      }
      const settingsButton = this.$createElement(
        VBtn,
        {
          class: {
            'v-data-table__settings-button': true,
          },
          props: {
            iconContent: true,
            outline: true,
            color: 'primary',
          },
          on: {
            click: this.onSettingsButtonClick,
          },
        },
        [
          this.$createElement(
            VIcon,
            {
              props: {
                color: 'primary',
              },
            },
            'cog'
          ),
        ]
      )

      const settingsModal = this.$createElement(VDataTableConfigModal, {
        props: {
          headers: this.headers,
          value: this.settingsModalActive,
          enableWidgets: this.enableWidgets,
          widgetsVisible: this.widgetsVisible,
          alwaysVisibleHeaders: this.alwaysVisibleHeaders,
        },
        on: {
          toggle: this.onModalToggle,
          'update:visibleHeaders': this.onUpdateVisibleHeaders,
          'update:widgetsVisibility': this.onUpdateWidgetsVisibility,
        },
      })

      return [settingsButton, settingsModal]
    },
    /**
     * @return Array<VNode>
     */
    genDataTableExportButtonSlot() {
      if (this.$scopedSlots.exportButton !== undefined) {
        return this.$scopedSlots.exportButton({
          items: this.items,
        })
      }
      return this.$createElement(
        VMenu,
        {
          props: {
            menuOverlay: false,
          },
          scopedSlots: {
            activator: ({ toggle }) => {
              return this.$createElement(
                VBtn,
                {
                  class: {
                    'v-data-table__export-button': true,
                  },
                  props: {
                    outline: true,
                    color: 'primary',
                  },
                  on: {
                    click: () => {
                      toggle()
                    },
                  },
                },
                [
                  'XLS',
                  this.$createElement(
                    VIcon,
                    {
                      color: 'primary',
                      slot: 'append',
                    },
                    'caret-down'
                  ),
                ]
              )
            },
          },
        },
        [
          this.$createElement(
            VMenuItem,
            {
              props: {
                color: 'success',
              },
            },
            'XLS'
          ),
          this.$createElement(
            VMenuItem,
            {
              props: {
                color: 'success',
              },
            },
            'ODS'
          ),
          this.$createElement(
            VMenuItem,
            {
              props: {
                color: 'success',
              },
            },
            'CSV'
          ),
        ]
      )
    },
    /**
     * @return Array<VNode>
     */
    genPrependTableLeftSlot() {
      if (this.$scopedSlots.tablePrependLeft !== undefined) {
        return this.$scopedSlots.tablePrependLeft({
          items: this.items,
          meta: this.meta,
        })
      }

      // Desktop label (text only)
      const defaultPerPageLabel = this.$createElement(
        'span',
        {
          class: {
            'd-md-inline': true,
            'd-none': true,
          },
        },
        [`Showing ${this.items.length} entries `]
      )
      // Mobile label with dropdown for selecting per page items count
      const mobilePerPageBlock = this.$createElement(
        'span',
        {
          class: {
            'd-inline': true,
            'd-md-none': true,
          },
        },
        ['Showing ', this.genPerPageDropdown(true), ' entries']
      )

      return this.$createElement('div', {}, [
        defaultPerPageLabel,
        mobilePerPageBlock,
        this.$createElement(
          'span',
          {
            class: {
              'd-none': true,
              'd-md-inline': true,
            },
          },
          `(filtered from ${this.meta.total} total entries)`
        ),
      ])
    },
    /**
     * @return Array<VNode>
     */
    genPrependTableRightSlot() {
      if (this.$scopedSlots.tablePrependRight !== undefined) {
        return this.$scopedSlots.tablePrependRight({
          items: this.items,
          headers: this.headers,
          onSettingsButtonClick: this.onSettingsButtonClick,
          settingsModalActive: this.settingsModalActive,
          enableWidgets: this.enableWidgets,
          widgetsVisible: this.widgetsVisible,
          onUpdateVisibleHeaders: this.onUpdateVisibleHeaders,
          onUpdateWidgetsVisibility: this.onUpdateWidgetsVisibility,
          modalToggle: this.onModalToggle,
          alwaysVisibleHeaders: this.alwaysVisibleHeaders,
        })
      }
      return this.$createElement(
        'div',
        {
          class: {
            'd-flex': true,
          },
        },
        [
          this.genDataTableSettingsButtonSlot(),
          this.genDataTableExportButtonSlot(),
        ]
      )
    },
    /**
     * @return Array<VNode>
     */
    genPrependTableSlot() {
      if (this.$scopedSlots.tablePrepend !== undefined) {
        return this.$scopedSlots.tablePrepend({
          items: this.items,
          meta: this.meta,
          headers: this.headers,
          onSettingsButtonClick: this.onSettingsButtonClick,
          settingsModalActive: this.settingsModalActive,
          enableWidgets: this.enableWidgets,
          widgetsVisible: this.widgetsVisible,
          onUpdateVisibleHeaders: this.onUpdateVisibleHeaders,
          onUpdateWidgetsVisibility: this.onUpdateWidgetsVisibility,
          modalToggle: this.onModalToggle,
          alwaysVisibleHeaders: this.alwaysVisibleHeaders,
          perPageOptions: this.perPageOptions,
        })
      }
      return this.$createElement(
        'div',
        {
          class: {
            'v-data-table__prepend-slot': true,
            // 'd-none': true,
            // 'd-md-flex': true,
            'd-flex': true,
            'align-items-center': true,
          },
        },
        [
          this.$createElement(
            'div',
            {
              class: {
                'v-data-table__prepend-slot-items-wrapper': true,
                'd-flex': true,
                'flex-fill': true,
                'align-items-center': true,
              },
            },
            [
              this.genPrependTableLeftSlot(),
              this.$createElement(VSpacer),
              this.genPrependTableRightSlot(),
            ]
          ),
        ]
      )
    },

    /**
     * Safe (empty by default) table prepend slot
     * @return Array<VNode> | null
     */
    genAfterPrependTableSlot() {
      if (this.$scopedSlots.afterTablePrepend !== undefined) {
        return this.$scopedSlots.afterTablePrepend({
          items: this.items,
          meta: this.meta,
          headers: this.headers,
        })
      } else {
        return null
      }
    },

    /**
     * @return VNode
     */
    genTableFooter() {
      return this.$createElement(
        'div',
        {
          class: {
            'v-data-table__footer': true,
            'd-flex': true,
          },
        },
        [this.genDataTablePagination(), this.genPerPageDropdown()]
      )
    },
    /**
     * @return VNode
     */
    genDataTable() {
      const data = {
        class: {
          'v-data-table-wrapper': true,
        },
      }

      const table = this.genSimpleTable()

      const children = [
        this.genPrependTableSlot(),
        this.genAfterPrependTableSlot(),
        table,
      ]

      if (this.$scopedSlots.alert) {
        const alertSlot = this.$createElement('div', [
          this.$scopedSlots.alert(),
        ])
        if (alertSlot) {
          children.unshift(alertSlot)
        }
      }

      if (
        this.filters !== null &&
        this.filters !== undefined &&
        this.filters.length > 0
      ) {
        children.unshift(this.genDataTableFilters())
      }
      children.push(this.genTableFooter())

      return this.$createElement('div', data, children)
    },
  },

  /**
   * Table prepend slot
   *
   * @slot tablePrepend
   * @binding {Array} items Binding: items
   * @binding {object} meta Binding: meta
   * @binding {Array} headers Binding: headers
   * @binding {function} onSettingsButtonClick Binding: onSettingsButtonClick
   * @binding {boolean} settingsModalActive Binding: settingsModalActive
   * @binding {boolean} enableWidgets Binding: enableWidgets
   * @binding {function} onUpdateVisibleHeaders Binding: onUpdateVisibleHeaders
   * @binding {function} onUpdateWidgetsVisibility Binding: onUpdateWidgetsVisibility
   * @binding {function} modalToggle Binding: modalToggle
   */
  /**
   * After table prepend slot. Safe (empty by default) table prepend slot
   *
   * @slot afterTablePrepend
   * @binding {Array} items Binding: items
   * @binding {object} meta Binding: meta
   * @binding {Array} headers Binding: headers
   */
  render() {
    return this.genDataTable()
  },
}
