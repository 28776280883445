import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _c13e5ff8 = () => interopDefault(import('../pages/conversion-rate-dashboard.vue' /* webpackChunkName: "pages/conversion-rate-dashboard" */))
const _bcadc99c = () => interopDefault(import('../pages/conversion-rate-dashboard/_country.vue' /* webpackChunkName: "pages/conversion-rate-dashboard/_country" */))
const _18d1a49d = () => interopDefault(import('../pages/conversion-rate-dashboard/_country/_product.vue' /* webpackChunkName: "pages/conversion-rate-dashboard/_country/_product" */))
const _6580d463 = () => interopDefault(import('../pages/finance/index.vue' /* webpackChunkName: "pages/finance/index" */))
const _6262cc12 = () => interopDefault(import('../pages/finance-charts.vue' /* webpackChunkName: "pages/finance-charts" */))
const _dbcdea3c = () => interopDefault(import('../pages/inventory.vue' /* webpackChunkName: "pages/inventory" */))
const _4bea8375 = () => interopDefault(import('../pages/logistic-documents.vue' /* webpackChunkName: "pages/logistic-documents" */))
const _00ec82ed = () => interopDefault(import('../pages/negative-reviews.vue' /* webpackChunkName: "pages/negative-reviews" */))
const _8f5e3fc0 = () => interopDefault(import('../pages/pp-optimization.vue' /* webpackChunkName: "pages/pp-optimization" */))
const _34592ad4 = () => interopDefault(import('../pages/pp-optimization/_country.vue' /* webpackChunkName: "pages/pp-optimization/_country" */))
const _0aff348e = () => interopDefault(import('../pages/pp-optimization/_country/_product.vue' /* webpackChunkName: "pages/pp-optimization/_country/_product" */))
const _0c217f3c = () => interopDefault(import('../pages/product-alerts/index.vue' /* webpackChunkName: "pages/product-alerts/index" */))
const _6a13e351 = () => interopDefault(import('../pages/products/index.vue' /* webpackChunkName: "pages/products/index" */))
const _243e4fc4 = () => interopDefault(import('../pages/rank-tracker/index.vue' /* webpackChunkName: "pages/rank-tracker/index" */))
const _327cfd81 = () => interopDefault(import('../pages/refunds.vue' /* webpackChunkName: "pages/refunds" */))
const _274e9020 = () => interopDefault(import('../pages/review-charts.vue' /* webpackChunkName: "pages/review-charts" */))
const _c9e494ca = () => interopDefault(import('../pages/shopify-price-control/index.vue' /* webpackChunkName: "pages/shopify-price-control/index" */))
const _bdeee326 = () => interopDefault(import('../pages/tickets.vue' /* webpackChunkName: "pages/tickets" */))
const _935b796c = () => interopDefault(import('../pages/ui-kit/index.vue' /* webpackChunkName: "pages/ui-kit/index" */))
const _8e1272a8 = () => interopDefault(import('../pages/auth/login.vue' /* webpackChunkName: "pages/auth/login" */))
const _b551656a = () => interopDefault(import('../pages/competitor-analysis/dashboard/index.vue' /* webpackChunkName: "pages/competitor-analysis/dashboard/index" */))
const _0db48d68 = () => interopDefault(import('../pages/cx/complaints-manager.vue' /* webpackChunkName: "pages/cx/complaints-manager" */))
const _2c730be2 = () => interopDefault(import('../pages/cx/complaints-manager/components/DeleteCellRenderer.vue' /* webpackChunkName: "pages/cx/complaints-manager/components/DeleteCellRenderer" */))
const _8872067e = () => interopDefault(import('../pages/cx/complaints-manager/components/EditCellRenderer.vue' /* webpackChunkName: "pages/cx/complaints-manager/components/EditCellRenderer" */))
const _0c62ef16 = () => interopDefault(import('../pages/cx/complaints-manager/components/ModalAddComplaint.vue' /* webpackChunkName: "pages/cx/complaints-manager/components/ModalAddComplaint" */))
const _8840f9b6 = () => interopDefault(import('../pages/cx/complaints-manager/components/ModalDeleteComplaint.vue' /* webpackChunkName: "pages/cx/complaints-manager/components/ModalDeleteComplaint" */))
const _98bd4734 = () => interopDefault(import('../pages/cx/complaints-manager/components/ModalEditComplaint.vue' /* webpackChunkName: "pages/cx/complaints-manager/components/ModalEditComplaint" */))
const _351a260e = () => interopDefault(import('../pages/cx/complaints-validation.vue' /* webpackChunkName: "pages/cx/complaints-validation" */))
const _2ed9019a = () => interopDefault(import('../pages/cx/complaints-validation/Dashboard.vue' /* webpackChunkName: "pages/cx/complaints-validation/Dashboard" */))
const _60085aac = () => interopDefault(import('../pages/cx/complaints-validation/Overview.vue' /* webpackChunkName: "pages/cx/complaints-validation/Overview" */))
const _1648c749 = () => interopDefault(import('../pages/cx/complaints-validation/Review.vue' /* webpackChunkName: "pages/cx/complaints-validation/Review" */))
const _db6aaac2 = () => interopDefault(import('../pages/cx/complaints-validation/components/CountersCard.vue' /* webpackChunkName: "pages/cx/complaints-validation/components/CountersCard" */))
const _33b3e5cd = () => interopDefault(import('../pages/cx/complaints-validation/components/Tab.vue' /* webpackChunkName: "pages/cx/complaints-validation/components/Tab" */))
const _0bdca314 = () => interopDefault(import('../pages/cx/complaints-validation/components/CellRenderers/ActionCellRenderer.vue' /* webpackChunkName: "pages/cx/complaints-validation/components/CellRenderers/ActionCellRenderer" */))
const _fe7e01b2 = () => interopDefault(import('../pages/cx/complaints-validation/components/CellRenderers/ComplaintCellRenderer.vue' /* webpackChunkName: "pages/cx/complaints-validation/components/CellRenderers/ComplaintCellRenderer" */))
const _0b799836 = () => interopDefault(import('../pages/cx/complaints-validation/components/CellRenderers/ReviewCellRenderer.vue' /* webpackChunkName: "pages/cx/complaints-validation/components/CellRenderers/ReviewCellRenderer" */))
const _5fdc46d9 = () => interopDefault(import('../pages/cx/complaints-validation/components/CellRenderers/SkuCellRenderer.vue' /* webpackChunkName: "pages/cx/complaints-validation/components/CellRenderers/SkuCellRenderer" */))
const _2e3f22f9 = () => interopDefault(import('../pages/cx/complaints-validation/components/CellRenderers/SourceCellRenderer.vue' /* webpackChunkName: "pages/cx/complaints-validation/components/CellRenderers/SourceCellRenderer" */))
const _2b77fcd0 = () => interopDefault(import('../pages/cx/complaints-validation/components/CellRenderers/StatusCellRenderer.vue' /* webpackChunkName: "pages/cx/complaints-validation/components/CellRenderers/StatusCellRenderer" */))
const _79c6868e = () => interopDefault(import('../pages/cx/complaints-validation/_id.vue' /* webpackChunkName: "pages/cx/complaints-validation/_id" */))
const _582553aa = () => interopDefault(import('../pages/cx/helicopter-view.vue' /* webpackChunkName: "pages/cx/helicopter-view" */))
const _f3aba574 = () => interopDefault(import('../pages/cx/helicopter-view/components/CountryCharts.vue' /* webpackChunkName: "pages/cx/helicopter-view/components/CountryCharts" */))
const _218c458f = () => interopDefault(import('../pages/cx/helicopter-view/components/FeedbackCharts.vue' /* webpackChunkName: "pages/cx/helicopter-view/components/FeedbackCharts" */))
const _6adb9674 = () => interopDefault(import('../pages/cx/helicopter-view/components/FeedbackType.vue' /* webpackChunkName: "pages/cx/helicopter-view/components/FeedbackType" */))
const _a63e523e = () => interopDefault(import('../pages/cx/helicopter-view/components/MonthDateFilter.vue' /* webpackChunkName: "pages/cx/helicopter-view/components/MonthDateFilter" */))
const _379226ce = () => interopDefault(import('../pages/cx/helicopter-view/components/TableView.vue' /* webpackChunkName: "pages/cx/helicopter-view/components/TableView" */))
const _5b19e649 = () => interopDefault(import('../pages/cx/helicopter-view/components/TopProductsCharts.vue' /* webpackChunkName: "pages/cx/helicopter-view/components/TopProductsCharts" */))
const _28584017 = () => interopDefault(import('../pages/cx/helicopter-view/components/charts/DoughnutChart.vue' /* webpackChunkName: "pages/cx/helicopter-view/components/charts/DoughnutChart" */))
const _f53c4a4c = () => interopDefault(import('../pages/cx/helicopter-view/components/charts/FeedbackTypeChart.vue' /* webpackChunkName: "pages/cx/helicopter-view/components/charts/FeedbackTypeChart" */))
const _1ed325d8 = () => interopDefault(import('../pages/cx/helicopter-view/components/charts/LinearChart.vue' /* webpackChunkName: "pages/cx/helicopter-view/components/charts/LinearChart" */))
const _fea4c4fe = () => interopDefault(import('../pages/cx/helicopter-view/components/charts/ProgressBar.vue' /* webpackChunkName: "pages/cx/helicopter-view/components/charts/ProgressBar" */))
const _e3ee40fc = () => interopDefault(import('../pages/cx/leader-board.vue' /* webpackChunkName: "pages/cx/leader-board" */))
const _36086bff = () => interopDefault(import('../pages/image-uploader/listing.vue' /* webpackChunkName: "pages/image-uploader/listing" */))
const _2364bd7a = () => interopDefault(import('../pages/image-uploader/listing/components/TableView.vue' /* webpackChunkName: "pages/image-uploader/listing/components/TableView" */))
const _decdcf2c = () => interopDefault(import('../pages/image-uploader/listing/components/CellRenderer/ActionRenderer.vue' /* webpackChunkName: "pages/image-uploader/listing/components/CellRenderer/ActionRenderer" */))
const _3259d04a = () => interopDefault(import('../pages/image-uploader/listing/components/CellRenderer/CurrentRenderer.vue' /* webpackChunkName: "pages/image-uploader/listing/components/CellRenderer/CurrentRenderer" */))
const _748fe47e = () => interopDefault(import('../pages/image-uploader/listing/components/CellRenderer/EditRenderer.vue' /* webpackChunkName: "pages/image-uploader/listing/components/CellRenderer/EditRenderer" */))
const _9f2b5c14 = () => interopDefault(import('../pages/image-uploader/listing/components/CellRenderer/ForceUpdateRenderer.vue' /* webpackChunkName: "pages/image-uploader/listing/components/CellRenderer/ForceUpdateRenderer" */))
const _23e7773c = () => interopDefault(import('../pages/image-uploader/listing/components/CellRenderer/NicknameRenderer.vue' /* webpackChunkName: "pages/image-uploader/listing/components/CellRenderer/NicknameRenderer" */))
const _9f7a3a2a = () => interopDefault(import('../pages/image-uploader/listing/components/CellRenderer/SetCurrentRenderer.vue' /* webpackChunkName: "pages/image-uploader/listing/components/CellRenderer/SetCurrentRenderer" */))
const _235f8e7d = () => interopDefault(import('../pages/image-uploader/listing/components/CellRenderer/UpdateRenderer.vue' /* webpackChunkName: "pages/image-uploader/listing/components/CellRenderer/UpdateRenderer" */))
const _04e672ad = () => interopDefault(import('../pages/image-uploader/listing/components/ModalWindow/ImageModal.vue' /* webpackChunkName: "pages/image-uploader/listing/components/ModalWindow/ImageModal" */))
const _1653b8ce = () => interopDefault(import('../pages/image-uploader/listing/components/ModalWindow/MonitorModal.vue' /* webpackChunkName: "pages/image-uploader/listing/components/ModalWindow/MonitorModal" */))
const _063f1159 = () => interopDefault(import('../pages/image-uploader/listing/components/ModalWindow/UpdateModal.vue' /* webpackChunkName: "pages/image-uploader/listing/components/ModalWindow/UpdateModal" */))
const _db3c6cd2 = () => interopDefault(import('../pages/image-uploader/listing/_country.vue' /* webpackChunkName: "pages/image-uploader/listing/_country" */))
const _7a135050 = () => interopDefault(import('../pages/image-uploader/listing/_country/_product.vue' /* webpackChunkName: "pages/image-uploader/listing/_country/_product" */))
const _28d711e4 = () => interopDefault(import('../pages/image-uploader/overview.vue' /* webpackChunkName: "pages/image-uploader/overview" */))
const _6a7ac0ed = () => interopDefault(import('../pages/image-uploader/overview/components/GalleryCardItem.vue' /* webpackChunkName: "pages/image-uploader/overview/components/GalleryCardItem" */))
const _5b008130 = () => interopDefault(import('../pages/image-uploader/overview/_country.vue' /* webpackChunkName: "pages/image-uploader/overview/_country" */))
const _0cb15d27 = () => interopDefault(import('../pages/image-uploader/overview/_country/_product.vue' /* webpackChunkName: "pages/image-uploader/overview/_country/_product" */))
const _b50ca01a = () => interopDefault(import('../pages/its/dashboard.vue' /* webpackChunkName: "pages/its/dashboard" */))
const _5e5033c1 = () => interopDefault(import('../pages/its/local-shipments-tracker.vue' /* webpackChunkName: "pages/its/local-shipments-tracker" */))
const _1713631b = () => interopDefault(import('../pages/its/po-tracker.vue' /* webpackChunkName: "pages/its/po-tracker" */))
const _a04b6ce6 = () => interopDefault(import('../pages/its/po-tracker/direct.vue' /* webpackChunkName: "pages/its/po-tracker/direct" */))
const _0a8b9e6e = () => interopDefault(import('../pages/its/po-tracker/reverse.vue' /* webpackChunkName: "pages/its/po-tracker/reverse" */))
const _59319704 = () => interopDefault(import('../pages/its/po-tracker/components/AsinTableCell.vue' /* webpackChunkName: "pages/its/po-tracker/components/AsinTableCell" */))
const _37ff7f4d = () => interopDefault(import('../pages/its/po-tracker/components/NicknameTableCell.vue' /* webpackChunkName: "pages/its/po-tracker/components/NicknameTableCell" */))
const _52a33286 = () => interopDefault(import('../pages/its/po-tracker/components/NicknameTableCellForReverse.vue' /* webpackChunkName: "pages/its/po-tracker/components/NicknameTableCellForReverse" */))
const _fe310b84 = () => interopDefault(import('../pages/its/po-tracker/components/PlainTextTableCell.vue' /* webpackChunkName: "pages/its/po-tracker/components/PlainTextTableCell" */))
const _ed7f97ec = () => interopDefault(import('../pages/its/po-tracker/components/ProductNicknameCell.vue' /* webpackChunkName: "pages/its/po-tracker/components/ProductNicknameCell" */))
const _c483c1ee = () => interopDefault(import('../pages/its/po-tracker/components/StatusTableCell.vue' /* webpackChunkName: "pages/its/po-tracker/components/StatusTableCell" */))
const _605e69e3 = () => interopDefault(import('../pages/its/shipments-tracker.vue' /* webpackChunkName: "pages/its/shipments-tracker" */))
const _bfe60af6 = () => interopDefault(import('../pages/its/stock-control.vue' /* webpackChunkName: "pages/its/stock-control" */))
const _412ec20e = () => interopDefault(import('../pages/product-catalog/catalog/index.vue' /* webpackChunkName: "pages/product-catalog/catalog/index" */))
const _04e7c71e = () => interopDefault(import('../pages/product-catalog/rating/index.vue' /* webpackChunkName: "pages/product-catalog/rating/index" */))
const _f42b8de0 = () => interopDefault(import('../pages/product-passport/catalog-groups/index.vue' /* webpackChunkName: "pages/product-passport/catalog-groups/index" */))
const _a93b74cc = () => interopDefault(import('../pages/product-passport/catalog-items/index.vue' /* webpackChunkName: "pages/product-passport/catalog-items/index" */))
const _3336aa77 = () => interopDefault(import('../pages/product-passport/listings/index.vue' /* webpackChunkName: "pages/product-passport/listings/index" */))
const _0fd92797 = () => interopDefault(import('../pages/product-passport/parents/index.vue' /* webpackChunkName: "pages/product-passport/parents/index" */))
const _9b7b11a8 = () => interopDefault(import('../pages/product-passport/products/index.vue' /* webpackChunkName: "pages/product-passport/products/index" */))
const _4ff4eee6 = () => interopDefault(import('../pages/settings/general.vue' /* webpackChunkName: "pages/settings/general" */))
const _674a63da = () => interopDefault(import('../pages/settings/inventory.vue' /* webpackChunkName: "pages/settings/inventory" */))
const _2c2ba2f5 = () => interopDefault(import('../pages/settings/negative-reviews.vue' /* webpackChunkName: "pages/settings/negative-reviews" */))
const _10c6cf27 = () => interopDefault(import('../pages/settings/product-alerts.vue' /* webpackChunkName: "pages/settings/product-alerts" */))
const _2f598586 = () => interopDefault(import('../pages/settings/users.vue' /* webpackChunkName: "pages/settings/users" */))
const _67f746ae = () => interopDefault(import('../pages/ui-kit/icons-list.vue' /* webpackChunkName: "pages/ui-kit/icons-list" */))
const _bc11ae84 = () => interopDefault(import('../pages/competitor-analysis/dashboard/details/index.vue' /* webpackChunkName: "pages/competitor-analysis/dashboard/details/index" */))
const _7084cd25 = () => interopDefault(import('../pages/competitor-analysis/dashboard/details/index/components/DateRangePicker.vue' /* webpackChunkName: "pages/competitor-analysis/dashboard/details/index/components/DateRangePicker" */))
const _62b72bd6 = () => interopDefault(import('../pages/competitor-analysis/dashboard/details/index/components/InfoBtn.vue' /* webpackChunkName: "pages/competitor-analysis/dashboard/details/index/components/InfoBtn" */))
const _4ce17671 = () => interopDefault(import('../pages/competitor-analysis/dashboard/details/index/components/ModalEditNote.vue' /* webpackChunkName: "pages/competitor-analysis/dashboard/details/index/components/ModalEditNote" */))
const _093b2d1d = () => interopDefault(import('../pages/competitor-analysis/dashboard/details/index/components/Tab.vue' /* webpackChunkName: "pages/competitor-analysis/dashboard/details/index/components/Tab" */))
const _37dc1890 = () => interopDefault(import('../pages/competitor-analysis/dashboard/details/index/_country.vue' /* webpackChunkName: "pages/competitor-analysis/dashboard/details/index/_country" */))
const _95f0011e = () => interopDefault(import('../pages/competitor-analysis/dashboard/details/index/_country/components/ChartView.vue' /* webpackChunkName: "pages/competitor-analysis/dashboard/details/index/_country/components/ChartView" */))
const _fded193e = () => interopDefault(import('../pages/competitor-analysis/dashboard/details/index/_country/components/TableView.vue' /* webpackChunkName: "pages/competitor-analysis/dashboard/details/index/_country/components/TableView" */))
const _0e2b31c6 = () => interopDefault(import('../pages/competitor-analysis/dashboard/details/index/_country/components/CellRenderers/CategoryCellRenderer.vue' /* webpackChunkName: "pages/competitor-analysis/dashboard/details/index/_country/components/CellRenderers/CategoryCellRenderer" */))
const _18b10990 = () => interopDefault(import('../pages/competitor-analysis/dashboard/details/index/_country/components/CellRenderers/ChangeCellRenderer.vue' /* webpackChunkName: "pages/competitor-analysis/dashboard/details/index/_country/components/CellRenderers/ChangeCellRenderer" */))
const _3e58eb7c = () => interopDefault(import('../pages/competitor-analysis/dashboard/details/index/_country/components/CellRenderers/LinkCellRenderer.vue' /* webpackChunkName: "pages/competitor-analysis/dashboard/details/index/_country/components/CellRenderers/LinkCellRenderer" */))
const _17a8ebde = () => interopDefault(import('../pages/competitor-analysis/dashboard/details/index/_country/components/CellRenderers/RankHeaderComponent.vue' /* webpackChunkName: "pages/competitor-analysis/dashboard/details/index/_country/components/CellRenderers/RankHeaderComponent" */))
const _3d65fa52 = () => interopDefault(import('../pages/competitor-analysis/dashboard/details/index/_country/_product.vue' /* webpackChunkName: "pages/competitor-analysis/dashboard/details/index/_country/_product" */))
const _fcd6a8e0 = () => interopDefault(import('../pages/competitor-analysis/log/competitors.vue' /* webpackChunkName: "pages/competitor-analysis/log/competitors" */))
const _9fc2ce12 = () => interopDefault(import('../pages/competitor-analysis/log/competitors/_asin.vue' /* webpackChunkName: "pages/competitor-analysis/log/competitors/_asin" */))
const _fe88396e = () => interopDefault(import('../pages/competitor-analysis/log/uep.vue' /* webpackChunkName: "pages/competitor-analysis/log/uep" */))
const _61dee58d = () => interopDefault(import('../pages/competitor-analysis/log/uep/_country.vue' /* webpackChunkName: "pages/competitor-analysis/log/uep/_country" */))
const _e4cb5ebc = () => interopDefault(import('../pages/competitor-analysis/log/uep/_country/_product.vue' /* webpackChunkName: "pages/competitor-analysis/log/uep/_country/_product" */))
const _467eb6ab = () => interopDefault(import('../pages/product-catalog/catalog/product.vue' /* webpackChunkName: "pages/product-catalog/catalog/product" */))
const _785db6d6 = () => interopDefault(import('../pages/product-catalog/catalog/product/_id.vue' /* webpackChunkName: "pages/product-catalog/catalog/product/_id" */))
const _06b745cb = () => interopDefault(import('../pages/product-catalog/catalog/product/_id/artworks/index.vue' /* webpackChunkName: "pages/product-catalog/catalog/product/_id/artworks/index" */))
const _6eda39f0 = () => interopDefault(import('../pages/product-catalog/catalog/product/_id/ebc/index.vue' /* webpackChunkName: "pages/product-catalog/catalog/product/_id/ebc/index" */))
const _5542c644 = () => interopDefault(import('../pages/product-catalog/catalog/product/_id/images/index.vue' /* webpackChunkName: "pages/product-catalog/catalog/product/_id/images/index" */))
const _7d177e6e = () => interopDefault(import('../pages/product-catalog/catalog/product/_id/main-info.vue' /* webpackChunkName: "pages/product-catalog/catalog/product/_id/main-info" */))
const _1004924e = () => interopDefault(import('../pages/product-catalog/catalog/product/_id/artworks/ArtworkZone.vue' /* webpackChunkName: "pages/product-catalog/catalog/product/_id/artworks/ArtworkZone" */))
const _75ed6cb2 = () => interopDefault(import('../pages/product-catalog/catalog/product/_id/components/FormBuilder.vue' /* webpackChunkName: "pages/product-catalog/catalog/product/_id/components/FormBuilder" */))
const _484fe088 = () => interopDefault(import('../pages/product-catalog/catalog/product/_id/components/FormElement.vue' /* webpackChunkName: "pages/product-catalog/catalog/product/_id/components/FormElement" */))
const _6d27a99e = () => interopDefault(import('../pages/product-catalog/catalog/product/_id/components/FormHeader.vue' /* webpackChunkName: "pages/product-catalog/catalog/product/_id/components/FormHeader" */))
const _0426fd49 = () => interopDefault(import('../pages/product-catalog/catalog/product/_id/ebc/components/ImageBlock.vue' /* webpackChunkName: "pages/product-catalog/catalog/product/_id/ebc/components/ImageBlock" */))
const _517b2369 = () => interopDefault(import('../pages/product-catalog/catalog/product/_id/ebc/components/ImageModal.vue' /* webpackChunkName: "pages/product-catalog/catalog/product/_id/ebc/components/ImageModal" */))
const _7a519abe = () => interopDefault(import('../pages/product-catalog/catalog/product/_id/ebc/components/ImageSection.vue' /* webpackChunkName: "pages/product-catalog/catalog/product/_id/ebc/components/ImageSection" */))
const _46fbd417 = () => interopDefault(import('../pages/product-catalog/catalog/product/_id/ebc/components/SectionCollapser.vue' /* webpackChunkName: "pages/product-catalog/catalog/product/_id/ebc/components/SectionCollapser" */))
const _5612eb00 = () => interopDefault(import('../pages/product-catalog/catalog/product/_id/images/components/Image.vue' /* webpackChunkName: "pages/product-catalog/catalog/product/_id/images/components/Image" */))
const _75eef086 = () => interopDefault(import('../pages/product-catalog/catalog/product/_id/images/components/ImageModal.vue' /* webpackChunkName: "pages/product-catalog/catalog/product/_id/images/components/ImageModal" */))
const _755938d9 = () => interopDefault(import('../pages/product-catalog/catalog/product/_id/_part.vue' /* webpackChunkName: "pages/product-catalog/catalog/product/_id/_part" */))
const _1743618e = () => interopDefault(import('../pages/competitor-analysis/log/components/DateOrientedCellRenderer.vue' /* webpackChunkName: "pages/competitor-analysis/log/components/DateOrientedCellRenderer" */))
const _0854e464 = () => interopDefault(import('../pages/competitor-analysis/log/components/ImageCellRenderer.vue' /* webpackChunkName: "pages/competitor-analysis/log/components/ImageCellRenderer" */))
const _96bd46be = () => interopDefault(import('../pages/competitor-analysis/log/components/TableView.vue' /* webpackChunkName: "pages/competitor-analysis/log/components/TableView" */))
const _025f6ccb = () => interopDefault(import('../pages/competitor-analysis/log/components/TitleCellRenderer.vue' /* webpackChunkName: "pages/competitor-analysis/log/components/TitleCellRenderer" */))
const _2ef748ef = () => interopDefault(import('../pages/product-catalog/catalog/components/AsinRenderer.vue' /* webpackChunkName: "pages/product-catalog/catalog/components/AsinRenderer" */))
const _a534caa6 = () => interopDefault(import('../pages/product-catalog/catalog/components/ProductRenderer.vue' /* webpackChunkName: "pages/product-catalog/catalog/components/ProductRenderer" */))
const _0216c04a = () => interopDefault(import('../pages/product-catalog/catalog/components/StatusRenderer.vue' /* webpackChunkName: "pages/product-catalog/catalog/components/StatusRenderer" */))
const _1aeb412e = () => interopDefault(import('../pages/product-catalog/catalog/components/TableView.vue' /* webpackChunkName: "pages/product-catalog/catalog/components/TableView" */))
const _1b215afe = () => interopDefault(import('../pages/product-catalog/rating/components/AsinCellRenderer.vue' /* webpackChunkName: "pages/product-catalog/rating/components/AsinCellRenderer" */))
const _0fbdcebf = () => interopDefault(import('../pages/product-catalog/rating/components/ChartsCellRenderer.vue' /* webpackChunkName: "pages/product-catalog/rating/components/ChartsCellRenderer" */))
const _25fd3d79 = () => interopDefault(import('../pages/product-catalog/rating/components/HintHeader.vue' /* webpackChunkName: "pages/product-catalog/rating/components/HintHeader" */))
const _7ad0f0b8 = () => interopDefault(import('../pages/product-catalog/rating/components/LinkCellRenderer.vue' /* webpackChunkName: "pages/product-catalog/rating/components/LinkCellRenderer" */))
const _c9867eea = () => interopDefault(import('../pages/product-catalog/rating/components/ParentAsinCellRenderer.vue' /* webpackChunkName: "pages/product-catalog/rating/components/ParentAsinCellRenderer" */))
const _40857a1e = () => interopDefault(import('../pages/product-catalog/rating/components/TableView.vue' /* webpackChunkName: "pages/product-catalog/rating/components/TableView" */))
const _ede0b780 = () => interopDefault(import('../pages/product-passport/catalog-groups/components/CatalogItemsTable.vue' /* webpackChunkName: "pages/product-passport/catalog-groups/components/CatalogItemsTable" */))
const _7add8bfd = () => interopDefault(import('../pages/product-passport/catalog-items/components/AsinCellRenderer.vue' /* webpackChunkName: "pages/product-passport/catalog-items/components/AsinCellRenderer" */))
const _1e993497 = () => interopDefault(import('../pages/product-passport/catalog-items/components/CatalogGroupTable.vue' /* webpackChunkName: "pages/product-passport/catalog-items/components/CatalogGroupTable" */))
const _37381e2f = () => interopDefault(import('../pages/product-passport/catalog-items/components/CogCellRenderer.vue' /* webpackChunkName: "pages/product-passport/catalog-items/components/CogCellRenderer" */))
const _5b2e390a = () => interopDefault(import('../pages/product-passport/catalog-items/components/CogPanel.vue' /* webpackChunkName: "pages/product-passport/catalog-items/components/CogPanel" */))
const _3a709156 = () => interopDefault(import('../pages/product-passport/catalog-items/components/EditCatalogPanel.vue' /* webpackChunkName: "pages/product-passport/catalog-items/components/EditCatalogPanel" */))
const _1f3741a0 = () => interopDefault(import('../pages/product-passport/catalog-items/components/EditCellRenderer.vue' /* webpackChunkName: "pages/product-passport/catalog-items/components/EditCellRenderer" */))
const _39f9c9ae = () => interopDefault(import('../pages/product-passport/catalog-items/components/ListingsTable.vue' /* webpackChunkName: "pages/product-passport/catalog-items/components/ListingsTable" */))
const _18603d1a = () => interopDefault(import('../pages/product-passport/listings/components/AsinCellRenderer.vue' /* webpackChunkName: "pages/product-passport/listings/components/AsinCellRenderer" */))
const _0b87551c = () => interopDefault(import('../pages/product-passport/listings/components/CogCellRenderer.vue' /* webpackChunkName: "pages/product-passport/listings/components/CogCellRenderer" */))
const _302351b2 = () => interopDefault(import('../pages/product-passport/listings/components/CogPanel.vue' /* webpackChunkName: "pages/product-passport/listings/components/CogPanel" */))
const _e431df66 = () => interopDefault(import('../pages/product-passport/listings/components/EditCellRenderer.vue' /* webpackChunkName: "pages/product-passport/listings/components/EditCellRenderer" */))
const _74e17446 = () => interopDefault(import('../pages/product-passport/listings/components/IssuesTable.vue' /* webpackChunkName: "pages/product-passport/listings/components/IssuesTable" */))
const _7cea5b33 = () => interopDefault(import('../pages/product-passport/listings/components/ProductPanel.vue' /* webpackChunkName: "pages/product-passport/listings/components/ProductPanel" */))
const _0c656b60 = () => interopDefault(import('../pages/product-passport/parents/components/CreateParentPanel.vue' /* webpackChunkName: "pages/product-passport/parents/components/CreateParentPanel" */))
const _39de1526 = () => interopDefault(import('../pages/product-passport/parents/components/EditCellRenderer.vue' /* webpackChunkName: "pages/product-passport/parents/components/EditCellRenderer" */))
const _649aa3fc = () => interopDefault(import('../pages/product-passport/parents/components/EditParentPanel.vue' /* webpackChunkName: "pages/product-passport/parents/components/EditParentPanel" */))
const _62bb6d3c = () => interopDefault(import('../pages/product-passport/parents/components/ProductsTable.vue' /* webpackChunkName: "pages/product-passport/parents/components/ProductsTable" */))
const _2b0572b8 = () => interopDefault(import('../pages/product-passport/products/components/CatalogItemsTable.vue' /* webpackChunkName: "pages/product-passport/products/components/CatalogItemsTable" */))
const _140ed984 = () => interopDefault(import('../pages/product-passport/products/components/CreateProductPanel.vue' /* webpackChunkName: "pages/product-passport/products/components/CreateProductPanel" */))
const _5de33742 = () => interopDefault(import('../pages/product-passport/products/components/EditCellRenderer.vue' /* webpackChunkName: "pages/product-passport/products/components/EditCellRenderer" */))
const _285f0d52 = () => interopDefault(import('../pages/product-passport/products/components/EditProductPanel.vue' /* webpackChunkName: "pages/product-passport/products/components/EditProductPanel" */))
const _52fc0978 = () => interopDefault(import('../pages/product-catalog/rating/charts/components/ChartArea.vue' /* webpackChunkName: "pages/product-catalog/rating/charts/components/ChartArea" */))
const _0bac1c10 = () => interopDefault(import('../pages/product-catalog/rating/charts/components/ChartBar.vue' /* webpackChunkName: "pages/product-catalog/rating/charts/components/ChartBar" */))
const _fa0af988 = () => interopDefault(import('../pages/product-catalog/rating/charts/components/ChartCalendarDeltaRating.vue' /* webpackChunkName: "pages/product-catalog/rating/charts/components/ChartCalendarDeltaRating" */))
const _33eabd64 = () => interopDefault(import('../pages/product-catalog/rating/charts/components/ChartCalender1Star.vue' /* webpackChunkName: "pages/product-catalog/rating/charts/components/ChartCalender1Star" */))
const _7a4f104f = () => interopDefault(import('../pages/product-catalog/rating/charts/components/ChartCalender2Star.vue' /* webpackChunkName: "pages/product-catalog/rating/charts/components/ChartCalender2Star" */))
const _0e937f50 = () => interopDefault(import('../pages/product-catalog/rating/charts/components/ChartCalender3Star.vue' /* webpackChunkName: "pages/product-catalog/rating/charts/components/ChartCalender3Star" */))
const _ba50235e = () => interopDefault(import('../pages/product-catalog/rating/charts/components/ChartCalender4Star.vue' /* webpackChunkName: "pages/product-catalog/rating/charts/components/ChartCalender4Star" */))
const _371c5d52 = () => interopDefault(import('../pages/product-catalog/rating/charts/components/ChartCalender5Star.vue' /* webpackChunkName: "pages/product-catalog/rating/charts/components/ChartCalender5Star" */))
const _20b54e66 = () => interopDefault(import('../pages/product-catalog/rating/charts/components/ChartCalenderReviews.vue' /* webpackChunkName: "pages/product-catalog/rating/charts/components/ChartCalenderReviews" */))
const _ac771a9a = () => interopDefault(import('../pages/product-catalog/rating/charts/components/ChartCombo.vue' /* webpackChunkName: "pages/product-catalog/rating/charts/components/ChartCombo" */))
const _5bfcea8c = () => interopDefault(import('../pages/product-catalog/rating/charts/components/ChartStacked.vue' /* webpackChunkName: "pages/product-catalog/rating/charts/components/ChartStacked" */))
const _fc2a6a6c = () => interopDefault(import('../pages/product-catalog/rating/charts/components/DateRangePicker.vue' /* webpackChunkName: "pages/product-catalog/rating/charts/components/DateRangePicker" */))
const _5708b8f2 = () => interopDefault(import('../pages/product-catalog/rating/charts/components/ProductTableView.vue' /* webpackChunkName: "pages/product-catalog/rating/charts/components/ProductTableView" */))
const _27cc1f64 = () => interopDefault(import('../pages/product-catalog/rating/charts/_id.vue' /* webpackChunkName: "pages/product-catalog/rating/charts/_id" */))
const _c3ce32fc = () => interopDefault(import('../pages/product-passport/catalog-items/_id.vue' /* webpackChunkName: "pages/product-passport/catalog-items/_id" */))
const _7a8ade9f = () => interopDefault(import('../pages/product-passport/listings/_id.vue' /* webpackChunkName: "pages/product-passport/listings/_id" */))
const _9c2bb882 = () => interopDefault(import('../pages/product-passport/parents/_id.vue' /* webpackChunkName: "pages/product-passport/parents/_id" */))
const _181c5494 = () => interopDefault(import('../pages/product-passport/products/_id.vue' /* webpackChunkName: "pages/product-passport/products/_id" */))
const _6ccace3e = () => interopDefault(import('../pages/product-passport/catalog-groups/_id/_country.vue' /* webpackChunkName: "pages/product-passport/catalog-groups/_id/_country" */))
const _1048c9df = () => interopDefault(import('../pages/rank-tracker/_id/track-keywords.vue' /* webpackChunkName: "pages/rank-tracker/_id/track-keywords" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/conversion-rate-dashboard",
    component: _c13e5ff8,
    name: "conversion-rate-dashboard",
    children: [{
      path: ":country?",
      component: _bcadc99c,
      name: "conversion-rate-dashboard-country",
      children: [{
        path: ":product?",
        component: _18d1a49d,
        name: "conversion-rate-dashboard-country-product"
      }]
    }]
  }, {
    path: "/finance",
    component: _6580d463,
    name: "finance"
  }, {
    path: "/finance-charts",
    component: _6262cc12,
    name: "finance-charts"
  }, {
    path: "/inventory",
    component: _dbcdea3c,
    name: "inventory"
  }, {
    path: "/logistic-documents",
    component: _4bea8375,
    name: "logistic-documents"
  }, {
    path: "/negative-reviews",
    component: _00ec82ed,
    name: "negative-reviews"
  }, {
    path: "/pp-optimization",
    component: _8f5e3fc0,
    name: "pp-optimization",
    children: [{
      path: ":country?",
      component: _34592ad4,
      name: "pp-optimization-country",
      children: [{
        path: ":product?",
        component: _0aff348e,
        name: "pp-optimization-country-product"
      }]
    }]
  }, {
    path: "/product-alerts",
    component: _0c217f3c,
    name: "product-alerts"
  }, {
    path: "/products",
    component: _6a13e351,
    name: "products"
  }, {
    path: "/rank-tracker",
    component: _243e4fc4,
    name: "rank-tracker"
  }, {
    path: "/refunds",
    component: _327cfd81,
    name: "refunds"
  }, {
    path: "/review-charts",
    component: _274e9020,
    name: "review-charts"
  }, {
    path: "/shopify-price-control",
    component: _c9e494ca,
    name: "shopify-price-control"
  }, {
    path: "/tickets",
    component: _bdeee326,
    name: "tickets"
  }, {
    path: "/ui-kit",
    component: _935b796c,
    name: "ui-kit"
  }, {
    path: "/auth/login",
    component: _8e1272a8,
    name: "auth-login"
  }, {
    path: "/competitor-analysis/dashboard",
    component: _b551656a,
    name: "competitor-analysis-dashboard"
  }, {
    path: "/cx/complaints-manager",
    component: _0db48d68,
    name: "cx-complaints-manager",
    children: [{
      path: "components/DeleteCellRenderer",
      component: _2c730be2,
      name: "cx-complaints-manager-components-DeleteCellRenderer"
    }, {
      path: "components/EditCellRenderer",
      component: _8872067e,
      name: "cx-complaints-manager-components-EditCellRenderer"
    }, {
      path: "components/ModalAddComplaint",
      component: _0c62ef16,
      name: "cx-complaints-manager-components-ModalAddComplaint"
    }, {
      path: "components/ModalDeleteComplaint",
      component: _8840f9b6,
      name: "cx-complaints-manager-components-ModalDeleteComplaint"
    }, {
      path: "components/ModalEditComplaint",
      component: _98bd4734,
      name: "cx-complaints-manager-components-ModalEditComplaint"
    }]
  }, {
    path: "/cx/complaints-validation",
    component: _351a260e,
    name: "cx-complaints-validation",
    children: [{
      path: "Dashboard",
      component: _2ed9019a,
      name: "cx-complaints-validation-Dashboard"
    }, {
      path: "Overview",
      component: _60085aac,
      name: "cx-complaints-validation-Overview"
    }, {
      path: "Review",
      component: _1648c749,
      name: "cx-complaints-validation-Review"
    }, {
      path: "components/CountersCard",
      component: _db6aaac2,
      name: "cx-complaints-validation-components-CountersCard"
    }, {
      path: "components/Tab",
      component: _33b3e5cd,
      name: "cx-complaints-validation-components-Tab"
    }, {
      path: "components/CellRenderers/ActionCellRenderer",
      component: _0bdca314,
      name: "cx-complaints-validation-components-CellRenderers-ActionCellRenderer"
    }, {
      path: "components/CellRenderers/ComplaintCellRenderer",
      component: _fe7e01b2,
      name: "cx-complaints-validation-components-CellRenderers-ComplaintCellRenderer"
    }, {
      path: "components/CellRenderers/ReviewCellRenderer",
      component: _0b799836,
      name: "cx-complaints-validation-components-CellRenderers-ReviewCellRenderer"
    }, {
      path: "components/CellRenderers/SkuCellRenderer",
      component: _5fdc46d9,
      name: "cx-complaints-validation-components-CellRenderers-SkuCellRenderer"
    }, {
      path: "components/CellRenderers/SourceCellRenderer",
      component: _2e3f22f9,
      name: "cx-complaints-validation-components-CellRenderers-SourceCellRenderer"
    }, {
      path: "components/CellRenderers/StatusCellRenderer",
      component: _2b77fcd0,
      name: "cx-complaints-validation-components-CellRenderers-StatusCellRenderer"
    }, {
      path: ":id?",
      component: _79c6868e,
      name: "cx-complaints-validation-id"
    }]
  }, {
    path: "/cx/helicopter-view",
    component: _582553aa,
    name: "cx-helicopter-view",
    children: [{
      path: "components/CountryCharts",
      component: _f3aba574,
      name: "cx-helicopter-view-components-CountryCharts"
    }, {
      path: "components/FeedbackCharts",
      component: _218c458f,
      name: "cx-helicopter-view-components-FeedbackCharts"
    }, {
      path: "components/FeedbackType",
      component: _6adb9674,
      name: "cx-helicopter-view-components-FeedbackType"
    }, {
      path: "components/MonthDateFilter",
      component: _a63e523e,
      name: "cx-helicopter-view-components-MonthDateFilter"
    }, {
      path: "components/TableView",
      component: _379226ce,
      name: "cx-helicopter-view-components-TableView"
    }, {
      path: "components/TopProductsCharts",
      component: _5b19e649,
      name: "cx-helicopter-view-components-TopProductsCharts"
    }, {
      path: "components/charts/DoughnutChart",
      component: _28584017,
      name: "cx-helicopter-view-components-charts-DoughnutChart"
    }, {
      path: "components/charts/FeedbackTypeChart",
      component: _f53c4a4c,
      name: "cx-helicopter-view-components-charts-FeedbackTypeChart"
    }, {
      path: "components/charts/LinearChart",
      component: _1ed325d8,
      name: "cx-helicopter-view-components-charts-LinearChart"
    }, {
      path: "components/charts/ProgressBar",
      component: _fea4c4fe,
      name: "cx-helicopter-view-components-charts-ProgressBar"
    }]
  }, {
    path: "/cx/leader-board",
    component: _e3ee40fc,
    name: "cx-leader-board"
  }, {
    path: "/image-uploader/listing",
    component: _36086bff,
    name: "image-uploader-listing",
    children: [{
      path: "components/TableView",
      component: _2364bd7a,
      name: "image-uploader-listing-components-TableView"
    }, {
      path: "components/CellRenderer/ActionRenderer",
      component: _decdcf2c,
      name: "image-uploader-listing-components-CellRenderer-ActionRenderer"
    }, {
      path: "components/CellRenderer/CurrentRenderer",
      component: _3259d04a,
      name: "image-uploader-listing-components-CellRenderer-CurrentRenderer"
    }, {
      path: "components/CellRenderer/EditRenderer",
      component: _748fe47e,
      name: "image-uploader-listing-components-CellRenderer-EditRenderer"
    }, {
      path: "components/CellRenderer/ForceUpdateRenderer",
      component: _9f2b5c14,
      name: "image-uploader-listing-components-CellRenderer-ForceUpdateRenderer"
    }, {
      path: "components/CellRenderer/NicknameRenderer",
      component: _23e7773c,
      name: "image-uploader-listing-components-CellRenderer-NicknameRenderer"
    }, {
      path: "components/CellRenderer/SetCurrentRenderer",
      component: _9f7a3a2a,
      name: "image-uploader-listing-components-CellRenderer-SetCurrentRenderer"
    }, {
      path: "components/CellRenderer/UpdateRenderer",
      component: _235f8e7d,
      name: "image-uploader-listing-components-CellRenderer-UpdateRenderer"
    }, {
      path: "components/ModalWindow/ImageModal",
      component: _04e672ad,
      name: "image-uploader-listing-components-ModalWindow-ImageModal"
    }, {
      path: "components/ModalWindow/MonitorModal",
      component: _1653b8ce,
      name: "image-uploader-listing-components-ModalWindow-MonitorModal"
    }, {
      path: "components/ModalWindow/UpdateModal",
      component: _063f1159,
      name: "image-uploader-listing-components-ModalWindow-UpdateModal"
    }, {
      path: ":country?",
      component: _db3c6cd2,
      name: "image-uploader-listing-country",
      children: [{
        path: ":product?",
        component: _7a135050,
        name: "image-uploader-listing-country-product"
      }]
    }]
  }, {
    path: "/image-uploader/overview",
    component: _28d711e4,
    name: "image-uploader-overview",
    children: [{
      path: "components/GalleryCardItem",
      component: _6a7ac0ed,
      name: "image-uploader-overview-components-GalleryCardItem"
    }, {
      path: ":country?",
      component: _5b008130,
      name: "image-uploader-overview-country",
      children: [{
        path: ":product?",
        component: _0cb15d27,
        name: "image-uploader-overview-country-product"
      }]
    }]
  }, {
    path: "/its/dashboard",
    component: _b50ca01a,
    name: "its-dashboard"
  }, {
    path: "/its/local-shipments-tracker",
    component: _5e5033c1,
    name: "its-local-shipments-tracker"
  }, {
    path: "/its/po-tracker",
    component: _1713631b,
    name: "its-po-tracker",
    children: [{
      path: "direct",
      component: _a04b6ce6,
      name: "its-po-tracker-direct"
    }, {
      path: "reverse",
      component: _0a8b9e6e,
      name: "its-po-tracker-reverse"
    }, {
      path: "components/AsinTableCell",
      component: _59319704,
      name: "its-po-tracker-components-AsinTableCell"
    }, {
      path: "components/NicknameTableCell",
      component: _37ff7f4d,
      name: "its-po-tracker-components-NicknameTableCell"
    }, {
      path: "components/NicknameTableCellForReverse",
      component: _52a33286,
      name: "its-po-tracker-components-NicknameTableCellForReverse"
    }, {
      path: "components/PlainTextTableCell",
      component: _fe310b84,
      name: "its-po-tracker-components-PlainTextTableCell"
    }, {
      path: "components/ProductNicknameCell",
      component: _ed7f97ec,
      name: "its-po-tracker-components-ProductNicknameCell"
    }, {
      path: "components/StatusTableCell",
      component: _c483c1ee,
      name: "its-po-tracker-components-StatusTableCell"
    }]
  }, {
    path: "/its/shipments-tracker",
    component: _605e69e3,
    name: "its-shipments-tracker"
  }, {
    path: "/its/stock-control",
    component: _bfe60af6,
    name: "its-stock-control"
  }, {
    path: "/product-catalog/catalog",
    component: _412ec20e,
    name: "product-catalog-catalog"
  }, {
    path: "/product-catalog/rating",
    component: _04e7c71e,
    name: "product-catalog-rating"
  }, {
    path: "/product-passport/catalog-groups",
    component: _f42b8de0,
    name: "product-passport-catalog-groups"
  }, {
    path: "/product-passport/catalog-items",
    component: _a93b74cc,
    name: "product-passport-catalog-items"
  }, {
    path: "/product-passport/listings",
    component: _3336aa77,
    name: "product-passport-listings"
  }, {
    path: "/product-passport/parents",
    component: _0fd92797,
    name: "product-passport-parents"
  }, {
    path: "/product-passport/products",
    component: _9b7b11a8,
    name: "product-passport-products"
  }, {
    path: "/settings/general",
    component: _4ff4eee6,
    name: "settings-general"
  }, {
    path: "/settings/inventory",
    component: _674a63da,
    name: "settings-inventory"
  }, {
    path: "/settings/negative-reviews",
    component: _2c2ba2f5,
    name: "settings-negative-reviews"
  }, {
    path: "/settings/product-alerts",
    component: _10c6cf27,
    name: "settings-product-alerts"
  }, {
    path: "/settings/users",
    component: _2f598586,
    name: "settings-users"
  }, {
    path: "/ui-kit/icons-list",
    component: _67f746ae,
    name: "ui-kit-icons-list"
  }, {
    path: "/competitor-analysis/dashboard/details",
    component: _bc11ae84,
    name: "competitor-analysis-dashboard-details",
    children: [{
      path: "components/DateRangePicker",
      component: _7084cd25,
      name: "competitor-analysis-dashboard-details-index-components-DateRangePicker"
    }, {
      path: "components/InfoBtn",
      component: _62b72bd6,
      name: "competitor-analysis-dashboard-details-index-components-InfoBtn"
    }, {
      path: "components/ModalEditNote",
      component: _4ce17671,
      name: "competitor-analysis-dashboard-details-index-components-ModalEditNote"
    }, {
      path: "components/Tab",
      component: _093b2d1d,
      name: "competitor-analysis-dashboard-details-index-components-Tab"
    }, {
      path: ":country?",
      component: _37dc1890,
      name: "competitor-analysis-dashboard-details-index-country",
      children: [{
        path: "components/ChartView",
        component: _95f0011e,
        name: "competitor-analysis-dashboard-details-index-country-components-ChartView"
      }, {
        path: "components/TableView",
        component: _fded193e,
        name: "competitor-analysis-dashboard-details-index-country-components-TableView"
      }, {
        path: "components/CellRenderers/CategoryCellRenderer",
        component: _0e2b31c6,
        name: "competitor-analysis-dashboard-details-index-country-components-CellRenderers-CategoryCellRenderer"
      }, {
        path: "components/CellRenderers/ChangeCellRenderer",
        component: _18b10990,
        name: "competitor-analysis-dashboard-details-index-country-components-CellRenderers-ChangeCellRenderer"
      }, {
        path: "components/CellRenderers/LinkCellRenderer",
        component: _3e58eb7c,
        name: "competitor-analysis-dashboard-details-index-country-components-CellRenderers-LinkCellRenderer"
      }, {
        path: "components/CellRenderers/RankHeaderComponent",
        component: _17a8ebde,
        name: "competitor-analysis-dashboard-details-index-country-components-CellRenderers-RankHeaderComponent"
      }, {
        path: ":product?",
        component: _3d65fa52,
        name: "competitor-analysis-dashboard-details-index-country-product"
      }]
    }]
  }, {
    path: "/competitor-analysis/log/competitors",
    component: _fcd6a8e0,
    name: "competitor-analysis-log-competitors",
    children: [{
      path: ":asin?",
      component: _9fc2ce12,
      name: "competitor-analysis-log-competitors-asin"
    }]
  }, {
    path: "/competitor-analysis/log/uep",
    component: _fe88396e,
    name: "competitor-analysis-log-uep",
    children: [{
      path: ":country?",
      component: _61dee58d,
      name: "competitor-analysis-log-uep-country",
      children: [{
        path: ":product?",
        component: _e4cb5ebc,
        name: "competitor-analysis-log-uep-country-product"
      }]
    }]
  }, {
    path: "/product-catalog/catalog/product",
    component: _467eb6ab,
    name: "product-catalog-catalog-product",
    children: [{
      path: ":id?",
      component: _785db6d6,
      name: "product-catalog-catalog-product-id",
      children: [{
        path: "artworks",
        component: _06b745cb,
        name: "product-catalog-catalog-product-id-artworks"
      }, {
        path: "ebc",
        component: _6eda39f0,
        name: "product-catalog-catalog-product-id-ebc"
      }, {
        path: "images",
        component: _5542c644,
        name: "product-catalog-catalog-product-id-images"
      }, {
        path: "main-info",
        component: _7d177e6e,
        name: "product-catalog-catalog-product-id-main-info"
      }, {
        path: "artworks/ArtworkZone",
        component: _1004924e,
        name: "product-catalog-catalog-product-id-artworks-ArtworkZone"
      }, {
        path: "components/FormBuilder",
        component: _75ed6cb2,
        name: "product-catalog-catalog-product-id-components-FormBuilder"
      }, {
        path: "components/FormElement",
        component: _484fe088,
        name: "product-catalog-catalog-product-id-components-FormElement"
      }, {
        path: "components/FormHeader",
        component: _6d27a99e,
        name: "product-catalog-catalog-product-id-components-FormHeader"
      }, {
        path: "ebc/components/ImageBlock",
        component: _0426fd49,
        name: "product-catalog-catalog-product-id-ebc-components-ImageBlock"
      }, {
        path: "ebc/components/ImageModal",
        component: _517b2369,
        name: "product-catalog-catalog-product-id-ebc-components-ImageModal"
      }, {
        path: "ebc/components/ImageSection",
        component: _7a519abe,
        name: "product-catalog-catalog-product-id-ebc-components-ImageSection"
      }, {
        path: "ebc/components/SectionCollapser",
        component: _46fbd417,
        name: "product-catalog-catalog-product-id-ebc-components-SectionCollapser"
      }, {
        path: "images/components/Image",
        component: _5612eb00,
        name: "product-catalog-catalog-product-id-images-components-Image"
      }, {
        path: "images/components/ImageModal",
        component: _75eef086,
        name: "product-catalog-catalog-product-id-images-components-ImageModal"
      }, {
        path: ":part?",
        component: _755938d9,
        name: "product-catalog-catalog-product-id-part"
      }]
    }]
  }, {
    path: "/competitor-analysis/log/components/DateOrientedCellRenderer",
    component: _1743618e,
    name: "competitor-analysis-log-components-DateOrientedCellRenderer"
  }, {
    path: "/competitor-analysis/log/components/ImageCellRenderer",
    component: _0854e464,
    name: "competitor-analysis-log-components-ImageCellRenderer"
  }, {
    path: "/competitor-analysis/log/components/TableView",
    component: _96bd46be,
    name: "competitor-analysis-log-components-TableView"
  }, {
    path: "/competitor-analysis/log/components/TitleCellRenderer",
    component: _025f6ccb,
    name: "competitor-analysis-log-components-TitleCellRenderer"
  }, {
    path: "/product-catalog/catalog/components/AsinRenderer",
    component: _2ef748ef,
    name: "product-catalog-catalog-components-AsinRenderer"
  }, {
    path: "/product-catalog/catalog/components/ProductRenderer",
    component: _a534caa6,
    name: "product-catalog-catalog-components-ProductRenderer"
  }, {
    path: "/product-catalog/catalog/components/StatusRenderer",
    component: _0216c04a,
    name: "product-catalog-catalog-components-StatusRenderer"
  }, {
    path: "/product-catalog/catalog/components/TableView",
    component: _1aeb412e,
    name: "product-catalog-catalog-components-TableView"
  }, {
    path: "/product-catalog/rating/components/AsinCellRenderer",
    component: _1b215afe,
    name: "product-catalog-rating-components-AsinCellRenderer"
  }, {
    path: "/product-catalog/rating/components/ChartsCellRenderer",
    component: _0fbdcebf,
    name: "product-catalog-rating-components-ChartsCellRenderer"
  }, {
    path: "/product-catalog/rating/components/HintHeader",
    component: _25fd3d79,
    name: "product-catalog-rating-components-HintHeader"
  }, {
    path: "/product-catalog/rating/components/LinkCellRenderer",
    component: _7ad0f0b8,
    name: "product-catalog-rating-components-LinkCellRenderer"
  }, {
    path: "/product-catalog/rating/components/ParentAsinCellRenderer",
    component: _c9867eea,
    name: "product-catalog-rating-components-ParentAsinCellRenderer"
  }, {
    path: "/product-catalog/rating/components/TableView",
    component: _40857a1e,
    name: "product-catalog-rating-components-TableView"
  }, {
    path: "/product-passport/catalog-groups/components/CatalogItemsTable",
    component: _ede0b780,
    name: "product-passport-catalog-groups-components-CatalogItemsTable"
  }, {
    path: "/product-passport/catalog-items/components/AsinCellRenderer",
    component: _7add8bfd,
    name: "product-passport-catalog-items-components-AsinCellRenderer"
  }, {
    path: "/product-passport/catalog-items/components/CatalogGroupTable",
    component: _1e993497,
    name: "product-passport-catalog-items-components-CatalogGroupTable"
  }, {
    path: "/product-passport/catalog-items/components/CogCellRenderer",
    component: _37381e2f,
    name: "product-passport-catalog-items-components-CogCellRenderer"
  }, {
    path: "/product-passport/catalog-items/components/CogPanel",
    component: _5b2e390a,
    name: "product-passport-catalog-items-components-CogPanel"
  }, {
    path: "/product-passport/catalog-items/components/EditCatalogPanel",
    component: _3a709156,
    name: "product-passport-catalog-items-components-EditCatalogPanel"
  }, {
    path: "/product-passport/catalog-items/components/EditCellRenderer",
    component: _1f3741a0,
    name: "product-passport-catalog-items-components-EditCellRenderer"
  }, {
    path: "/product-passport/catalog-items/components/ListingsTable",
    component: _39f9c9ae,
    name: "product-passport-catalog-items-components-ListingsTable"
  }, {
    path: "/product-passport/listings/components/AsinCellRenderer",
    component: _18603d1a,
    name: "product-passport-listings-components-AsinCellRenderer"
  }, {
    path: "/product-passport/listings/components/CogCellRenderer",
    component: _0b87551c,
    name: "product-passport-listings-components-CogCellRenderer"
  }, {
    path: "/product-passport/listings/components/CogPanel",
    component: _302351b2,
    name: "product-passport-listings-components-CogPanel"
  }, {
    path: "/product-passport/listings/components/EditCellRenderer",
    component: _e431df66,
    name: "product-passport-listings-components-EditCellRenderer"
  }, {
    path: "/product-passport/listings/components/IssuesTable",
    component: _74e17446,
    name: "product-passport-listings-components-IssuesTable"
  }, {
    path: "/product-passport/listings/components/ProductPanel",
    component: _7cea5b33,
    name: "product-passport-listings-components-ProductPanel"
  }, {
    path: "/product-passport/parents/components/CreateParentPanel",
    component: _0c656b60,
    name: "product-passport-parents-components-CreateParentPanel"
  }, {
    path: "/product-passport/parents/components/EditCellRenderer",
    component: _39de1526,
    name: "product-passport-parents-components-EditCellRenderer"
  }, {
    path: "/product-passport/parents/components/EditParentPanel",
    component: _649aa3fc,
    name: "product-passport-parents-components-EditParentPanel"
  }, {
    path: "/product-passport/parents/components/ProductsTable",
    component: _62bb6d3c,
    name: "product-passport-parents-components-ProductsTable"
  }, {
    path: "/product-passport/products/components/CatalogItemsTable",
    component: _2b0572b8,
    name: "product-passport-products-components-CatalogItemsTable"
  }, {
    path: "/product-passport/products/components/CreateProductPanel",
    component: _140ed984,
    name: "product-passport-products-components-CreateProductPanel"
  }, {
    path: "/product-passport/products/components/EditCellRenderer",
    component: _5de33742,
    name: "product-passport-products-components-EditCellRenderer"
  }, {
    path: "/product-passport/products/components/EditProductPanel",
    component: _285f0d52,
    name: "product-passport-products-components-EditProductPanel"
  }, {
    path: "/product-catalog/rating/charts/components/ChartArea",
    component: _52fc0978,
    name: "product-catalog-rating-charts-components-ChartArea"
  }, {
    path: "/product-catalog/rating/charts/components/ChartBar",
    component: _0bac1c10,
    name: "product-catalog-rating-charts-components-ChartBar"
  }, {
    path: "/product-catalog/rating/charts/components/ChartCalendarDeltaRating",
    component: _fa0af988,
    name: "product-catalog-rating-charts-components-ChartCalendarDeltaRating"
  }, {
    path: "/product-catalog/rating/charts/components/ChartCalender1Star",
    component: _33eabd64,
    name: "product-catalog-rating-charts-components-ChartCalender1Star"
  }, {
    path: "/product-catalog/rating/charts/components/ChartCalender2Star",
    component: _7a4f104f,
    name: "product-catalog-rating-charts-components-ChartCalender2Star"
  }, {
    path: "/product-catalog/rating/charts/components/ChartCalender3Star",
    component: _0e937f50,
    name: "product-catalog-rating-charts-components-ChartCalender3Star"
  }, {
    path: "/product-catalog/rating/charts/components/ChartCalender4Star",
    component: _ba50235e,
    name: "product-catalog-rating-charts-components-ChartCalender4Star"
  }, {
    path: "/product-catalog/rating/charts/components/ChartCalender5Star",
    component: _371c5d52,
    name: "product-catalog-rating-charts-components-ChartCalender5Star"
  }, {
    path: "/product-catalog/rating/charts/components/ChartCalenderReviews",
    component: _20b54e66,
    name: "product-catalog-rating-charts-components-ChartCalenderReviews"
  }, {
    path: "/product-catalog/rating/charts/components/ChartCombo",
    component: _ac771a9a,
    name: "product-catalog-rating-charts-components-ChartCombo"
  }, {
    path: "/product-catalog/rating/charts/components/ChartStacked",
    component: _5bfcea8c,
    name: "product-catalog-rating-charts-components-ChartStacked"
  }, {
    path: "/product-catalog/rating/charts/components/DateRangePicker",
    component: _fc2a6a6c,
    name: "product-catalog-rating-charts-components-DateRangePicker"
  }, {
    path: "/product-catalog/rating/charts/components/ProductTableView",
    component: _5708b8f2,
    name: "product-catalog-rating-charts-components-ProductTableView"
  }, {
    path: "/product-catalog/rating/charts/:id",
    component: _27cc1f64,
    name: "product-catalog-rating-charts-id"
  }, {
    path: "/product-passport/catalog-items/:id?",
    component: _c3ce32fc,
    name: "product-passport-catalog-items-id"
  }, {
    path: "/product-passport/listings/:id?",
    component: _7a8ade9f,
    name: "product-passport-listings-id"
  }, {
    path: "/product-passport/parents/:id?",
    component: _9c2bb882,
    name: "product-passport-parents-id"
  }, {
    path: "/product-passport/products/:id?",
    component: _181c5494,
    name: "product-passport-products-id"
  }, {
    path: "/product-passport/catalog-groups/:id?/:country?",
    component: _6ccace3e,
    name: "product-passport-catalog-groups-id-country"
  }, {
    path: "/rank-tracker/:id?/track-keywords",
    component: _1048c9df,
    name: "rank-tracker-id-track-keywords"
  }, {
    path: "/",
    component: _2dfb1658,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config.app && config.app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
