//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'VCopiedValue',
  props: {
    value: {
      required: true,
      type: [String, Number],
    },
  },
  data() {
    return {
      isOpenedTooltip: false,
    }
  },
  methods: {
    copyValue(value) {
      navigator.clipboard.writeText(value)

      this.isOpenedTooltip = true

      setTimeout(() => {
        this.isOpenedTooltip = false
      }, '1200')
    },
  },
}
