//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VIcon from '~/components/ui/v-icon/VIcon'

export default {
  name: 'VRating',
  components: {
    VIcon,
  },
  props: {
    /**
     * Value of rating. Can be number or string with value like "3.5", "4" or "4/5" ("7/10" / "4.5/5").
     */
    rating: {
      required: true,
      type: [Number, String],
    },
    /**
     * Count of voices
     */
    count: {
      required: true,
      type: Number,
    },
  },
  computed: {
    /**
     * rating on a ten-point scale
     * @return {Number | String}
     */
    normalizedRating() {
      // value looks like "3.5" or "4"
      if (!isNaN(this.rating) && +this.rating >= 0 && +this.rating <= 5) {
        return Math.round(+this.rating * 2).toFixed(0)
      }
      // value looks like "2/5"
      const regexp = /((?:\d(?:\.\d+)?)+)\/((?:\d(?:\.\d+)?)+)/
      if (regexp.test(this.rating)) {
        const matches = regexp.exec(this.rating)
        return Math.round((+matches[1] / +matches[2]) * 10).toFixed(0)
      }
      return 0
    },
  },
  methods: {
    /**
     * Get icon's name for star by position
     * @param {number} position Position of star. equal 1 for first star (not zero for 1st)
     * @return {string}
     */
    getStarTypeByPosition(position) {
      // e.g. 1st star with normalizedRating >= 2
      if (+this.normalizedRating >= position * 2) {
        return 'star'
      } else if (+this.normalizedRating < position * 2) {
        // e.g. 3rd star with normalizedRating === 5 (position[3]*2=6)
        if (+this.normalizedRating === position * 2 - 1) {
          return 'half-star'
        }
        // e.g. 3rd star with normalizedRating < 5 (position[3]*2=6)
        return 'outline-star'
      }
      return 'outline-star'
    },
  },
}
