//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'VCollapse',
  model: {
    event: 'toggle', // Not implemented
    prop: 'value',
  },
  props: {
    value: {
      required: true,
      type: Boolean,
    },
  },
  data() {
    return {
      initClasses: this.value ? [] : ['v-collapse--collapsed'],
    }
  },
  mounted() {
    if (!this.value) {
      this.$refs.collapseContainer.classList.add('v-collapse--collapsed')
    }
  },
  methods: {
    /// Animation flow
    beforeEnter(el) {
      // collapsed state before enter transition start
      el.style.height = 0
      el.classList.remove('v-collapse--collapsed')
      this.initClasses = []
    },
    enter(el) {
      // set height equal to contents height
      el.style.height = getComputedStyle(this.$refs.content).height
    },
    afterEnter(el) {
      // reset height (height will be auto on expanded state)
      el.style.height = ''
    },
    leave(el) {
      // expanded state, ready to collapse: height will be fixed before leave transition start
      el.style.height = getComputedStyle(this.$refs.content).height

      // Force re-paint (in order to fix animation)
      getComputedStyle(el).height // eslint-disable-line no-unused-expressions

      // set height equal to zero ONLY AFTER beforeLeave hook make height fixed
      requestAnimationFrame(() => {
        el.style.height = 0
      })
    },
    afterLeave(el) {
      // Hide element (set height to 0 and display none)
      el.classList.add('v-collapse--collapsed')
    },
  },
}
