//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Logo from '@/components/layouts/Logo'
import VApp from '../components/layouts/app/VApp'
import VHeader from '../components/layouts/header/VHeader'
import VMain from '../components/layouts/main/VMain'
import VSpacer from '../components/ui/v-spacer/VSpacer'

export default {
  components: {
    Logo,
    VApp,
    VHeader,
    VMain,
    VSpacer,
  },
  created() {
    this.$store.commit('application/layout/setApplicationDrawerWidth', '0')
  },
}
