//
//
//
//
//
//
//
//
//

import Vue from 'vue'

export default Vue.extend({
  name: 'ProductField',
  props: {
    title: {
      required: true,
      type: String,
    },
    id: {
      required: true,
      type: Number,
    },
  },
})
