export default {
  setEditImageModal(state, value) {
    state.editImageModal = Object.assign(state.editImageModal, value)
  },
  hideEditImageModal(state) {
    state.editImageModal = Object.assign(state.editImageModal, {
      visible: false,
      pos: null,
      zone: null,
      selectedImageId: null,
      setImage: () => {},
    })
  },
  setProductId(state, value) {
    state.productId = value
  },
  setSetId(state, value) {
    state.setId = value
  },
}
