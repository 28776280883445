import Vue from 'vue'

/**
 * Determine if component rendering on server side or client side
 * isBooted - is component already rendering on client side
 */
export default Vue.extend({
  data() {
    return {
      isBooted: false as boolean,
    }
  },
  mounted() {
    // mounted hook will run only on client side
    this.isBooted = true
  },
})
