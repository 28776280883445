
export default {
  name: 'VIcon',
  props: {
    width: {
      required: false,
      type: [String, Number],
      default: '1em',
    },
    height: {
      required: false,
      type: [String, Number],
      default: '1em',
    },
    size: {
      required: false,
      type: [String, Number],
      default: null,
    },
    color: {
      required: false,
      type: String,
      default: null,
    },
    // folder with svg icons
    pack: {
      required: false,
      type: String,
      default: 'default',
    },
  },
  computed: {
    iconWidth() {
      if (this.iconSize != null) return this.iconSize
      if (!isNaN(this.width)) {
        return `${this.width}px`
      }
      return this.width
    },
    iconHeight() {
      if (this.iconSize != null) return this.iconSize
      if (!isNaN(this.height)) {
        return `${this.height}px`
      }
      return this.height
    },
    iconSize() {
      if (!isNaN(this.size) && this.size != null) {
        return `${this.size}px`
      }
      return this.size
    },
    classes() {
      const classes = {}
      const colorClass = this.getColorClass()
      if (colorClass !== null) {
        classes[colorClass] = true
      }
      return classes
    },
    style() {
      const style = {}
      const colorClass = this.getColorClass()
      if (colorClass === null && this.color) {
        style.color = this.color
      }
      return style
    },
  },
  methods: {
    getColorClass() {
      const colors = [
        'primary',
        'secondary',
        'success',
        'info',
        'warning',
        'danger',
        'btn-gray-content',
        'btn-gray-shade',
      ]

      if (!colors.includes(this.color)) {
        return null
      }

      return `text-${this.color}`
    },
  },
  render(h) {
    const slot = this.$slots.default
    if (slot.length === 0) {
      return null
    }
    const icon = slot[0].text.trim()
    if (typeof icon !== 'string') {
      return null
    }

    return h(
      'div',
      {
        class: {
          'v-icon': true,
          ...this.classes,
        },
        style: {
          width: this.iconWidth,
          height: this.iconHeight,
          ...this.style,
        },
      },
      [
        h('svg-icon', {
          props: {
            name: `${this.pack}/${icon}`,
          },
        }),
      ]
    )
  },
}
