
import Vue, { CreateElement, VNode, PropType } from 'vue'
import { VPopover } from 'v-tooltip'
import ssrBootableMixin from '@/mixins/ssrBootableMixin'
// @ts-ignore
import VBtn from '../v-btn/VBtn'
// @ts-ignore
import VCard from '../v-card/VCard'
// @ts-ignore
import VIcon from '../v-icon/VIcon'

/*  ***************************************************************
 *  * This component was created after VMenu created.             *
 *  * VMenu recommended to rewrite in future using this component *
 *  ***************************************************************
 */

export default Vue.extend({
  name: 'VPopup',
  mixins: [ssrBootableMixin],
  model: {
    prop: 'open',
    event: 'toggle',
  },
  props: {
    overlay: {
      required: false,
      type: Boolean,
      default: false,
    },
    minWidthByActivator: {
      required: false,
      type: Boolean,
      default: false,
    },
    /**
     * Popper.js v1 offset.
     * Note: If there're several values in the string,
     * the string MUST have values, separated by comma and optional space (e.g `, `)
     * @see https://popper.js.org/docs/v1/#modifiersoffset
     * @see https://github.com/Akryum/v-tooltip/blob/25b77b659a44e0c562b8da9843bd1a98084c63fc/src/components/Popover.vue#L108
     */
    customOffset: {
      required: false,
      type: [Number, String],
      default: undefined,
    },
    customOffsetMergeMode: {
      required: false,
      type: String as PropType<'override' | 'add'>,
      default: 'override',
    },
    /**
     * Custom width in px
     * Should be number or string like '10' / '10px'
     * NOTE: only pixels supported. If you use value like '5vw' / '4%',
     * only number will be used as pixels value ('5vw' -> '5px', '4%' -> '4px')
     */
    customWidth: {
      required: false,
      type: [Number, String],
      default: undefined,
    },
    customWidthMergeMode: {
      required: false,
      type: String as PropType<'override' | 'add'>,
      default: 'override',
    },
    /**
     * v-model value. Optional.
     * Changes and is changing by local `active` data variable.
     */
    open: {
      required: false,
      type: Boolean,
      default: false,
    },
    popoverClass: {
      required: false,
      type: [String, Array] as PropType<string | string[]>,
      default: null,
    },
    popoverWrapperClass: {
      required: false,
      type: [String, Array] as PropType<string | string[]>,
      default: null,
    },
    popoverArrowClass: {
      required: false,
      type: [String, Array] as PropType<string | string[]>,
      default: null,
    },
    popoverInnerClass: {
      required: false,
      type: [String, Array] as PropType<string | string[]>,
      default: null,
    },
    popoverPopperOptions: {
      required: false,
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      active: false as boolean | null,
      activatorHeight: 0 as number | string,
      activatorWidth: 0 as number | string,
    }
  },
  computed: {
    popupOffset(): number | string {
      let activatorHeightNumber: number
      if (!isNaN(this.activatorHeight as number)) {
        activatorHeightNumber = this.activatorHeight as number
      } else {
        activatorHeightNumber = parseFloat(this.activatorHeight as string)
      }

      if (!this.customOffset && this.customOffset !== 0) {
        if (!this.overlay) {
          return 0
        }
        return activatorHeightNumber * -1
      } else if (this.customOffsetMergeMode === 'add') {
        const partsOfCustomOffsets = (this.customOffset as string).split(
          /,\s*/g
        )
        if (partsOfCustomOffsets.length === 1) {
          partsOfCustomOffsets[0] += ` - ${activatorHeightNumber}px`
        } else if (partsOfCustomOffsets.length >= 2) {
          partsOfCustomOffsets[1] += ` - ${activatorHeightNumber}px`
          if (partsOfCustomOffsets.length === 4) {
            partsOfCustomOffsets[3] += ` - ${activatorHeightNumber}px`
          }
        }
        return partsOfCustomOffsets.join(', ')
      } else {
        if (this.customOffsetMergeMode !== 'override') {
          // eslint-disable-next-line no-console
          console.warn(
            'Wrong type of customOffsetMergeMode. Available values: "add", "override"'
          )
        }
        return this.customOffset
      }
    },
    popupCardMinWidth(): number {
      if (!this.minWidthByActivator) {
        return 0
      }
      if (!isNaN(this.activatorWidth as number)) {
        return this.activatorWidth as number
      }
      return parseFloat(this.activatorWidth as string)
    },
    popupCardWidth(): string {
      if (!this.customWidth) {
        return 'auto'
      } else if (this.customWidthMergeMode === 'add') {
        const minWidth: number = this.popupCardMinWidth
        let additionalWidthNum: number
        if (!isNaN(this.customWidth as number)) {
          additionalWidthNum = this.customWidth as number
        } else {
          additionalWidthNum = parseFloat(this.customWidth as string)
        }
        const finalWidth: number = minWidth + additionalWidthNum
        return `${finalWidth}px`
      } else {
        if (this.customWidthMergeMode !== 'override') {
          // eslint-disable-next-line no-console
          console.warn(
            'Wrong type of customOffsetMergeMode. Available values: "add", "override"'
          )
        }
        return typeof this.customWidth === 'number'
          ? `${this.customWidth}px`
          : this.customWidth
      }
    },
  },
  watch: {
    open: {
      handler(val, _oldVal) {
        if (val !== this.active) {
          this.active = val
        }
      },
    },
    active: {
      handler(val, _oldVal) {
        if (val !== this.open) {
          this.$emit('toggle', val)
        }
      },
    },
  },
  mounted() {
    this.updateActivatorSizeData()
  },
  methods: {
    toggle(value = null): void {
      if (value === null || typeof value !== 'boolean') {
        this.active = !this.active
      } else {
        this.active = value
      }
    },
    getPlacement(): string {
      return this.overlay ? 'top-start' : 'bottom-end'
    },

    updateActivatorSizeData() {
      const activatorWrapper = (this.$refs.popup as Vue).$el.querySelector(
        '.v-popup__activator-wrapper'
      )
      if (activatorWrapper) {
        const computedStylesOfActivator = getComputedStyle(activatorWrapper)
        this.activatorHeight = computedStylesOfActivator.height
        this.activatorWidth = computedStylesOfActivator.width
      }
    },

    onPopoverEvent(eventName: string, ...args: unknown[]) {
      this.$emit(`popover:${eventName}`, ...args)
    },
  },
  render(createElement: CreateElement): VNode {
    // Activator
    let activator
    if (this.$scopedSlots.activator) {
      const slotActivator = this.$scopedSlots.activator
      // @ts-ignore
      activator = slotActivator({
        toggle: this.toggle,
      })[0]
    } else {
      activator = createElement(
        VBtn,
        {
          class: {
            'v-popup-activator': true,
          },
          props: {
            iconContent: true,
            text: true,
            color: 'secondary',
            hoverColor: 'primary',
          },
          on: {
            click: () => {
              this.toggle()
            },
          },
          slot: 'default',
        },
        [createElement(VIcon, 'more')]
      )
    }

    // Default slot
    const defaultSlot = this.$scopedSlots.default
      ? this.$scopedSlots.default({
          toggle: this.toggle,
        })
      : this.$slots.default

    return createElement(
      VPopover,
      {
        // class: {
        //   'v-popup': true,
        // },
        props: {
          trigger: 'manual',
          open: this.active,
          placement: this.getPlacement(),
          popoverClass: ['v-popup', 'tooltip-no-arrows', [this.popoverClass]],
          popoverWrapperClass: [
            'wrapper',
            {
              // @ts-ignore
              'd-none': !this.isBooted,
            },
            [this.popoverWrapperClass],
          ],
          popoverArrowClass: this.popoverArrowClass,
          popoverInnerClass: this.popoverInnerClass,
          popperOptions: this.popoverPopperOptions,
          offset: this.popupOffset,
        },
        on: {
          show: (...args: any[]) => {
            this.onPopoverEvent('show', ...args)
          },
          'apply-show': (...args: any[]) => {
            this.onPopoverEvent('apply-show', ...args)
          },
          hide: (...args: any[]) => {
            this.onPopoverEvent('hide', ...args)
          },
          'apply-hide': (...args: any[]) => {
            this.onPopoverEvent('apply-hide', ...args)
          },
          dispose: (...args: any[]) => {
            this.onPopoverEvent('dispose', ...args)
          },
          'auto-hide': (...args: any[]) => {
            this.onPopoverEvent('auto-hide', ...args)
          },
          'close-directive': (...args: any[]) => {
            this.onPopoverEvent('close-directive', ...args)
          },
          'close-group': (...args: any[]) => {
            this.onPopoverEvent('close-group', ...args)
          },
          resize: (...args: any[]) => {
            this.onPopoverEvent('resize', ...args)
          },
          'update:open': this.toggle,
        },
        ref: 'popup',
        refInFor: false,
      },
      [
        // visible activator button
        createElement(
          'div',
          {
            class: {
              'v-popup__activator-wrapper': true,
            },
          },
          [activator]
        ),

        // popup items inside popover
        createElement(
          VCard,
          {
            slot: 'popover',
            class: {
              'v-popup__card': true,
            },
            style: {
              minWidth: this.popupCardMinWidth + 'px',
              width: this.popupCardWidth,
            },
          },
          [defaultSlot]
        ),
      ]
    )
  },
})
