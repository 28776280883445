import helpers from './helpers.js'

export default {
  setTableView(state, value) {
    state.tableView = value
    localStorage.lastPOTrackerTableView = value
  },
  setFetched(state, value) {
    state.fetched = value
  },
  setOrders(state, orders) {
    state.orders = orders
    state.ordersMap = helpers.getMap(orders)
  },
  setShipments(state, shipments) {
    state.shipments = shipments
    state.shipmentsMap = helpers.getMap(shipments)
  },
  setProducts(state, products) {
    state.products = products
    state.productsMap = helpers.getMap(products)
  },
  setProductParams(state, productParams) {
    state.withoutInboundProductParamsMap = helpers.getMap(
      productParams.map((value) => value.without_inbound),
      'product_id'
    )
    state.withInboundProductParamsMap = helpers.getMap(
      productParams.map((value) => value.with_inbound),
      'product_id'
    )
  },
  setPersons(state, persons) {
    state.persons = persons
    state.personsMap = helpers.getMap(persons)
  },
  setSuppliers(state, suppliers) {
    state.suppliers = suppliers
    state.suppliersMap = helpers.getMap(suppliers)
  },
  setItems(state, items) {
    state.items = items
    state.itemsMap = helpers.getMap(items)
  },
  setCountries(state, countries) {
    state.countries = countries
    state.countriesMap = helpers.getMap(countries)
  },
  setManufacturingStatuses(state, manufacturingStatuses) {
    state.manufacturingStatuses = manufacturingStatuses
    state.manufacturingStatusesMap = helpers.getMap(manufacturingStatuses)
  },
  setShippingStatuses(state, shippingStatuses) {
    state.shippingStatuses = shippingStatuses
    state.shippingStatusesMap = helpers.getMap(shippingStatuses)
  },
  setLocalShippingStatuses(state, localShippingStatuses) {
    state.localShippingStatuses = localShippingStatuses
    state.localShippingStatusesMap = helpers.getMap(localShippingStatuses)
  },
  setShippingMethods(state, shippingMethods) {
    state.shippingMethods = shippingMethods
    state.shippingMethodsMap = helpers.getMap(shippingMethods)
  },
  setShippingTypes(state, shippingTypes) {
    state.shippingTypes = shippingTypes
    state.shippingTypesMap = helpers.getMap(shippingTypes)
  },
  setWarehouses(state, warehouses) {
    state.warehouses = warehouses
    state.warehousesMap = helpers.getMap(warehouses)
  },
  setWarehousesStock(state, warehousesStock) {
    state.warehousesStock = warehousesStock
  },
  addToWarehouseStock(state, { warehouseId, productId, count }) {
    const stock = state.warehousesStock.find(
      (stockCur) =>
        stockCur.id === warehouseId && stockCur.product_id === productId
    )

    const warehouse = state.warehouses.find(
      (warehouse) => warehouse.id === warehouseId
    )

    if (warehouse.name === 'Amazon') return

    if (!stock) {
      const newStock = {
        id: warehouseId,
        product_id: productId,
        count,
        enroute: 0,
      }
      state.warehousesStock.splice(0, 0, newStock)
      helpers.linkWarehouseStock(state, state.warehousesStock[0])
      return
    }
    stock.count += count
  },
  linkData(state) {
    state.orders.forEach((order) => helpers.linkOrder(state, order))
    state.shipments.forEach((shipment) => helpers.linkShipment(state, shipment))
    state.products.forEach((product) => helpers.linkProduct(state, product))
    state.warehousesStock.forEach((warehouseStock) =>
      helpers.linkWarehouseStock(state, warehouseStock)
    )
    state.items.forEach((item) => helpers.linkItem(state, item))

    state.shipmentsHasWarehouse = state.shipments
      .filter((shipment) => shipment.status?.name !== 'Delivered')
      .every((shipment) => shipment.ship_to)
  },
  togglePOViewModal(state, order) {
    state.modalViewPO = order
  },
  togglePOEditModal(state, order) {
    state.modalEditPO = order
  },
  toggleShipmentViewModal(state, shipment) {
    state.modalViewShipment = shipment
  },
  toggleShipmentLocalViewModal(state, shipment) {
    state.modalViewLocalShipment = shipment
  },
  toggleShipmentEditModal(state, shipment) {
    state.modalEditShipment = shipment
  },
  toggleCreateShipment(state, bool) {
    state.modalCreateShipment = bool
  },
  toggleCreateLocalShipment(state, bool) {
    state.modalCreateLocalShipment = bool
  },
  toggleSplitShipmentModal(state, shipment) {
    state.modalSplitShipment = shipment
  },
  setCreateShipmentOnSave(state, onSave) {
    state.modalCreateShipmentOnSave = onSave
  },
  setCreateShipmentData(state, createShipmentData) {
    state.createShipmentData = createShipmentData
  },
  setCreateShipmentCallback(state, callback) {
    state.createShipmentCallback = callback
  },
  setEditShipmentCallback(state, callback) {
    state.editShipmentCallback = callback
  },
  setRecieveShipmentCallback(state, callback) {
    state.recieveShipmentCallback = callback
  },
  setCreateOrderCallback(state, callback) {
    state.createOrderCallback = callback
  },
  setEditOrderCallback(state, callback) {
    state.editOrderCallback = callback
  },
  setSplitShipmentCallback(state, callback) {
    state.splitShipmentCallback = callback
  },
  setCogCallback(state, callback) {
    state.cogCallback = callback
  },
  setSaveOrderRows(state, func) {
    state.saveOrderRows = func
  },
  setSaveShipmentRows(state, func) {
    state.saveShipmentRows = func
  },
  toggleCogWindow(state, item) {
    state.modalCogWindow = item
  },
  showEnroutePage(state, id) {
    state.modalEnroutePage = id
  },
  closeEnroutePage(state) {
    state.modalEnroutePage = null
  },
  toggleReceiveWindow(state, item) {
    state.modalReceiveWindow = item
  },
  editOrder(state, changeOrder) {
    const order = state.ordersMap.get(changeOrder.order_id)

    for (const [key, value] of Object.entries(changeOrder.edit_fields)) {
      order[key] = value
    }
    helpers.linkOrder(state, order)
  },
  updateOrder(state, rawOrder) {
    const order = state.ordersMap.get(rawOrder.id)

    Object.assign(order, rawOrder)
    helpers.linkOrder(state, order)
  },
  editShipment(state, changeShipment) {
    const shipment = state.shipmentsMap.get(changeShipment.id)

    for (const [key, value] of Object.entries(changeShipment)) {
      shipment[key] = value
    }
    helpers.linkShipment(state, shipment)
  },
  editItems(state, changeItems) {
    changeItems.forEach((changeItem) => {
      const item = state.itemsMap.get(changeItem.item_id)

      const oldShipment = item.shipment
      const oldProduct = item.product

      for (const [key, value] of Object.entries(changeItem.edit_fields)) {
        item[key] = value
      }
      helpers.linkItem(state, item)

      if (item.shipment !== oldShipment) {
        item.shipment && item.shipment.items_ids.push(item.id)
        const indexItemIdInOld =
          oldShipment && oldShipment.items_ids.indexOf(item.id)
        oldShipment && oldShipment.items_ids.splice(indexItemIdInOld, 1)

        item.shipment && helpers.linkShipment(state, item.shipment)
        oldShipment && helpers.linkShipment(state, oldShipment)
      }

      if (item.product !== oldProduct) {
        helpers.linkProduct(state, item.product)
        helpers.linkProduct(state, oldProduct)
      }
    })
  },
  addOrder(state, order) {
    state.orders.push(order)
    state.ordersMap.set(order.id, order)
    helpers.linkOrder(state, order)
  },
  addShipment(state, shipment) {
    state.shipments.push(shipment)
    state.shipmentsMap.set(shipment.id, shipment)
    helpers.linkShipment(state, shipment)
  },
  addItems(state, items) {
    items.forEach((item) => {
      helpers.linkItem(state, item)

      item.order.items_ids.push(item.id)
      item.order.items.push(item)

      item.product.items.push(item)

      item.shipment && item.shipment.items_ids.push(item.id)
      item.shipment && item.shipment.items.push(item)

      state.items.push(item)
      state.itemsMap.set(item.id, item)
    })
  },
  setReversePO(state, value) {
    state.reversePO = value
  },
  setDefaultCallbacks(state) {
    state.editShipmentCallback = () => {}
    state.createShipmentCallback = () => {}
    state.recieveShipmentCallback = () => {}
    state.createOrderCallback = () => {}
    state.editOrderCallback = () => {}
    state.splitShipmentCallback = () => {}
    state.cogCallback = () => {}
    state.modalCreateShipmentOnSave = () => {}
    state.saveOrderRows = () => {}
    state.saveShipmentRows = () => {}
  },
}
