//
//
//
//
//
//

export default {
  name: 'VCardContent',
  props: {
    background: {
      required: false,
      type: String,
      default: 'transparent',
    },
  },
}
