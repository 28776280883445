//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'RightPanel',
  props: {
    title: {
      require: false,
      default: null,
      type: String,
    },
    showRightPanel: {
      require: false,
      type: Boolean,
      default: false,
    },
    closeBtn: {
      require: false,
      type: Boolean,
      default: true,
    },
    width: {
      require: false,
      type: String,
      default: '600px',
    },
  },
}
