//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'
import VApp from '~/components/layouts/app/VApp'
import VHeader from '~/components/layouts/header/VHeader'
import VDrawer from '~/components/layouts/drawer/VDrawer'
import VDrawerLogo from '~/components/layouts/drawer/VDrawerLogo'
import VMain from '~/components/layouts/main/VMain'
import VBtn from '~/components/ui/v-btn/VBtn'
import VSpacer from '~/components/ui/v-spacer/VSpacer'
import VIcon from '~/components/ui/v-icon/VIcon'
import '@/plugins/vue-notification'
import '@/plugins/vue-cookies'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-alpine.css'

/* eslint-disable */
import 'ag-grid-enterprise'
import { LicenseManager } from 'ag-grid-enterprise'
LicenseManager.setLicenseKey(__NUXT__.config.AG_GRID_LICENSE)
/* eslint-enaable */

export default {
  components: {
    VApp,
    VHeader,
    VDrawer,
    VMain,
    VBtn,
    VSpacer,
    VIcon,
    VDrawerLogo,
  },
  data() {
    return {
      userEmail: localStorage.userEmail,
    }
  },
  created() {
    if (typeof document !== 'undefined') {
      this.loadPermissionStyles()
    }
    this.$axios.setHeader('X-uri', window.location.host)
    this.$store.dispatch('base/runTracker')
    this.$store.dispatch('base/setUserInfo')
    this.$store.dispatch('base/getCountries')
    // this.$sentry.setUser({ email: this.userEmail })

    /// eslint-disable
    console.log(
      `Build: ${this.$store.state.base.buildVersion}\nEnvirement: ${this.$store.state.base.buildEnv}\nBranch: ${this.$store.state.base.buildBranch}`
    )
    /// eslint-enable
  },
  computed: {
    ...mapState('base', ['userinfo']),
    userTitle() {
      const user = this.userinfo
      return user && `${user.first_name} ${user.last_name}`
    },
  },
  methods: {
    logout() {
      this.$auth.logout()
    },
    loadPermissionStyles() {
      const currentTimestamp = Date.now()
      this.$axios
        .get(
          `/api-v2/index.php?action=user_permission_styles&date=${currentTimestamp}`
        )
        .then((response) => {
          if (response.statusText === 'OK') {
            const text = response.data
            const styleNode = document.createElement('style')
            styleNode.type = 'text/css'

            if (styleNode.styleSheet) {
              styleNode.styleSheet.cssText = text
            } else {
              styleNode.appendChild(document.createTextNode(text))
            }

            document.head.append(styleNode)
          }
        })
    },
  },
}
