const middleware = {}

middleware['checkAuth'] = require('../middleware/checkAuth.js')
middleware['checkAuth'] = middleware['checkAuth'].default || middleware['checkAuth']

middleware['lastModuleInfo'] = require('../middleware/lastModuleInfo.js')
middleware['lastModuleInfo'] = middleware['lastModuleInfo'].default || middleware['lastModuleInfo']

middleware['tracking'] = require('../middleware/tracking.js')
middleware['tracking'] = middleware['tracking'].default || middleware['tracking']

export default middleware
