//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'VCheckbox',
  model: {
    event: 'change',
    prop: 'inputValue',
  },
  props: {
    groupCheckbox: {
      required: false,
      type: Boolean,
      default: false,
    },
    // Uses in v-model
    inputValue: {
      required: false,
      type: [String, Boolean], // string type -> 'some' - for group selects (this.groupCheckbox === true)
      default: null,
    },
    // value of radio. Required
    value: {
      required: true,
      type: [String, Boolean],
    },
    formStyle: {
      required: false,
      type: String,
      default: 'light',
    },
  },
  computed: {
    isActive() {
      return this.inputValue === this.value
    },
    selectionClasses() {
      return {
        'v-radio__selection--active': this.isActive,
        'v-radio__selection--dark': this.formStyle === 'dark',
        'v-radio__selection--middle': this.formStyle === 'middle',
        'v-radio__selection--white': this.formStyle === 'white',
      }
    },
  },
  methods: {
    onChange(event) {
      event.preventDefault()
      this.$emit('change', this.value)
      this.$refs.input.checked = this.isActive // fixes dom-element checked prop's value
    },
  },
}
