export default {
  linkItem(state, item) {
    item.product = state.productsMap.get(item.product_id)
    item.shipment = state.shipmentsMap.get(item.shipment_id)
    item.order = state.ordersMap.get(item.order_id)
    item.manufacturing_status = state.manufacturingStatusesMap.get(status.id)
    item.manufacturing_status = state.manufacturingStatusesMap.get(
      item.manufacturing_status_id
    )
  },
  linkOrder(state, order) {
    order.responsible_person = state.personsMap.get(order.responsible_person_id)
    order.country_shipment = state.countriesMap.get(order.country_shipment_id)
    order.supplier = state.suppliersMap.get(order.supplier_id)
    order.items = order.items_ids.map((id) => state.itemsMap.get(id))
  },
  linkShipment(state, shipment) {
    shipment.items = []
    shipment.method = state.shippingMethodsMap.get(shipment.method_id)
    shipment.ship_to = state.warehousesMap.get(shipment.ship_to_id)
    shipment.ship_from = state.warehousesMap.get(shipment.ship_from_id)
    shipment.type = state.shippingTypesMap.get(shipment.type_id)
    shipment.items = shipment.items_ids.map((id) => state.itemsMap.get(id))

    const isW2A = shipment.w2w && shipment.ship_to?.name === 'Amazon'
    shipment.status = isW2A
      ? state.localShippingStatusesMap.get(shipment.status_id)
      : state.shippingStatusesMap.get(shipment.status_id)
  },
  linkWarehouseStock(state, warehouseStock) {
    warehouseStock.product = state.productsMap.get(warehouseStock.product_id)
  },
  linkProduct(state, product) {
    product.items = state.items.filter((item) => item.product_id === product.id)
    product.with_inbound_params = state.withInboundProductParamsMap.get(
      product.id
    )
    product.without_inbound_params = state.withoutInboundProductParamsMap.get(
      product.id
    )
  },
  getMap(arr, key = 'id') {
    const map = new Map()
    arr.forEach((item) => {
      map.set(item[key], item)
    })
    return map
  },
}
