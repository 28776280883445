import { render, staticRenderFns } from "./default.vue?vue&type=template&id=bb619e9a&scoped=true&"
import script from "./default.vue?vue&type=script&lang=js&"
export * from "./default.vue?vue&type=script&lang=js&"
import style0 from "./default.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./default.vue?vue&type=style&index=1&id=bb619e9a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bb619e9a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {VDrawer: require('/app/components/layouts/drawer/VDrawer.vue').default,VDrawerLogo: require('/app/components/layouts/drawer/VDrawerLogo.vue').default,VSpacer: require('/app/components/ui/v-spacer/VSpacer.vue').default,VIcon: require('/app/components/ui/v-icon/VIcon.vue').default,VBtn: require('/app/components/ui/v-btn/VBtn.vue').default,VHeader: require('/app/components/layouts/header/VHeader.vue').default,VMain: require('/app/components/layouts/main/VMain.vue').default,VApp: require('/app/components/layouts/app/VApp.vue').default})
