//
//
//
//
//
//
//
//

import { mapState } from 'vuex'
import BreadcrumbsMixin from '@/mixins/BreadcrumbsMixin'

export default {
  name: 'VMain',
  mixins: [BreadcrumbsMixin],
  computed: {
    ...mapState('application/layout', ['headerHeight', 'drawerWidth']),
  },
}
