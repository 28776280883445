export default {
  getCompetitorRowData({ commit }, { competitorId }) {
    this.$axios
      .get(`api-v2/public/ratings/companalys.php?action=getAllComparedParams`, {
        params: {
          cclid: competitorId,
        },
      })
      .then((resolve) => {
        const rowData = resolve.data.map((row) => {
          row.date = new Date(row.date * 1000).toLocaleDateString('en-US')
          return row
        })
        commit('setCompetitorsRowData', rowData)
      })
  },
  getOurProductRelCompetitor({ commit }, { competitorId }) {
    this.$axios
      .get(
        'api-v2/public/ratings/companalys.php?action=getOurProductRelCompetitor',
        { params: { cclid: competitorId } }
      )
      .then((resolveOur) => {
        commit('setOurProducts', resolveOur.data)
      })
  },
  getProductBSR({ commit }, { competitorId }) {
    this.$axios
      .get('api-v2/public/ratings/companalys.php?action=getCompetitorBSR', {
        params: { cclid: competitorId },
      })
      .then((resolveBSR) => {
        commit('setCompetitorBSR', resolveBSR.data)
      })
  },
  getCompetitorsAsinList({ commit }) {
    return this.$axios
      .get('api-v2/public/ratings/companalys.php?action=getCompetitorsAsinList')
      .then((resolve) => {
        commit('setCompetitorsAsinList', resolve.data)
      })
  },
  getCountries({ commit }) {
    this.$axios
      .get('api-v2/public/ratings/companalys.php?action=getCountries')
      .then((res) => {
        commit('setCountries', res.data)
      })
  },
  getProducts({ commit }, { countryName }) {
    return this.$axios
      .get('api-v2/public/ratings/companalys.php?action=getProducts', {
        params: { country: countryName },
      })
      .then((res) => {
        commit('setProducts', res.data)
      })
  },
  getOurRowData({ commit }, { productId }) {
    return this.$axios
      .get(`api-v2/public/ratings/companalys.php?action=getAllComparedParams`, {
        params: { productId },
      })
      .then((res) => {
        const rowData = res.data.map((row) => {
          row.date = new Date(row.date * 1000).toLocaleDateString('en-US')
          return row
        })
        commit('setOurRowData', rowData)
      })
  },
  getProductData({ commit }, { productId }) {
    this.$axios
      .get('api-v2/public/ratings/companalys.php?action=getProductData', {
        params: {
          id: productId,
          startDate: Date.now() - 30 * 24 * 60 * 60 * 1000,
          endDate: Date.now(),
        },
      })
      .then((res) => {
        commit('setAsin', res.data.asin)
        commit('setImage', res.data.image)
        commit('setOurProductBSR', {
          categories: res.data.sn,
          bsr: res.data.sr,
        })
      })
  },
}
