import loadingMixin from '@/components/ui/loaders/loadingMixin'
import VPagination from '@/components/ui/tables/v-data-table/VPagination'
import VMenuItem from '@/components/ui/v-menu/VMenuItem'
import VMenu from '@/components/ui/v-menu/VMenu'
import VBtn from '@/components/ui/v-btn/VBtn'
import VIcon from '@/components/ui/v-icon/VIcon'

export default {
  mixins: [loadingMixin],
  props: {
    page: {
      required: false,
      type: Number,
      default() {
        return 1
      },
    },
    /**
     * Count of entities per page
     */
    perPage: {
      required: false,
      type: Number,
      default() {
        return 5
      },
    },
    /**
     * Options for dropdown
     */
    perPageOptions: {
      required: false,
      type: Array,
      default() {
        return [5, 10, 15, 25, 50, 100]
      },
    },
    /**
     * Count of all pages (with current perPage's value)
     */
    totalPages: {
      required: false,
      type: Number,
      default: null,
    },
    /**
     * Count of all entities (with current perPage's value)
     */
    totalItems: {
      required: false,
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      /**
       * Local data of pagination (currentPage, totalPages, total, perPage, perPageOptions)
       */
      meta: {
        currentPage: 1,
        totalPages: 1,
        total: 0,
        perPage: 10,
        perPageOptions: [5, 10, 15, 25, 50, 100],
      },
      currentPageWatchLocked: false, // lock emit update:page event when changing perPage
    }
  },
  watch: {
    'meta.perPage'(val, _oldVal) {
      this.currentPageWatchLocked = true
      setTimeout(() => {
        this.currentPageWatchLocked = false
      }, 150)
      this.meta.currentPage = 1
      /**
       * Changed perPage value
       */
      this.$emit('update:perPage', val)
    },
    perPage(val, _oldVal) {
      this.meta.perPage = val
    },
    'meta.currentPage'(val, _oldVal) {
      if (!this.currentPageWatchLocked) {
        /**
         * Changed current page
         */
        this.$emit('update:page', val)
      }
    },
    totalPages(val, _oldVal) {
      this.meta.totalPages = val
      if (this.meta.totalPages < 1) {
        this.meta.totalPages = 1
      }
    },
    totalItems(val, _oldVal) {
      this.meta.total = val
    },
  },
  created() {
    this.currentPageWatchLocked = true
    this.meta.currentPage = this.page
    this.currentPageWatchLocked = false

    this.meta.perPage = this.perPage
    this.meta.perPageOptions = this.perPageOptions
    if (this.totalPages !== null) {
      this.meta.totalPages = this.totalPages
      if (this.meta.totalPages < 1) {
        this.meta.totalPages = 1
      }
    }
    if (this.totalItems !== null) {
      this.meta.total = this.totalItems
    }
  },
  methods: {
    /**
     * @return VNode
     */
    genDataTablePagination() {
      return this.$createElement(VPagination, {
        props: {
          loading: this.loading,
          totalPages: this.meta.totalPages,
          currentPage: this.meta.currentPage,
        },
        on: {
          'page-click': (page) => {
            this.meta.currentPage = page
          },
        },
      })
    },

    /**
     * @return Array<VNode>
     */
    genPerPageDropdownItems() {
      const menuItems = []
      for (let i = 0; i < this.perPageOptions.length; i++) {
        const option = this.perPageOptions[i]
        menuItems.push(
          this.$createElement(
            VMenuItem,
            {
              on: {
                click: () => {
                  this.meta.perPage = option
                },
              },
            },
            [option]
          )
        )
      }
      return menuItems
    },
    /**
     * @return VNode
     * @param {boolean} forMobile apply classes for display only on mobile devices
     */
    genPerPageDropdown(forMobile = false) {
      return this.$createElement(
        VMenu,
        {
          scopedSlots: {
            activator: ({ toggle }) => {
              return [
                this.$createElement(
                  VBtn,
                  {
                    class: {
                      'dropdown-btn': true,
                      'd-none': !forMobile,
                      'd-md-block': !forMobile,
                      'd-md-none': forMobile,
                      'd-block': forMobile,
                    },
                    props: {
                      disabled: this.loading,
                    },
                    on: {
                      click: toggle,
                    },
                  },
                  [
                    this.meta.perPage,
                    this.$createElement(
                      VIcon,
                      {
                        props: {
                          pack: 'ui-icons',
                          size: '0.75em',
                        },
                      },
                      'selector-arrow'
                    ),
                  ]
                ),
                this.$createElement('div', 'Rows per pages'),
              ]
            },
          },
        },
        this.genPerPageDropdownItems()
      )
    },
  },
}
