//
//
//
//
//
//
//

import Vue from 'vue'
import { mapState } from 'vuex'
import { getUrlByShortname } from '@/utils/getMarketplaceURL'

export default Vue.extend({
  name: 'AsinRenderer',
  data() {
    return {
      productAsin: '',
    }
  },
  computed: {
    ...mapState('base', ['countries']),
    marketplaceURL() {
      if (!this.countries) {
        return null
      }
      return getUrlByShortname(this.countries, this.country, this.productAsin)
    },
  },
  beforeMount() {
    this.productAsin = this.params.data.asin
    this.country =
      this.params.data.country_shortname ||
      this.$route.params.country ||
      this.params.countryShortname ||
      this.params.data.countryShortname
  },
})
