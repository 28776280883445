//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'
import VCollapse from '../../ui/v-collapse/VCollapse'
import VCard from '../../ui/v-card/VCard'
import VCardContent from '../../ui/v-card/VCardContent'
import getSelectedNode from '~/utils/getSelectedNode'
import VIcon from '~/components/ui/v-icon/VIcon'

export default {
  name: 'VDrawerMenu',
  components: {
    VCollapse,
    VCard,
    VCardContent,
    VIcon,
  },
  data() {
    return {
      menu: {
        filter: true,
        items: [],
      },
      expandedNodes: {},
    }
  },
  computed: {
    compactMenuBoundariesElement() {
      if (process.server) {
        return undefined
      }
      return document.body
    },
    drawItems() {
      return this.menu.filter
        ? this.items.filter((item) => this.menu.items.includes(item.name))
        : this.items
    },
    items() {
      return [
        {
          name: 'product-passport',
          text: 'Product Passport',
          icon: 'list',
          group: true,
          items: [
            {
              type: 'br',
              text: 'Amazon data',
            },
            {
              name: 'product-passport-listings',
              to: { name: 'product-passport-listings' },
              text: 'Listings',
              icon: null,
            },
            {
              name: 'product-passport-catalog-items',
              to: { name: 'product-passport-catalog-items' },
              text: 'Catalog Items',
              icon: null,
            },
            {
              name: 'product-passport-catalog-groups',
              to: { name: 'product-passport-catalog-groups' },
              text: 'Catalog Groups',
              icon: null,
            },
            {
              type: 'br',
              text: 'Business data',
            },
            {
              name: 'product-passport-products',
              to: { name: 'product-passport-products' },
              text: 'Products',
              icon: null,
            },
            {
              name: 'product-passport-parents',
              to: { name: 'product-passport-parents' },
              text: 'Parents',
              icon: null,
            },
            {
              name: 'product-passport-brands',
              to: { name: 'product-passport-brands' },
              text: 'Brands',
              icon: null,
              disabled: true,
            },
          ],
        },
        {
          name: 'finance',
          to: { name: 'finance' },
          text: 'Finance',
          icon: 'finance',
        },
        {
          name: 'inventory',
          to: { name: 'inventory' },
          text: 'Inventory',
          icon: 'inventory',
        },
        {
          name: 'products',
          to: { name: 'products' },
          text: 'Products',
          icon: 'list',
        },
        {
          name: 'product-catalog-catalog',
          to: { name: 'product-catalog-catalog' },
          text: 'Product Catalog',
          icon: 'product-view',
        },
        {
          name: 'product-catalog-rating',
          to: { name: 'product-catalog-rating' },
          text: 'Rating',
          icon: 'trending',
        },
        {
          name: 'product-alerts',
          to: { name: 'product-alerts' },
          text: 'Product Alerts',
          icon: 'tasks',
        },
        {
          name: 'cx',
          text: 'CX',
          icon: 'cx',
          group: true,
          items: [
            {
              name: 'cx-complaints-validation',
              to: { name: 'cx-complaints-validation' },
              text: 'Complaints validation',
              icon: null,
            },
            {
              name: 'cx-helicopter-view',
              to: { name: 'cx-helicopter-view' },
              text: 'Helicopter view',
              icon: null,
            },
            {
              name: 'cx-complaints-manager',
              to: { name: 'cx-complaints-manager' },
              text: 'Complaints manager',
              icon: null,
            },
            {
              name: 'cx-leader-board',
              to: { name: 'cx-leader-board' },
              text: 'Leader board',
              icon: null,
            },
          ],
        },
        {
          name: 'conversion-rate-dashboard',
          to: { name: 'conversion-rate-dashboard' },
          text: 'Conversion Rate Dashboard',
          icon: 'analytics',
        },
        {
          name: 'competitor-analysis',
          text: 'Competitor Analysis',
          icon: 'product-return',
          group: true,
          items: [
            {
              name: 'competitor-analysis-dashboard',
              to: { name: 'competitor-analysis-dashboard' },
              text: 'Dashboard',
              icon: null,
            },
            {
              name: 'competitor-analysis-log-uep',
              to: { name: 'competitor-analysis-log-uep' },
              text: 'Event log',
              icon: null,
            },
          ],
        },
        {
          name: 'refunds',
          to: { name: 'refunds' },
          text: 'Refunds',
          icon: 'product-return',
        },
        {
          name: 'pp-optimization',
          to: { name: 'pp-optimization' },
          text: 'PP Optimization',
          icon: 'package',
        },
        {
          name: 'image-uploader',
          text: 'Image Uploader',
          icon: 'add-new-item',
          group: true,
          items: [
            {
              name: 'overview',
              to: { name: 'image-uploader-overview' },
              text: 'Overview',
              icon: null,
            },
            {
              name: 'listing',
              to: { name: 'image-uploader-listing' },
              text: 'Listing',
              icon: null,
            },
          ],
        },
        {
          name: 'its',
          text: 'ITS',
          icon: 'shop',
          group: true,
          items: [
            {
              name: 'dashboard',
              to: { name: 'its-dashboard' },
              text: 'Dashboard',
              icon: null,
            },
            {
              name: 'po-tracker',
              to: {
                name: `its-po-tracker-` + this.tableView,
              },
              text: 'PO Tracker',
              icon: null,
            },
            {
              name: 'its-shipments-tracker',
              to: { name: 'its-shipments-tracker' },
              text: 'Shipments tracker',
              icon: null,
            },
            {
              name: 'its-local-shipments-tracker',
              to: { name: 'its-local-shipments-tracker' },
              text: 'Local shipments tracker',
              icon: null,
            },
            {
              name: 'its-stock-control',
              to: { name: 'its-stock-control' },
              text: 'Stock control',
              icon: null,
            },
          ],
        },
        {
          name: 'finance-charts',
          to: { name: 'finance-charts' },
          text: 'Finance charts',
          icon: 'analytics',
        },
        {
          name: 'review-charts',
          to: { name: 'review-charts' },
          text: 'Review charts',
          icon: 'review',
        },
        {
          name: 'rank-tracker',
          to: { name: 'rank-tracker' },
          text: 'Rank tracker',
          icon: 'key',
        },
        {
          name: 'tickets',
          to: { name: 'tickets' },
          text: 'Tickets',
          icon: 'note',
        },
        {
          name: 'shopify-price-control',
          to: { name: 'shopify-price-control' },
          text: 'Shopify Price Control',
          icon: 'shopping-bag',
        },
        {
          name: 'settings',
          text: 'Settings',
          icon: 'cog',
          group: true,
          items: [
            {
              name: 'settings-general',
              to: { name: 'settings-general' },
              text: 'General',
              icon: null,
            },
            {
              name: 'settings-inventory',
              to: { name: 'settings-inventory' },
              text: 'Inventory',
              icon: null,
            },
            {
              name: 'settings-users',
              to: { name: 'settings-users' },
              text: 'Users',
              icon: null,
            },
            {
              name: 'settings-product-alerts',
              to: { name: 'settings-product-alerts' },
              text: 'Product Alerts',
              icon: null,
            },
            {
              name: 'settings-negative-reviews',
              to: { name: 'settings-negative-reviews' },
              text: 'Negative Reviews',
              icon: null,
            },
          ],
        },
      ]
    },
    collapsedMenu() {
      return this.drawerState === 'collapsed'
    },
    selectedNode() {
      const leaf = getSelectedNode(this.$route, this.items)
      const node =
        leaf &&
        this.items
          .filter((item) => item.group)
          .find((item) =>
            item.items.map((citem) => citem.name).includes(leaf.name)
          )
      return node
    },
    ...mapState('application/layout', ['drawerState']),
    ...mapState('its', ['tableView']),
  },
  watch: {
    collapsedMenu(value) {
      if (value) {
        this.expandedNodes = {}
      } else {
        setTimeout(this.expandSelectedNode, 100)
      }
    },
    selectedNode(item) {
      if (item?.group && !this.expandedNodes[item.name]) {
        this.expandedNodes[item.name] = true
      }
    },
  },
  created() {
    this.$axios.get('/api-v2/access.php').then((res) => {
      if (res) {
        this.menu.items = res.data.items
        const showFilteredMenu = localStorage.showFilteredMenu
          ? JSON.parse(localStorage.showFilteredMenu)
          : null
        this.menu.filter =
          showFilteredMenu !== null ? showFilteredMenu : res.data.filter
      }
    })
    !this.collapsedMenu && this.expandSelectedNode()
    localStorage.menuItems = JSON.stringify(this.items)
  },
  methods: {
    expandSelectedNode() {
      this.selectedNode && this.toggleExpand(this.selectedNode, true)
    },
    toggleExpand(item, value = !this.expandedNodes[item.name]) {
      this.$set(this.expandedNodes, item.name, value)
    },
  },
}
