//
//
//
//
//
//
//
//
//
//
//
//

import Vue from 'vue'

export default Vue.extend({
  name: 'CrtField',
  props: {
    title: {
      required: true,
      type: String,
    },
    asin: {
      required: true,
      type: String,
    },
    country: {
      required: true,
      type: String,
    },
  },
})
