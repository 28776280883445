
import Vue, { VNode } from 'vue'
import { ScopedSlotChildren } from 'vue/types/vnode'
// @ts-ignore
import VIcon from '@/components/ui/v-icon/VIcon'

export default Vue.extend({
  name: 'VWidgetsContainer',
  methods: {
    /// render methods
    genDefaultSlot(): VNode | VNode[] | ScopedSlotChildren | null {
      if (!this.$scopedSlots.default) {
        return null
      }
      return this.$scopedSlots.default({})
    },

    genMobileArrowBlock(direction: string): VNode {
      return this.$createElement(
        'div',
        {
          class: {
            'd-flex': true,
            'd-md-none': true,
            'align-items-center': true,
          },
          on: {
            click: (_event: MouseEvent) => {
              let scrollDistance = parseInt(
                getComputedStyle(this.$refs.wrapper as Element).width.replace(
                  /D+/,
                  ''
                )
              )
              if (direction === 'left') {
                scrollDistance *= -1
              }

              let scrollLeft =
                (this.$refs.wrapper as Element).scrollLeft + scrollDistance
              const offset = scrollLeft % scrollDistance
              if (offset < 0) {
                scrollLeft = scrollLeft + (scrollDistance + offset)
              } else {
                scrollLeft = scrollLeft - offset
                if (Math.abs(offset) > Math.abs(scrollDistance) / 2) {
                  scrollLeft += Math.abs(scrollDistance)
                }
              }

              ;(this.$refs.wrapper as Element).scrollTo({
                top: 0,
                left: scrollLeft,
                behavior: 'smooth',
              })
            },
          },
        },
        [
          this.$createElement(
            VIcon,
            {
              props: {
                size: '1rem',
                color: 'primary',
              },
            },
            direction === 'left' ? 'arrow-left' : 'arrow-right'
          ),
        ]
      )
    },

    genWidgetsWrapper(): VNode {
      return this.$createElement(
        'div',
        {
          class: {
            'v-widgets-container__wrapper': true,
            row: true,
            'flex-nowrap': true,
          },
          ref: 'wrapper',
          refInFor: false,
        },
        [this.genDefaultSlot()]
      )
    },
  },
  render(h): VNode {
    return h(
      'div',
      {
        class: {
          'v-widgets-container': true,
        },
      },
      [
        this.genMobileArrowBlock('left'),
        this.genWidgetsWrapper(),
        this.genMobileArrowBlock('right'),
      ]
    )
  },
})
