import Vue from 'vue'
import VCalendar from 'v-calendar'

// Use v-calendar & v-date-picker components
// Theme settings: https://github.com/nathanreyes/v-calendar/blob/07e676e0ee5e08f5c10addabd85decb7aaa3b313/src/utils/defaults/theme.js (v1.0.8)
Vue.use(VCalendar, {
  theme: {
    color: 'primary',
    datePickerInput:
      'v-input__form-control vc-appearance-none vc-text-base vc-text-gray-800 vc-bg-white vc-border vc-border-gray-400 vc-rounded vc-w-full vc-py-2 vc-px-3 vc-leading-tight focus:vc-outline-none focus:vc-shadow',
    // weekdays: {
    //   light: 'vc-text-sm vc-font-bold vc-text-gray-500',
    // },
    navTitle: {
      light:
        'text-{color}-lighten-5 vc-font-bold vc-leading-snug vc-px-2 vc-py-1 vc-rounded vc-border-2 vc-border-transparent hover:vc-bg-gray-900 focus:border-{color}',
    },
    navArrows: {
      light:
        'vc-leading-snug vc-rounded vc-border-2 vc-border-transparent hover:vc-bg-gray-900 focus:border-{color}',
    },
    navCell: {
      light:
        'vc-w-12 vc-font-semibold vc-cursor-pointer vc-text-center vc-leading-snug vc-py-1 vc-rounded vc-border-2 vc-border-transparent hover:vc-bg-gray-900 hover:vc-shadow-inner hover:vc-text-white focus:border-{color}',
    },
    navCellInactiveCurrent: {
      light: 'text-{color}-lighten-5 vc-font-bold border-{color}-lighten-5',
    },
    navCellActive: {
      light:
        'bg-{color}-lighten-5 text-{color}-darken-4 vc-border-transparent vc-font-bold vc-shadow',
    },
    bgLow: {
      light: 'vc-bg-white vc-border-2 border-{color}-darken-1',
    },
    bgAccentLow: {
      light: 'bg-{color}-lighten-4',
    },
    bgAccentHigh: {
      light: 'bg-{color}',
    },
    contentAccent: {
      light: 'vc-font-bold text-{color}-darken-4',
    },
  },
  masks: {
    title: 'MMMM YYYY',
    weekdays: 'W',
    navMonths: 'MMM',
    // input: ['L', 'YYYY-MM-DD', 'YYYY/MM/DD'],
    input: ['MM/DD/YYYY'],
    dayPopover: 'WWW, MMM D, YYYY',
    // data: ['L', 'YYYY-MM-DD', 'YYYY/MM/DD'],
    data: ['MM/DD/YYYY'],
  },
  locale: 'en-US',
})
