//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Vue from 'vue'
import VDateInput from '@/components/ui/v-date-input/VDateInput'

export default Vue.extend({
  name: 'CustomDateFilter',
  components: {
    VDateInput,
  },
  data() {
    return {
      date: null,
    }
  },
  computed: {
    localDate() {
      return this.date
    },
  },
  mounted() {
    this.datepicker = this.$refs.datepicker
  },
  methods: {
    inputDate(date) {
      this.date = date
      this.onDateChanged([date])
    },
    onDateChanged(selectedDates) {
      this.date = selectedDates[0] || null
      this.params.onDateChanged()
    },

    getDate() {
      return this.date
    },

    setDate(date) {
      this.date = date || null
    },

    setInputPlaceholder() {},

    setInputAriaLabel() {},
  },
})
