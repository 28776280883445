// 'product-catalog-catalog': 'product-catalog' - modeule, 'catalog' sectioin
// 'product-catalog-catalog-product-id-part': 'product-catalog' - modeule, 'catalog' sectioin, 'product-id-part' - trash

// 'product-catalog-catalog'.split('product-catalog-catalog') // ['',''] +
// 'product-catalog-ratings'.split('product-catalog-catalog') // ['product-catalog-ratings'] -
// 'product-catalog-catalog-product-id-part'.split('product-catalog-catalog') // ['','-product-id-part'] +
// 'product-catalog-cataloging-product-id-part'.split('product-catalog-catalog') // ['','ing-product-id-part'] -

const compareNames = (currentName, itemName) => {
  const split = currentName.split(itemName)
  return split[0] === '' && (split[1] === '' || split[1][0] === '-')
}

export default (route, items) => {
  const name = route.name

  const modules = items.filter((item) => item && !item.group)
  const groups = items
    .filter((item) => item.group)
    .map((item) => item.items)
    .flat()

  const allModules = [...modules, ...groups]
  const lastModule = allModules.find((item) =>
    compareNames(name, item.to?.name)
  )

  return lastModule
}
