export default {
  data() {
    return {
      /**
       * Array of headers (objects from `headers` prop) that must be shown
       */
      visibleHeaders: [],
    }
  },
  watch: {
    headers: {
      handler(val, _oldVal) {
        this.visibleHeaders = this.initVisibleHeaders(val)

        const modalConfigurationSettingsString = window.localStorage.getItem(
          this.getPath() + '.settings'
        )
        const modalConfigurationSettings = JSON.parse(
          modalConfigurationSettingsString
        )
        if (modalConfigurationSettings !== null) {
          this.visibleHeaders = modalConfigurationSettings
        }
      },
      immediate: true,
    },
  },
  methods: {
    getPath() {
      const version = 'default'
      return `${this.$route.name}.${this.$store.state.base.buildVersion}.${version}`
    },
    initVisibleHeaders(headers) {
      const visibleHeaders = {}
      for (let i = 0; i < headers.length; i++) {
        visibleHeaders[headers[i].value] = true
      }
      return visibleHeaders
    },
  },
}
