//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VMenuItem from '../v-menu/VMenuItem'
import VInput from '../v-input/VInput'
import VMenu from '../v-menu/VMenu'
import VIcon from '../v-icon/VIcon'
import VBtn from '../v-btn/VBtn'

export default {
  name: 'VFileInput',
  components: {
    VMenuItem,
    VMenu,
    VInput,
    VIcon,
    VBtn,
  },
  model: {
    event: 'file-input',
    prop: 'value',
  },
  props: {
    value: {
      required: false,
      type: Object,
      default: null,
    },
    preset: {
      required: false,
      type: String,
      default: 'default',
    },
    btnText: {
      required: false,
      type: String,
      default: null,
    },
    label: {
      required: false,
      type: String,
      default: null,
    },
    noLabel: {
      required: false,
      type: Boolean,
      default: false,
    },
    hideDetails: {
      required: false,
      type: Boolean,
      default: false,
    },
    error: {
      required: false,
      type: Boolean,
      default: false,
    },
    errorMessage: {
      required: false,
      type: String,
      default: '',
    },
    placeholder: {
      required: false,
      type: String,
      default: null,
    },
    multiple: {
      required: false,
      type: Boolean,
      default: false,
    },
    disabled: {
      required: false,
      type: Boolean,
      default: false,
    },
    persistentHint: {
      required: false,
      type: Boolean,
      default: false,
    },
    hint: {
      required: false,
      type: String,
      default: '',
    },
    filenameLengthLimit: {
      required: false,
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      focused: false,
      generatedId: 'v-file-input-' + Math.random().toString(36).substring(7),
    }
  },
  computed: {
    clippedValueString() {
      // file is empty
      if (this.value.value === null || this.value.value === undefined) {
        return ''
      }

      // unlimited file name
      if (
        this.filenameLengthLimit === 0 ||
        this.filenameLengthLimit === null ||
        this.filenameLengthLimit === undefined
      ) {
        return this.value.value
      }

      // limit file name if needed
      if (this.value.value.length <= this.filenameLengthLimit) {
        return this.value.value
      } else {
        const parts = this.value.value.split('.')
        const extension = parts.splice(parts.length - 1, 1)
        let filename = parts.join('') // if filename contains dots
        const filenameLeftLength =
          this.filenameLengthLimit - (extension.length + 1) // ext with leading dot
        if (filename.length > filenameLeftLength) {
          filename = filename.substring(0, filenameLeftLength).trim() + '... '
        }
        return `${filename}.${extension}`
      }
    },
    selectFileButtonText() {
      if (this.btnText === null) {
        switch (this.preset) {
          case 'with-button':
            return 'Select File'
          case 'with-button-revocable':
            return 'Upload File'
        }
        return 'Select File'
      }
      return this.btnText
    },
    internalValue: {
      /**
       * Return FileList with all current files
       * @return {FileList}
       */
      get() {
        return this.$refs.fileInput.files
      },
      /**
       * Set new file(s) and emit 'file-input' event (for changing text on input/button
       * Can set empty array, null or undefined to reset file input. NOTE: file input's value WILL NOT BE RESET
       * @param {FileList|File[]|File|Array|null|undefined} files
       */
      set(files) {
        this.$emit('file-input', {
          value: this.getFileNames(files),
          files: files || [],
        })
      },
    },
  },
  methods: {
    /**
     * Generate file name / files' names string or null if files list is empty
     * @param files
     * @return {null|string}
     */
    getFileNames(files) {
      if (files === null || files === undefined || files.length === 0) {
        return null
      }
      return files.length > 1
        ? Array.from(files)
            .map(function (file) {
              return file.name
            })
            .join(', ')
        : files[0].name
    },
    focusOnInput() {
      this.$refs.fileInput.focus()
    },
    onChange(event) {
      this.internalValue = event.target.files
    },
    changeFile() {
      this.$refs.fileInput.click()
    },
    /**
     * Delete file and clear file input value
     */
    deleteFile() {
      this.$refs.fileInput.value = ''
      this.internalValue = null
    },
  },
}
