export default {
  props: {
    /**
     * Array of Filter objects
     */
    filters: {
      required: false,
      type: Array, // Array of objects. better to rewrite with TS
      default: null,
    },
    /**
     * Uses in `col-md-...` for "Filter" button
     */
    filterButtonColumns: {
      required: false,
      type: [Number, String],
      default: 3,
    },
    /**
     * Uses instead filterButtonColumns prop (use `col-sm-auto`+`flex-sm-fill` instead `col-md-...`)
     */
    filtersColumnFlexFill: {
      required: false,
      type: Boolean,
      default: false,
    },
    /**
     * Uses instead filterButtonColumns and filtersColumnFlexFill props (use `col-sm` instead `col-md-...`)
     */
    equalFiltersColumnWidth: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
}
