//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Vue from 'vue'
import { mapState } from 'vuex'
import { getUrlByShortname } from '@/utils/getMarketplaceURL'

export default Vue.extend({
  name: 'AsinField',
  props: {
    title: {
      required: true,
      type: String,
    },
    asin: {
      required: true,
      type: String,
    },
    country: {
      required: true,
      type: String,
    },
  },
  computed: {
    ...mapState('base', ['countries']),
    marketplaceURL() {
      if (!this.countries) {
        return null
      }
      return getUrlByShortname(this.countries, this.country, this.asin)
    },
  },
})
