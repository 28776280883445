export default function ({ $axios, $auth }) {
  $axios.interceptors.response.use(function (config) {
    const success = config.data?.success
    const message = config.data?.message
    if (success === false && message === 'Unauthorized user') {
      $auth.logout()
    }
    return config
  })
}
