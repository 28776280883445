//
//
//
//
//
//

export default {
  name: 'VTab',
  props: {},
  methods: {
    onClick(event) {
      this.$emit('tabs:change', event)
      this.$emit('click', event)
    },
  },
}
