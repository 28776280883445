export default {
  props: {
    /**
     * Loading state
     */
    loading: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
}
