
import VCardContent from './VCardContent'

export default {
  name: 'VCard',
  props: {
    bordered: {
      required: false,
      type: Boolean,
      default: false,
    },
    formCard: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classes() {
      const classes = {
        'v-card--bordered': this.bordered,
        'v-card--form-card': this.formCard,
      }

      return classes
    },
  },
  methods: {
    parseNodes() {
      const slot = this.$slots.default || []

      let cardHeader = null
      let cardContent = null
      let cardFooter = null

      const nodesLength = slot.length
      if (nodesLength > 0) {
        for (let i = 0; i < nodesLength; i++) {
          /** @type {VNode} */
          const node = slot[i]
          if (node.componentOptions) {
            // if node's constructor name is VCardHeader
            if (node.componentOptions.Ctor.options.name === 'VCardHeader') {
              cardHeader = node
            }
            // if node's constructor name is VCardContent
            if (node.componentOptions.Ctor.options.name === 'VCardContent') {
              cardContent = node
            }
            // if node's constructor name is VCardFooter
            if (node.componentOptions.Ctor.options.name === 'VCardFooter') {
              cardFooter = node
            }
          }
        }
      }

      return {
        cardHeader,
        cardContent,
        cardFooter,
      }
    },
  },
  render(createElement) {
    const { cardHeader, cardContent, cardFooter } = this.parseNodes()

    // simple card
    if (cardHeader === null && cardContent === null && cardFooter === null) {
      return createElement(
        'div',
        {
          class: {
            'v-card': true,
            ...this.classes,
          },
        },
        [createElement(VCardContent, [this.$slots.default])]
      )
    }

    const VCardChildrenNodes = []
    if (cardHeader !== null) {
      VCardChildrenNodes.push(cardHeader)
    }
    if (cardContent !== null) {
      VCardChildrenNodes.push(cardContent)
    }
    if (cardFooter !== null) {
      VCardChildrenNodes.push(cardFooter)
    }

    // complex card
    return createElement(
      'div',
      {
        class: {
          'v-card': true,
          ...this.classes,
        },
      },
      VCardChildrenNodes
    )
  },
}
