//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VIcon from '@/components/ui/v-icon/VIcon'

export default {
  name: 'VInput',
  components: { VIcon },
  model: {
    event: 'input',
    prop: 'value',
  },
  props: {
    label: {
      required: false,
      type: String,
      default: null,
    },
    type: {
      required: false,
      type: String,
      default: 'text',
    },
    step: {
      required: false,
      type: Number,
      default: undefined,
    },
    placeholder: {
      required: false,
      type: String,
      default: null,
    },
    disabled: {
      required: false,
      type: Boolean,
      default: false,
    },
    readonly: {
      required: false,
      type: Boolean,
      default: false,
    },
    clearable: {
      required: false,
      type: Boolean,
      default: false,
    },
    noFocusOnClear: {
      required: false,
      type: Boolean,
      default: false,
    },
    value: {
      required: false,
      type: [String, Number, Date, Boolean],
      default: '',
    },
    noLabel: {
      required: false,
      type: Boolean,
      default: false,
    },
    hideDetails: {
      required: false,
      type: Boolean,
      default: false,
    },
    error: {
      required: false,
      type: Boolean,
      default: false,
    },
    errorMessage: {
      required: false,
      type: [String, Boolean],
      default: '',
    },
    persistentHint: {
      required: false,
      type: Boolean,
      default: false,
    },
    hint: {
      required: false,
      type: String,
      default: '',
    },
    hintRight: {
      required: false,
      type: Boolean,
      default: false,
    },
    /**
     * Show details only if they active
     */
    autoShowDetails: {
      required: false,
      type: Boolean,
      default: false,
    },
    inputClass: {
      required: false,
      type: [String, Array, Object],
      default: '',
    },
    autocomplete: {
      required: false,
      type: String,
      default: null,
    },
    ariaAutocomplete: {
      required: false,
      type: String,
      default: null,
    },
    formStyle: {
      required: false,
      type: String,
      default: 'light',
    },
    noMinWidth: {
      required: false,
      type: Boolean,
      default: false,
    },
    noAppearance: {
      required: false,
      type: Boolean,
      default: false,
    },
    title: {
      required: false,
      type: String,
      default: null,
    },
    maxLength: {
      required: false,
      type: Number,
      default: 524288, // default by html5 standart
    },
    list: {
      required: false,
      type: String,
      default: null,
    },
  },
  data() {
    return {
      focused: false,
      generatedId: 'v-input-' + Math.random().toString(36).substring(7),
    }
  },
  computed: {
    isError() {
      return this.error || !!this.errorMessage
    },
    inputClasses() {
      return [
        {
          'v-input--no-min-width': this.noMinWidth,
          'v-input--no-appearance': this.noAppearance,
        },
        ...this.inputClass,
      ]
    },
    formControlClasses() {
      return {
        'v-input__form-control--error': this.isError,
        'v-input__form-control--dark': this.formStyle === 'dark',
        'v-input__form-control--middle': this.formStyle === 'middle',
        'v-input__form-control--white': this.formStyle === 'white',
      }
    },
    hintActive() {
      return (this.focused && !!this.hint) || this.persistentHint
    },
    hintClasses() {
      return {
        'v-input__hint--active': this.hintActive,
        'v-input__hint--auto-show': this.autoShowDetails,
        'v-input__hint--right': this.hintRight,
      }
    },
    errorClasses() {
      return {
        'v-input__error-feedback--active': this.isError,
        'v-input__error-feedback--auto-show': this.autoShowDetails,
      }
    },
  },
  methods: {
    focusOnInput() {
      if (this.$refs.input) {
        this.$refs.input.focus()
      }
    },
    onFocus(event) {
      this.focused = true
      this.$emit('focus', event)
    },
    onBlur(event) {
      this.focused = false
      this.$emit('blur', event)
    },
    onInput(event) {
      /**
       * Input event
       * @property {value} Value
       * @property {event} Event
       */
      this.$emit('input', event.target.value, event)
    },
    onChange(event) {
      /**
       * Change event
       * @property {value} Value
       * @property {event} Event
       */
      this.$emit('change', event.target.value, event)
    },
    onClick(event) {
      this.$emit('click', event)
    },
    onFormControlWrapperClick(event) {
      this.$emit('click:form-control-wrapper', event)
    },
    onFormControlClick(_event) {
      // focus only if not clearable or "no focus on clear" prop not set to true
      if (!this.clearable || !this.noFocusOnClear) {
        this.focusOnInput()
      }
    },
    onInputWrapperClick(event) {
      this.$emit('click:input-wrapper', event)
    },
    onSlotClick(slot, event) {
      if (slot) {
        this.$emit(`click:${slot}`, event)
      }
    },
    clear(event) {
      // set value to empty string
      this.$emit('beforeClear', event)
      this.$emit('input', '', event)
      this.$emit('afterClear', event)
    },
  },
}
