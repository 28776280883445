import { render, staticRenderFns } from "./VDataTableConfigModal.vue?vue&type=template&id=aeb6e8e8&"
import script from "./VDataTableConfigModal.vue?vue&type=script&lang=js&"
export * from "./VDataTableConfigModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {VSwitch: require('/app/components/ui/v-switch/VSwitch.vue').default,VCheckbox: require('/app/components/ui/v-checkbox/VCheckbox.vue').default,VBtn: require('/app/components/ui/v-btn/VBtn.vue').default,VActionsGroup: require('/app/components/ui/v-actions-group/VActionsGroup.vue').default,VModal: require('/app/components/ui/v-modal/VModal.vue').default})
