export default {
  warehousesProductsStockMap: (state) => {
    const warehousesProductsStockMap = new Map()
    state.products.forEach((product) => {
      const productStock = state.warehousesStock
        .filter((stock) => stock.product_id === product.id)
        .map((stock) => stock.count)
        .reduce((prev, curr) => prev + curr, 0)

      warehousesProductsStockMap.set(product.id, productStock)
    })
    return warehousesProductsStockMap
  },
  internationalShipments: (state) => {
    return state.shipments.filter((shipment) => !shipment.w2w)
  },
  localShipments: (state) => {
    return state.shipments.filter((shipment) => shipment.w2w)
  },
  internationalItems: (state) => {
    return state.items.filter((item) => !item.w2w)
  },
  localItems: (state) => {
    return state.items.filter((item) => item.w2w)
  },
}
