/**
 * Determine if window's inner width is width of mobile device's screen
 * isMobile is always false for server side rendering!
 */
export default {
  computed: {
    isMobile() {
      return this.$breakpoints.smAndDown
    },
  },
}
