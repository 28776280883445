function getURL(marketplace, asin) {
  return `https://${marketplace}/dp/${asin}`
}

export function getUrlById(countries, id, asin) {
  const marketplace = countries.find((country) => country.id === id)
    .amz_marketplace_url
  return getURL(marketplace, asin)
}

export function getUrlByShortname(countries, shortname, asin) {
  const marketplace = countries.find(
    (country) => country.shortname === shortname
  ).amz_marketplace_url
  return getURL(marketplace, asin)
}

export function getUrlByName(countries, name, asin) {
  const marketplace = countries.find((country) => country.name === name)
    .amz_marketplace_url
  return getURL(marketplace, asin)
}
