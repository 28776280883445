import Vue, { VNode } from 'vue'

export default Vue.extend({
  name: 'VWidgetColumn',
  functional: true,
  render(createElement, context): VNode {
    return createElement(
      'div',
      {
        class: {
          'col-12': true,
          'col-md': true,
          'v-widget-column': true,
        },
      },
      [context.children]
    )
  },
})
