export default () => ({
  bestsellerPaths: null,
  competitor: null,
  competitorsRowData: null,
  filterType: null,
  ourProducts: null,
  competitorBSR: null,
  competitorsAsinList: null,
  countries: null,
  products: null,
  ourProductBSR: null,
  ourRowData: null,
  asin: null,
  image: null,
  country: null,
  product: null,
  dashboardData: null,
  dateRange: null,
  lowerAvailableDate: null,
  upperAvailableDate: null,
  productId: null,
})
