import { render, staticRenderFns } from "./ColumnSettingsMenu.vue?vue&type=template&id=6a6eb444&scoped=true&"
import script from "./ColumnSettingsMenu.vue?vue&type=script&lang=js&"
export * from "./ColumnSettingsMenu.vue?vue&type=script&lang=js&"
import style0 from "./ColumnSettingsMenu.vue?vue&type=style&index=0&id=6a6eb444&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a6eb444",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {VIcon: require('/app/components/ui/v-icon/VIcon.vue').default,VBtn: require('/app/components/ui/v-btn/VBtn.vue').default,VCheckbox: require('/app/components/ui/v-checkbox/VCheckbox.vue').default,RightPanel: require('/app/components/ui/right-panel/RightPanel.vue').default,VInput: require('/app/components/ui/v-input/VInput.vue').default})
