//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import RightPanel from '@/components/ui/right-panel/RightPanel'

export default {
  name: 'ColumnSettingsMenu',
  components: { RightPanel },
  props: {
    gridOptions: {
      require: true,
      type: Object,
      default: null,
    },
    showColumnPanel: {
      require: true,
      type: Boolean,
    },
    columnsStateVersion: {
      require: true,
      type: String,
      default: 'default',
    },
  },
  data() {
    return {
      columnDefs: null,
      columns: null,
      templates: null,
      tableTemplates: null,
      showTemplatePanel: false,
      newTemplateName: '',
      errorMessage: '',
    }
  },
  watch: {
    showColumnPanel(value) {
      if (!value) return

      this.columnDefs = this.gridOptions.columnDefs
      const columnState = this.gridOptions.columnApi.getColumnState()

      this.columns = columnState.map((column) => {
        column.header = this.treeFind(this.columnDefs, column.colId)
        return column
      })

      this.tableTemplates = this.templates[this.$route.name][
        this.columnsStateVersion
      ]
    },
  },
  beforeMount() {
    this.templates = JSON.parse(localStorage.getItem('columnTemplates')) || {}
    const routeName = this.$route.name

    if (!this.templates[routeName]) {
      this.templates[routeName] = {}
    }
    if (!this.templates[routeName][this.columnsStateVersion]) {
      this.templates[routeName][this.columnsStateVersion] = []
    }

    this.tableTemplates = this.templates[routeName][this.columnsStateVersion]
  },
  methods: {
    // eslint-disable-next-line consistent-return
    treeFind(tree, id) {
      for (let i = 0; i < tree.length; i++) {
        const node = tree[i]
        if (node.field === id) {
          return node.headerName
        }
        if (node.children) {
          const result = this.treeFind(node.children, id)
          if (result) {
            return `${node.headerName} -> ${result}`
          }
        }
      }
    },
    selectColumns() {
      this.clearSelectedTemplate()
      this.saveColumnState()
    },
    saveColumnState() {
      const visibleCol = this.columns
        .filter((column) => column.hide)
        .map((column) => column.colId)
      const hideCol = this.columns
        .filter((column) => !column.hide)
        .map((column) => column.colId)

      this.gridOptions.columnApi.setColumnsVisible(visibleCol, false)
      this.gridOptions.columnApi.setColumnsVisible(hideCol, true)
    },
    refreshColumn() {
      this.columns = this.columns.map((column) => {
        column.hide = this.columnDefs.find(
          (columnDef) => column.colId === columnDef.field
        ).hide
        return column
      })
    },
    clearSelectedTemplate() {
      this.tableTemplates = this.tableTemplates.map((template) => {
        template.selected = false
        return template
      })
    },
    saveTemplate() {
      const existTempalteName = this.tableTemplates.find(
        (template) => template.name === this.newTemplateName
      )

      if (this.newTemplateName.length > 24) {
        this.errorMessage = 'Maximum length 24 characters'
        return
      }
      if (existTempalteName) {
        this.errorMessage = 'This template name has already exist'
        return
      }
      this.errorMessage = ''

      const contentNewTemplate = this.columns.map((column) => {
        return {
          colId: column.colId,
          hide: column.hide,
        }
      })
      const newTemplate = {
        name: this.newTemplateName,
        selected: true,
        content: contentNewTemplate,
      }

      this.clearSelectedTemplate()

      this.tableTemplates.push(newTemplate)
      this.templates[this.$route.name][
        this.columnsStateVersion
      ] = this.tableTemplates
      localStorage.setItem('columnTemplates', JSON.stringify(this.templates))

      this.newTemplateName = ''
      this.showTemplatePanel = false

      this.saveColumnState()
    },
    deleteTemplate(value) {
      this.tableTemplates = this.tableTemplates.filter(
        (template) => template.name !== value.name
      )

      this.templates[this.$route.name][
        this.columnsStateVersion
      ] = this.tableTemplates
      localStorage.setItem('columnTemplates', JSON.stringify(this.templates))
    },
    selectTemplate(value) {
      this.clearSelectedTemplate()

      const selectedTemplate = this.tableTemplates.find(
        (template) => value.name === template.name
      )
      selectedTemplate.selected = true

      this.columns = this.columns.map((column) => {
        column.hide = selectedTemplate.content.find(
          (selectedTemplateContent) =>
            column.colId === selectedTemplateContent.colId
        ).hide

        return column
      })

      this.saveColumnState()
    },
  },
}
