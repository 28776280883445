import DefaultPreloader from '@/components/ui/loaders/DefaultPreloader'

export default {
  methods: {
    /**
     * Create VNode with default preloader
     * @return {VNode|VNode[]}
     */
    genDefaultPreloader(data = {}) {
      if (this.$scopedSlots.preloader !== undefined) {
        return this.$scopedSlots.preloader({})
      }
      return this.$createElement(DefaultPreloader, data)
    },
  },
}
