//
//
//
//
//
//
//
//

import { mapState } from 'vuex'
import PageDimensionMixin from '../../../mixins/PageDimensionMixin'
import VDrawerMenu from './VDrawerMenu'

export default {
  name: 'VDrawer',
  components: { VDrawerMenu },
  mixins: [PageDimensionMixin],
  computed: {
    ...mapState('application/layout', ['drawerState']),
  },
}
