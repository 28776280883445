export default {
  data() {
    return {
      tables: null,
      defaultFilterModel: null,
    }
  },
  mounted() {
    if (!this.columnState) {
      return
    }

    this.gridOptions = Object.assign(this.gridOptions, {
      onColumnResized: this.onColumnResized,
      onColumnVisible: this.onColumnsChanged,
      onColumnMoved: this.onColumnsChanged,
      onColumnPinned: this.onColumnsChanged,
      onSortChanged: this.onColumnsChanged,
      onGridReady: this.loadGridState,
    })

    setTimeout(() => {
      if (!this.notSavingFilterTableState) {
        this.gridOptions.onFilterChanged = this.onFilterChanged
      }
    }, 500)
  },
  watch: {
    columnDefs() {
      if (!this.columnState) {
        return
      }
      const columnsState = this.getColumnsState()
      setTimeout(() => {
        this.setState(columnsState)
        !this.notSavingFilterTableState && this.setDefaultFilters()
      }, 100)
    },
    rowData() {
      if (!this.columnState) {
        return
      }
      setTimeout(() => {
        !this.notSavingFilterTableState && this.setDefaultFilters()
      })
    },
  },
  methods: {
    setDefaultFilters() {
      this.afterSetDefaultFilters && this.afterSetDefaultFilters()
      const localFilterModel = localStorage.getItem(this.getPath() + '.filter')
      const isEmptyObject = JSON.parse(
        JSON.stringify(localFilterModel === '{}')
      )

      if (!isEmptyObject) {
        const filterModel = JSON.parse(localFilterModel)
        this.gridOptions.api.setFilterModel(filterModel)
      }
    },
    onFilterChanged() {
      const filterModel = this.gridOptions.api.getFilterModel()
      localStorage.setItem(
        this.getPath() + '.filter',
        JSON.stringify(filterModel)
      )
    },
    loadGridState() {
      const columnsState = this.getColumnsState()
      this.setState(columnsState)
    },
    setState(columnsState) {
      if (columnsState !== null) {
        this.gridOptions.columnApi.applyColumnState({
          state: columnsState,
          applyOrder: true,
        })
      }
    },
    getPath() {
      const version = this.columnsStateVersion || 'default'
      return `${this.$route.name}.${this.$store.state.base.buildVersion}.${version}`
    },
    getColumnsState() {
      const path = this.getPath()

      if (localStorage.getItem(path) !== null) {
        return JSON.parse(localStorage.getItem(path))
      }
      return null
    },
    onColumnResized(params) {
      params.finished === true && this.onColumnsChanged()
    },
    onColumnsChanged() {
      const columnsState = this.gridOptions.columnApi.getColumnState()
      const path = this.getPath()
      localStorage.setItem(path, JSON.stringify(columnsState))
    },
  },
}
