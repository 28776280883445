//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  components: {},
  data() {
    return {
      values: null,
      isOpen: false,
      isTippyShow: false,
      tippyInstance: null,
      isShowTooltip: false,
    }
  },
  computed: {
    headerName() {
      return this.params.colDef.headerName
    },
    actualWidthColumn() {
      return this.params.column.actualWidth
    },
  },
  watch: {
    values() {
      this.isShowTooltip = this.getWidthComparisonBoolean()
    },
    actualWidthColumn() {
      this.isShowTooltip = this.getWidthComparisonBoolean()
    },
  },
  beforeMount() {
    this.values = this.params.value || []
  },
  methods: {
    getWidthComparisonBoolean() {
      const columnWidth = this.actualWidthColumn
      const gridCellPaddings = 35
      const cellWidth = this.getWidthCell() + gridCellPaddings
      return cellWidth > columnWidth
    },
    getWidthCell() {
      return this.$refs.valueRowCell?.scrollWidth || 0
    },
    getInstance(instance) {
      this.tippyInstance = instance
    },
    hide() {
      this.tippyInstance.hide()
    },
    tippyToggle() {
      this.isTippyShow = !this.isTippyShow
      this.params.api.gridOptionsWrapper.gridOptions.suppressRowClickSelection = this.isTippyShow
    },
  },
}
