export const state = () => ({
  loaded: false,
  part: 'main-page',
  builderPages: null,
  builderFields: null,
})

export const mutations = {
  setLoaded(state, value) {
    state.loaded = value
  },
  setBuilderPages(state, value) {
    state.builderPages = value
  },
  setBuilderFields(state, value) {
    state.builderFields = value
  },
  updateFields(state, values) {
    Object.keys(values).forEach((fieldName) => {
      const value = values[fieldName]
      const field = state.builderFields.find(
        (field) => field.name === fieldName
      )
      field.value = value
    })
  },
}

export const actions = {
  saveFields({ commit }, { modifiedFields, productId }) {
    return this.$axios
      .post('api-v2/api.php?action=editProductCatalog', {
        ...modifiedFields,
        product_catalog_id: productId,
      })
      .then((response) => {
        const success = response.data.success !== 'false'
        success && commit('updateFields', modifiedFields)

        return response.data
      })
  },
}
